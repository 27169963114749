import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

class HeaderForm extends React.Component {
  render() {
    return (
      <>
        <Typography variant="h5" gutterBottom>{this.props.titulo}</Typography>
        <Divider/>
      </>
    );
  }
}

export default HeaderForm;