import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoTextoSite from '../../servicos/ServicoTextoSite';
import { Types } from '../ducks/textosSite';
const servico = new ServicoTextoSite();

function* salvar (action) {
    try {
        const texto = yield call([servico, servico.atualizarTexto], action.texto.id, action.texto);
        yield put({type: Types.SALVAR_SUCESSO, texto: texto.data, editar: true});
    } catch (e) {
        if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.SALVAR_ERRO, erro: e.data});
    }
}

function* buscarItem (action) {
    try {
        const texto = yield call([servico, servico.buscarTextoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, texto});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const textos = yield call([servico, servico.buscarTextos]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, textos});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* sagaSlides () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
}

export default sagaSlides;