import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import ErroForm from '../componentes/ErroForm';
import InputPesquisa from '../componentes/geral/InputPesquisa';
import TabelaInscricoes from '../componentes/inscricoes/TabelaInscricoes';

// REDUX
import {
  buscarTodos,
  pesquisar,
  exportar
} from '../store/ducks/inscricoes';

class PaginaInscricoes extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
  }
  downloadExportacao = () => {
    const link = document.createElement('a');
    link.href = this.props.linkExportacao;
    link.download = true;
    var evt = document.createEvent('MouseEvents');
    evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    link.dispatchEvent(evt);
  }
  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
          {/* MENU */}
          <Grid container spacing={2} alignItems="center">
            <Grid item md={6} sm={6} xs={6}>
              <Button style={{marginRight: '1em'}} variant="contained" color="primary" onClick={this.props.exportar}>Exportar</Button>
              {(this.props.linkExportacao) ? <Button variant="text" onClick={this.downloadExportacao}>Download CSV</Button> : ''}
            </Grid>
            <Grid item container md={6} sm={6} xs={6} justify="flex-end">
              {/* PESQUISA */}
              <InputPesquisa 
                aoPesquisar={() => this.props.pesquisar(this.props.pesquisa)}
                aoRemoverPesquisa={() => this.props.buscarTodos(1, this.props.qtde)} />
            </Grid>
          </Grid>
          {/* TABELA */}
          <TabelaInscricoes />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    linkExportacao: state.inscricoes.linkExportacao,
    carregando: state.inscricoes.carregando,
    erro: state.inscricoes.erro,
    selecionados: state.inscricoes.selecionados,
    pagina: state.inscricoes.pagina,
    qtde: state.inscricoes.qtde,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    exportar: () => dispatch(exportar()),
    pesquisar: (pesquisa, pagina) => dispatch(pesquisar(pesquisa, pagina))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaInscricoes));