import axios from 'axios';
class ServicoUsuario {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/usuarios` : 'https://api.pontuafidelidade.com.br/api/admin/usuarios';

  constructor() {
    this.buscarToken();
    this.usuarios = (localStorage.getItem('usuarios')) ? JSON.parse(localStorage.getItem('usuarios')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarUsuarioPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarUsuarios(qtde = null, pagina = 1, ordem = null) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      if (ordem) url = `${url}&ordem=${ordem}`;
      console.log(url);
      axios({
        url,
        method: 'GET',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        console.log(res.data);
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  pesquisarUsuarios(pesquisa, escolaridade, sexo, qtde, pagina) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?pesquisa=${pesquisa}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      if (pagina) url = `${url}&page=${pagina}`;
      if (escolaridade) url = `${url}&escolaridade=${escolaridade}`;
      if (sexo) url = `${url}&sexo=${sexo}`;
      axios({
        url,
        method: 'GET',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  removerUsuario(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoUsuario;