import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';
import * as slugify from 'slugify';

// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormSelect from '../../geral/CampoFormSelect';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, CircularProgress, Button
} from '@material-ui/core';

import {
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles'

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/funcoes';

import { buscarTodos as buscarPermissoes } from '../../../store/ducks/permissoes';

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        maxWidth: '500px',
        width: '90%'
      }
    }
  }
});

class DrawerAdicionar extends Component {
  componentDidMount() {
    this.props.buscarPermissoes();
  }
  alterarNome = (e) => {
    this.props.alterarItem('nome', e.target.value);
    this.props.alterarItem('slug', slugify(e.target.value, {lower: true}));
  }
  salvar = (e) => {
    e.preventDefault();
    const funcao = { ...this.props.item, permissoes: this.props.item.permissoes_ids };
    this.props.salvar(funcao);
  }
  render() {
    return (
      <div role="presentation">
        <MuiThemeProvider theme={theme}>
          <Drawer anchor="right" open={this.props.mostrar} onClose={e => this.props.esconderForm()}>
            <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1em'}}>
              {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
              <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar função</Typography>
              {/* NOME */}
              <CampoFormTexto
                required
                label="Nome"
                value={this.props.item.nome}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
                onChange={this.alterarNome} />
              {/* SLUG */}
              <CampoFormTexto
                required
                label="Slug"
                value={this.props.item.slug}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.slug) ? this.props.erro.errors.slug[0] : ''}
                onChange={e => this.props.alterarItem('slug', e.target.value)} />
              {/* ATIVO */}
              <CampoFormSwitch
                label="Super administrador"
                checked={(this.props.item.superadmin) ? true : false}
                onChange={e => this.props.alterarItem('superadmin', !this.props.item.superadmin)} />
              {/* PERMISSÕES */}
              <CampoFormSelect
                multiple
                label="Permissões"
                carregando={this.props.carregandoPermissoes}
                value={this.props.item.permissoes_ids}
                onChange={e => this.props.alterarItem('permissoes_ids', e.target.value)}
                erro={this.props.erroPermissoes}
                opcoes={(() => {
                  const opcoes = [];
                  this.props.permissoes.map((permissao, i) => {
                    return opcoes.push({value: permissao.id, label: permissao.nome})
                  })
                  return opcoes;
                })()}/>
              {(this.props.erro && this.props.erro.message)
                  ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
                  : ''}
              <div className="flex-end">
                <Button
                  disabled={this.props.carregando}
                  type="submit"
                  style={{marginTop: '1em', position: 'relative'}}
                  variant="contained"
                  color="primary">
                  Salvar
                  {(this.props.carregando)
                    ? <CircularProgress
                        size={24}
                        color="secondary"
                        style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                    : ''}
                </Button>
              </div>
            </form>
          </Drawer>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.funcoes.carregando,
    estabelecimento: state.estabelecimentos.item,
    item: state.funcoes.item,
    mostrar: state.funcoes.mostrarForm,
    erro: state.funcoes.erro,
    carregandoPermissoes: state.permissoes.carregando,
    permissoes: state.permissoes.permissoes,
    erroPermissoes: state.permissoes.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (funcao) => dispatch(salvar(funcao)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm()),
    buscarPermissoes: () => dispatch(buscarPermissoes())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAdicionar));