import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  Chip, Card, CardContent, Grid, Typography, Divider,
  Table, TableBody, TableRow, TableCell, Button, CircularProgress
} from '@material-ui/core';

import {
  alterarPagina,
  adicionarSelecao,
  buscarTodos,
  removerSelecao,
  selecionarTodos,
  desselecionarTodos,
  definirItem,
  remover,
  iniciarEditar
} from '../../../store/ducks/vouchers';

class ListaVouchers extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }
  // INFINITE SCROLL PARA A VIEW EM LISTA
  handleScrolling = () => {
    const wrappedElement = document.getElementById('listaDados');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      if (this.props.paginas > this.props.pagina && !this.props.carregando) {
        this.props.alterarPagina(this.props.pagina + 1);
        this.props.buscarTodos(this.props.pagina, this.props.qtde);
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  mostrarConfirmacaoRemover = (voucher) => {
    this.refs.confirmacaoRemover.mostrar(voucher);
  }
  editar = (voucher) => {
    this.props.definirItem(voucher);
    this.props.irParaEditar(voucher.id);
  }
  render() {
    return (
      <div id="listaDados">
        {(this.props.carregando && this.props.pagina === 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {this.props.vouchers.map((voucher, i) => {
          return (
            <Card key={i} raised style={{marginBottom: '.5em', padding: '.5em'}}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5" style={{textTransform: 'uppercase'}}>{voucher.titulo}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {(voucher.ativo ? <Chip label="Ativa" color="primary" /> : '-')}
                  </Grid>
                </Grid>
                <Divider style={{margin: '1em 0'}} />
                <Grid container>
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell><strong>Tipo</strong></TableCell>
                          <TableCell>{(voucher.tipo === 'percentual') ? 'Percentual' : 'Valor'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Percent/Valor</strong></TableCell>
                          <TableCell>{(voucher.tipo === 'percentual') ? `${voucher.percentual}%` : `R$${voucher.valor}`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Quantidade</strong></TableCell>
                          <TableCell>{(voucher.sem_limite_quantidade ? <Chip label="Ilimitado" color="primary" /> : <Typography>{voucher.quantidade}</Typography>)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>Estab.</strong></TableCell>
                            <TableCell>
                              {(voucher.estabelecimento)
                                ? <Link className="andes-link" to={`/estabelecimentos/ver/${voucher.estabelecimento.id}?tab=vouchers`}>{voucher.estabelecimento.razao_social}</Link>
                                : '-'}
                            </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button 
                      onClick={e => this.props.iniciarEditar(voucher)}
                      variant="outlined"
                      size="small">Editar</Button>
                    <Button 
                      onClick={e => this.mostrarConfirmacaoRemover(voucher)}
                      variant="outlined"
                      size="small"
                      color="secondary">Remover</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {/* LOADER MAIS */}
        {(this.props.carregando && this.props.pagina > 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {/* CONFIRMAÇÃO */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.remover(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      vouchers: state.vouchers.itens,
      selecionados: state.vouchers.selecionados,
      paginas: state.vouchers.paginas,
      pagina: state.vouchers.pagina,
      qtde: state.vouchers.qtde,
      carregando: state.vouchers.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
      alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
      adicionarSelecao: (voucher) => dispatch(adicionarSelecao(voucher)),
      removerSelecao: (voucher) => dispatch(removerSelecao(voucher)),
      selecionarTodos: () => dispatch(selecionarTodos()),
      desselecionarTodos: () => dispatch(desselecionarTodos()),
      remover: (voucher) => dispatch(remover(voucher)),
      buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
      definirItem: (voucher) => dispatch(definirItem(voucher)),
      iniciarEditar: (voucher) => dispatch(iniciarEditar(voucher))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaVouchers);