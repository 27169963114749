import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';

import {
  Drawer, Typography,
  CircularProgress, Button
} from '@material-ui/core';

import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormSelect from '../../geral/CampoFormSelect';
import CampoFormErro from '../../geral/CampoFormErro';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/adminsEstabelecimentos';

import {
  buscarTodos
} from '../../../store/ducks/funcoesEstabelecimentos';

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        maxWidth: '500px',
        width: '90%'
      }
    }
  }
});

class DrawerAdicionar extends Component {
  componentDidMount() {
    this.props.buscarFuncoes();
    this.props.alterarItem('estabelecimentos_id', this.props.match.params.id);
  }
  salvar = (e) => {
    e.preventDefault();
    const admin = {...this.props.item, estabelecimentos_id: this.props.estabelecimento.id};
    this.props.salvar(admin);
  }
  render() {
    return (
      <div role="presentation">
        <MuiThemeProvider theme={theme}>
          <Drawer anchor="right" open={this.props.mostrar} onClose={e => this.props.esconderForm()}>
            <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1em'}}>
              {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
              <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar admin</Typography>
              <CampoFormTexto
                required
                label="Nome"
                value={this.props.item.nome}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
                onChange={e => this.props.alterarItem('nome', e.target.value)} />
              <CampoFormTexto
                required
                label="E-mail"
                value={this.props.item.email}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.email) ? this.props.erro.errors.email[0] : ''}
                onChange={e => this.props.alterarItem('email', e.target.value)} />
              <CampoFormTexto
                required
                type="password"
                label="Senha"
                value={this.props.item.senha}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.senha) ? this.props.erro.errors.senha[0] : ''}
                onChange={e => this.props.alterarItem('senha', e.target.value)} />
              <CampoFormSelect
                label="Função"
                carregando={this.props.carregandoFuncoes}
                value={this.props.item.funcoes_estabelecimentos_id}
                onChange={e => this.props.alterarItem('funcoes_estabelecimentos_id', e.target.value)}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.funcoes_estabelecimentos_id) ? this.props.erro.errors.funcoes_estabelecimentos_id[0] : ''}
                opcoes={(() => {
                  const opcoes = [];
                  this.props.funcoes.map((funcao, i) => {
                    return opcoes.push({value: funcao.id, label: funcao.nome})
                  })
                  return opcoes;
                })()}/>
                {(this.props.erro && this.props.erro.message)
                  ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
                  : ''}
              <div className="flex-end">
                <Button
                  disabled={this.props.carregando}
                  type="submit"
                  style={{marginTop: '1em', position: 'relative'}}
                  variant="contained"
                  color="primary">
                  Salvar
                  {(this.props.carregando)
                    ? <CircularProgress
                      size={24}
                      color="secondary"
                      style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                    : ''}
                </Button>
              </div>
            </form>
          </Drawer>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.adminsEstabelecimentos.carregando,
    carregandoFuncoes: state.funcoesEstabelecimentos.carregandoFuncoes,
    erroFuncoes: state.funcoesEstabelecimentos.erro,
    funcoes: state.funcoesEstabelecimentos.funcoes,
    estabelecimento: state.estabelecimentos.item,
    item: state.adminsEstabelecimentos.item,
    mostrar: state.adminsEstabelecimentos.mostrarForm,
    erro: state.adminsEstabelecimentos.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    buscarFuncoes: () => dispatch(buscarTodos(1, 50)),
    salvar: (admin) => dispatch(salvar(admin)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAdicionar));