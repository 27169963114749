const novoItem = {
    titulo: '',
    conteudo: '',
    ordem: null
}

const stateInicial = {
    carregando: false,
    erro: '',
    layout: 'tabela',
    itens: [],
    item: {...novoItem},
    selecionados: [],
    mostrarForm: false
}

export const Types = {
    // ALTERACOES
    ALTERAR_LAYOUT: 'vantagensSite/ALTERAR_LAYOUT',
    ALTERAR_ITEM: 'vantagensSite/ALTERAR_ITEM',
    ADICIONAR_SELECAO: 'vantagensSite/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'vantagensSite/REMOVER_SELECAO',
    SELECIONAR_TODOS: 'vantagensSite/SELECIONAR_TODOS',
    DESSELECIONAR_TODOS: 'vantagensSite/DESSELECIONAR_TODOS',
    DEFINIR_ITEM: 'vantagensSite/DEFINIR_ITEM',
    MOSTRAR_FORM: 'vantagensSite/MOSTRAR_FORM',
    ESCONDER_FORM: 'vantagensSite/ESCONDER_FORM',
    INICIAR_EDITAR: 'vantagensSite/INICIAR_EDITAR',
    REORDENAR_REQUEST: 'vantagensSite/REORDENAR_REQUEST',
    REORDENAR_ERRO: 'vantagensSite/REORDENAR_ERRO',
    // SALVAR
    SALVAR_REQUEST: 'vantagensSite/SALVAR_REQUEST',
    SALVAR_SUCESSO: 'vantagensSite/SALVAR_SUCESSO',
    SALVAR_ERRO: 'vantagensSite/SALVAR_ERRO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'vantagensSite/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'vantagensSite/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'vantagensSite/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'vantagensSite/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'vantagensSite/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'vantagensSite/BUSCAR_ITEM_ERRO',
    // REMOVER
    REMOVER_REQUEST: 'vantagensSite/REMOVER_REQUEST',
    REMOVER_SUCESSO: 'vantagensSite/REMOVER_SUCESSO',
    REMOVER_ERRO: 'vantagensSite/REMOVER_ERRO'
}

export default function reducer(state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_LAYOUT: {
            if (['tabela', 'lista'].indexOf(action.layout) > -1) {
                return {...state, layout: action.layout};
            }
            return {...state};
        }
        case Types.ALTERAR_ITEM: {
            const item = {...state.item}
            item[action.campo] = action.valor;
            return {...state, item, erro: ''}
        }
        case Types.DEFINIR_ITEM: {
            return {
                ...state,
                item: action.item
            }
        }
        case Types.ESCONDER_FORM: {
            return {...state, mostrarForm: false}
        }
        case Types.MOSTRAR_FORM: {
            return {
                ...state,
                erro: '',
                mostrarForm: true,
                item: {...novoItem}
            }
        }
        case Types.INICIAR_EDITAR: {
            const item = {...action.vantagem};
            return {
              ...state,
              item,
              erro: '',
              mostrarForm: true
            }
        }
        case Types.REORDENAR_REQUEST: {
            const itens = [...state.itens];
            const item = {...itens[action.origem]};
            itens.splice(action.origem, 1);
            itens.splice(action.destino, 0, item);
            return {...state, erro: '', itens};
        }
        case Types.REORDENAR_ERRO: {
            return {
                ...state,
                erro: action.erro
            }
        }
        case Types.SALVAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.SALVAR_SUCESSO: {
            let novoState = {
                ...state,
                carregando: false,
                mostrarForm: false,
                item: action.vantagem
            }
            if (action.editar) {
                const itens = [...state.itens];
                itens.map((item, i) => {
                  if (item.id === action.vantagem.id) return itens[i] = action.vantagem;
                  return null;
                });
                novoState = {...novoState, itens };
            } else {
                novoState = {...novoState, itens: [...state.itens, action.vantagem]};
            }
            return novoState;
        }
        case Types.SALVAR_ERRO: {
            console.log(action.erro);
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.vantagem
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                itens: action.vantagens.data
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.slide);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(vantagem => vantagem.id !== action.vantagem.id);
            return {...state, selecionados};
        }
        case Types.SELECIONAR_TODOS: {
            const selecionados = [...state.itens];
            return {...state, selecionados};
        }
        case Types.DESSELECIONAR_TODOS: {
            const selecionados = [];
            return {...state, selecionados};
        }
        case Types.REMOVER_REQUEST: {
            const itens = [...state.itens];
            itens.map((vantagem, i) => {
                if (vantagem.id === action.vantagem.id) {
                    return itens[i].removendo = true;
                }
                return null;
            });
            return {...state, itens};
        }
        case Types.REMOVER_SUCESSO: {
            let itens = [...state.itens];
            let selecionados = [...state.selecionados];
            itens = itens.filter((vantagem) => vantagem.id !== action.vantagem.id)
            selecionados = selecionados.filter((vantagem) => vantagem.id !== action.vantagem.id)
            return {...state, itens, selecionados};
        }
        case Types.REMOVER_ERRO: {
            let itens = [...state.itens];
            itens.map((vantagem, i) => {
                return itens[i].removendo = false;
            });
            return {...state, erro: action.erro, itens};
        }
        default: return state;
    }
}

export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarItem(campo, valor) {
    return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos() {
    return { type: Types.BUSCAR_TODOS_REQUEST}
}

export function adicionarSelecao(vantagem) {
    return { type: Types.ADICIONAR_SELECAO, vantagem }
}

export function removerSelecao(vantagem) {
    return { type: Types.REMOVER_SELECAO, vantagem }
}

export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(vantagem) {
    return { type: Types.REMOVER_REQUEST, vantagem }
}

export function definirNovoItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(vantagem) {
    return { type: Types.SALVAR_REQUEST, vantagem }
}

export function definirItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function mostrarForm() {
    return { type: Types.MOSTRAR_FORM };
}
  
export function esconderForm() {
    return { type: Types.ESCONDER_FORM };
}

export function iniciarEditar(vantagem) {
    return { type: Types.INICIAR_EDITAR, vantagem }
}

export function reordenarItem(origem, destino, id) {
    return { type: Types.REORDENAR_REQUEST, origem, destino, id }
}