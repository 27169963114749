import axios from 'axios';
class ServicoEstatstica {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/estatisticas` : 'https://api.pontuafidelidade.com.br/api/admin/estatisticas';

  constructor() {
    this.buscarToken();
    this.estatisticas = (localStorage.getItem('estatisticas')) ? JSON.parse(localStorage.getItem('estatisticas')) : {};
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarEstatisticas() {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        console.log(res.data);
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarEstatisticasEstabelecimento(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }
  
}

export default ServicoEstatstica;