import React, { Component } from 'react';
import { FormControlLabel, Switch, FormControl, FormHelperText } from '@material-ui/core';

class CampoFormSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        <FormControlLabel
          control={
            <Switch 
              checked={this.props.checked}
              onChange={this.props.onChange}
              color="primary" />
          } 
          label={this.props.label} />
          {(this.props.erro || this.props.ajuda) ? 
            <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
            : ''}
      </FormControl>
    )
  }
}

export default CampoFormSwitch;