import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoDepoimentoSite from '../../servicos/ServicoDepoimentoSite';
import { Types } from '../ducks/depoimentosSite';
const servico = new ServicoDepoimentoSite();

function* salvar (action) {
    if (action.depoimento.id) {
        try {
            const depoimento = yield call([servico, servico.atualizarDepoimento], action.depoimento.id, action.depoimento);
            yield put({type: Types.SALVAR_SUCESSO, depoimento: depoimento.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const depoimento = yield call([servico, servico.adicionarDepoimento], action.depoimento);
            yield put({type: Types.SALVAR_SUCESSO, depoimento});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* reordenar (action) {
    try {
        yield call([servico, servico.reordenarDepoimentos], action.id, action.destino);
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REORDENAR_ERRO, erro: e.message});
     }
}

function* buscarItem (action) {
    try {
        const depoimento = yield call([servico, servico.buscarDepoimentoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, depoimento});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const depoimentos = yield call([servico, servico.buscarDepoimentos]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, depoimentos});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerDepoimento], action.depoimento.id);
        yield put({type: Types.REMOVER_SUCESSO, depoimento: action.depoimento});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaDepoimentos () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
    yield takeEvery(Types.REORDENAR_REQUEST, reordenar);
}

export default sagaDepoimentos;