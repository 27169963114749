import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Chip, Grid, Icon, Typography } from '@material-ui/core';

import '../css/PaginaEstabelecimentos.css';
import ErroForm from '../componentes/ErroForm';
import InputPesquisa from '../componentes/geral/InputPesquisa';
import MenuSelecaoLayout from '../componentes/geral/MenuSelecaoLayout';
import ListaUsuarios from '../componentes/usuarios/ListaUsuarios';
import TabelaUsuarios from '../componentes/usuarios/TabelaUsuarios';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  alterarOrdem,
  buscarTodos,
  pesquisar
} from '../store/ducks/usuarios';

class PaginaUsuarios extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      escolaridade: {},
      sexo: {}
    };
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  ajustarTela = () => (window.innerWidth < 768)
    ? this.props.alterarLayout('lista')
    : this.props.alterarLayout('tabela')

  componentDidMount() {
    this.props.alterarOrdem('alfabetica');
    setTimeout(() => {
      this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem);
    });
    this.ajustarTela();
    window.addEventListener("resize", window.addEventListener("resize", this.ajustarTela.bind(this)));
   
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item container md={3} sm={12} xs={12} alignItems="center">
              <Typography>
                Total de itens: <strong>{this.props.totalUsuarios}</strong>
              </Typography>
            </Grid>
            <Grid item container md={9} sm={12} xs={12} justify="flex-end" alignItems="center">
               {/* FILTRO POR SEXO */}
               {this.state.sexo && this.state.sexo.label
                ? <Chip
                    style={{marginBottom: '1em'}}
                    onDelete={e => this.setState({sexo: {}}, () => {
                      this.props.pesquisar(
                        this.props.pesquisa,
                        (this.state.escolaridade && this.state.escolaridade.slug) ? this.state.escolaridade.slug : null,
                        (this.state.sexo && this.state.sexo.slug) ? this.state.sexo.slug : null
                      );
                    })}
                    icon={<Icon>face</Icon>}
                    label={this.state.sexo.label} />
                : ''}
              {/* FILTRO POR ESCOLARIDADE */}
              {this.state.escolaridade && this.state.escolaridade.label
                ? <Chip
                    style={{marginBottom: '1em'}}
                    onDelete={e => this.setState({escolaridade: {}}, () => {
                      this.props.pesquisar(
                        this.props.pesquisa,
                        (this.state.escolaridade && this.state.escolaridade.slug) ? this.state.escolaridade.slug : null,
                        (this.state.sexo && this.state.sexo.slug) ? this.state.sexo.slug : null
                      );
                    })}
                    icon={<Icon>school</Icon>}
                    label={this.state.escolaridade.label} />
                : ''}
              {/* PESQUISA */}
              <InputPesquisa 
                aoPesquisar={() => this.props.pesquisar(
                  this.props.pesquisa,
                  (this.state.escolaridade && this.state.escolaridade.slug) ? this.state.escolaridade.slug : null
                )}
                aoRemoverPesquisa={() => this.props.buscarTodos(1, this.props.qtde, this.props.ordem)} />
              {/* LAYOUT */}
              <MenuSelecaoLayout
                layout={this.props.layout}
                aoAlterarLayout={layout => this.props.alterarLayout(layout)} />
            </Grid>
          </Grid>
          {/* TABELA */}
          {(this.props.layout === 'tabela')
            ? <TabelaUsuarios
                aoFiltrarPorEscolaridade={e => {
                  this.setState({escolaridade: e}, () => {
                    this.props.pesquisar(
                      this.props.pesquisa,
                      (this.state.escolaridade && this.state.escolaridade.slug) ? this.state.escolaridade.slug : null,
                      (this.state.sexo && this.state.sexo.slug) ? this.state.sexo.slug : null
                    );
                  });
                }} aoFiltrarPorSexo={e => {
                  this.setState({sexo: e}, () => {
                    this.props.pesquisar(
                      this.props.pesquisa,
                      (this.state.escolaridade && this.state.escolaridade.slug) ? this.state.escolaridade.slug : null,
                      (this.state.sexo && this.state.sexo.slug) ? this.state.sexo.slug : null
                    );
                  });
                }} /> : ''}
          {/* LISTA */}
          {(this.props.layout === 'lista')
            ? <ListaUsuarios /> : ''}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.usuarios.carregando,
    erro: state.usuarios.erro,
    layout: state.usuarios.layout,
    usuarios: state.usuarios.usuarios,
    totalUsuarios: state.usuarios.total,
    selecionados: state.usuarios.selecionados,
    pagina: state.usuarios.pagina,
    qtde: state.usuarios.qtde,
    ordem: state.usuarios.ordem,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    alterarOrdem: (ordem) => dispatch(alterarOrdem(ordem)),
    buscarTodos: (pagina, qtde, ordem) => dispatch(buscarTodos(pagina, qtde, ordem)),
    pesquisar: (pesquisa, escolaridade, sexo) => dispatch(pesquisar(pesquisa, escolaridade, sexo))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaUsuarios));