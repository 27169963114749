import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import reducers from './ducks';
import sagas from './sagas';

// HISTORY

export const history = createBrowserHistory();

// PERSISTENCIA 

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(routerMiddleware(history));
middlewares.push(sagaMiddleware);

const composer = compose(applyMiddleware(...middlewares));

const store = createStore(reducers(history), composer);

sagaMiddleware.run(sagas);

export { store };