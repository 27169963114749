const novoItem = {
  cidades: [],
  resgates: [],
  totalResgates: 0,
  resgatesHoje: 0,
  usuarios: [],
  voucher: {}
}

const stateInicial = {
  item: {...novoItem},
  itens: [],
  carregando: false,
  erro: '',
}

export const Types = {
  BUSCAR_ITEM_REQUEST: 'estatisticasProdutos/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'estatisticasProdutos/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'estatisticasProdutos/BUSCAR_ITEM_ERRO',
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.BUSCAR_ITEM_REQUEST: {
      return {
        ...state,
        carregando: true,
        erro: ''
      }
    }
    case Types.BUSCAR_ITEM_SUCESSO: {
      console.log(action);
      return {
        ...state,
        carregando: false,
        item: action.estatisticas
      }
    }
    case Types.BUSCAR_ITEM_ERRO: {
      return {
        ...state,
        carregando: false,
        erro: action.erro
      }
    }
    default: return {...state};
  }
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}