import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoConfiguracaoSite from '../../servicos/ServicoConfiguracaoSite';
import { Types } from '../ducks/configuracoesSite';
const servico = new ServicoConfiguracaoSite();

function* salvar (action) {
    try {
        const configuracao = yield call([servico, servico.atualizarConfiguracao], action.configuracao.id, action.configuracao);
        yield put({type: Types.SALVAR_SUCESSO, configuracao: configuracao.data, editar: true});
    } catch (e) {
        if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.SALVAR_ERRO, erro: e.data});
    }
}

function* buscarItem (action) {
    try {
        const configuracao = yield call([servico, servico.buscarConfiguracaoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, configuracao});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const configuracoes = yield call([servico, servico.buscarConfiguracoes]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, configuracoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* sagaConfiguracoes () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
}

export default sagaConfiguracoes;