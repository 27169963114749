import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';

// FORM
import CampoFormAutocomplete from '../../geral/CampoFormAutocomplete';
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormSelect from '../../geral/CampoFormSelect';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormTextarea from '../../geral/CampoFormTextarea';

import {
  Drawer, Typography, 
  CircularProgress, Button,
  Stepper, Step, StepLabel, StepContent
} from '@material-ui/core';

import { IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  buscarDadosCep
} from '../../../store/ducks/cep';

import {
  alterarItem,
  alterarStep,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/estabelecimentos';

import {
  buscarTodos as buscarCategorias
} from '../../../store/ducks/categoriasEstabelecimentos';
import CampoFormErro from '../../geral/CampoFormErro';

const styles = {
  overFlowVisible: {
    overflow: 'visible !important'
  },
  drawerNormal: {
    width: '500px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  drawerExtendido: {
    width: '768px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  btnFloatHover: {
    backgroundColor: 'white'
  },
  btnFloatNormal: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  },
  btnFloatExtendido: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%) rotate(180deg)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  }
};

function TextTelefone(props) {
  const { inputRef, ...other } = props;
  let { value } = props;
  value = (value) ? value.replace(/\D+/g, '') : '';
  const mascara = (value.length === 11) ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={(props.mask) ? props.mask : mascara}
      placeholderChar={'\u2000'}
    />
  );
}

function TextCpf(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={(props.mask) ? props.mask : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function TextCnpj(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={(props.mask) ? props.mask : [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function TextCep(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={(props.mask) ? props.mask : [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

class DrawerAdicionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: {},
      extendido: false
    }
  }
  componentDidMount() {
    this.props.alterarItem('estabelecimentos_id', this.props.match.params.id);
    this.props.buscarCategorias();
  }
  extenderMenu = (e) => {
    e.preventDefault();
    this.setState({extendido: !this.state.extendido});
  }
  salvar = (e) => {
    e.preventDefault();
    const estabelecimento = {...this.props.item};
    if (this.state.categoria && this.state.categoria.id) {
      estabelecimento['categorias_id'] = this.state.categoria.id;
    }
    this.props.salvar(estabelecimento);
  }
  alterarCep = (e) => {
    e.preventDefault();
    this.props.alterarItem('cep', e.target.value);
    if (e.target.value.trim().length === 9) {
      this.props.buscarDadosCep(e.target.value);
    }
  }
  render() {
    return (
      <div role="presentation">
        <Drawer
          classes={{
            paper: (this.state.extendido) ? this.props.classes.drawerExtendido : this.props.classes.drawerNormal
          }}
          anchor="right"
          open={this.props.mostrar}
          onClose={e => this.props.esconderForm()}>
          <IconButton
            classes={{
              root: (this.state.extendido) ? this.props.classes.btnFloatExtendido : this.props.classes.btnFloatNormal
            }}
            size="small"
            onClick={this.extenderMenu}
            color="primary"
            aria-label="Extender">
              <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1.5em', overflow: 'auto'}}>
            <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar estabelecimento</Typography>
            <Stepper orientation="vertical" activeStep={this.props.step}>
              <Step style={{width: '100%'}}>
                <StepLabel>Geral</StepLabel>
                <StepContent style={{width: '100%'}}>
                  {/* RAZAO SOCIAL */}
                  <CampoFormTexto
                    required
                    label="Razão Social"
                    value={this.props.item.razao_social}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.razao_social) ? this.props.erro.errors.razao_social[0] : ''}
                    onChange={e => this.props.alterarItem('razao_social', e.target.value)} />
                  {/* NOME FANTASIA */}
                  <CampoFormTexto
                    label="Nome Fantasia"
                    value={this.props.item.nome_fantasia}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome_fantasia) ? this.props.erro.errors.nome_fantasia[0] : ''}
                    onChange={e => this.props.alterarItem('nome_fantasia', e.target.value)} />
                  {/* TIPO */}
                  <CampoFormSelect
                    label="Tipo"
                    value={this.props.item.tipo}
                    onChange={e => this.props.alterarItem('tipo', e.target.value)}
                    opcoes={[
                      {value: 'pessoa_juridica', label: 'Pessoa jurídica'},
                      {value: 'pessoa_fisica', label: 'Pessoa física'}
                    ]}/>
                  {/* CPF */}
                  {(this.props.item.tipo === 'pessoa_fisica')
                    ? <CampoFormTexto
                        label="CPF"
                        inputComponent={TextCpf}
                        value={this.props.item.cpf}
                        erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.cpf) ? this.props.erro.errors.cpf[0] : ''}
                        onChange={e => this.props.alterarItem('cpf', e.target.value)} />
                        : ''}
                  {/* CNPJ */}
                  {(this.props.item.tipo === 'pessoa_juridica')
                    ? <CampoFormTexto
                        label="CNPJ"
                        inputComponent={TextCnpj}
                        value={this.props.item.cnpj}
                        erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.cnpj) ? this.props.erro.errors.cnpj[0] : ''}
                        onChange={e => this.props.alterarItem('cnpj', e.target.value)} />
                        : ''}
                  {/* CATEGORIA */}
                  <CampoFormAutocomplete
                    label="Categoria"
                    value={this.state.categoria}
                    options={this.props.categorias}
                    getOptionLabel={categoria => categoria.nome}
                    onChange={(e, categoria) => this.setState({categoria})} />
                  {/* BOTOES */}
                  <div className="flex-end">
                    <Button
                      onClick={e => this.props.alterarStep(1)}
                      variant="contained"
                      color="primary"
                      style={{marginTop: '1em'}}>Avançar</Button>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Contato</StepLabel>
                <StepContent>
                  <CampoFormTexto
                    label="E-mail"
                    value={this.props.item.email}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.email) ? this.props.erro.errors.email[0] : ''}
                    onChange={e => this.props.alterarItem('email', e.target.value)} />
                  <CampoFormTexto
                    label="Telefone"
                    inputComponent={TextTelefone} 
                    value={this.props.item.telefone}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.telefone) ? this.props.erro.errors.telefone[0] : ''}
                    onChange={e => this.props.alterarItem('telefone', e.target.value)} />
                  <CampoFormTexto
                    label="WhatsApp"
                    inputComponent={TextTelefone} 
                    value={this.props.item.whatsapp}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.whatsapp) ? this.props.erro.errors.whatsapp[0] : ''}
                    onChange={e => this.props.alterarItem('whatsapp', e.target.value)} />
                  <CampoFormTexto
                    ajuda="Copie e cole o link do perfil no Facebook"
                    label="Facebook"
                    value={this.props.item.facebook}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.facebook) ? this.props.erro.errors.facebook[0] : ''}
                    onChange={e => this.props.alterarItem('facebook', e.target.value)} />
                    {(this.props.item.facebook)
                      ? <a className="andes-link" rel="noopener noreferrer" target="_blank" href={this.props.item.facebook}>Teste o link do seu perfil</a>
                      : '' }
                  <CampoFormTexto
                    ajuda="Copie e cole o link do perfil no Instagram"
                    label="Instagram"
                    value={this.props.item.instagram}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.instagram) ? this.props.erro.errors.instagram[0] : ''}
                    onChange={e => this.props.alterarItem('instagram', e.target.value)} />
                  {(this.props.item.instagram)
                      ? <a className="andes-link" rel="noopener noreferrer" target="_blank" href={this.props.item.instagram}>Teste o link do seu perfil</a>
                      : '' }
                  <CampoFormTexto
                    ajuda="Copie e cole o link do seu site"
                    label="Site"
                    value={this.props.item.site}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.site) ? this.props.erro.errors.site[0] : ''}
                    onChange={e => this.props.alterarItem('site', e.target.value)} />
                  <div className="flex-end">
                    <Button
                      onClick={e => this.props.alterarStep(0)}
                      style={{marginRight: '.5em'}}
                      variant="contained">Voltar</Button>
                    <Button
                      onClick={e => this.props.alterarStep(2)}
                      variant="contained"
                      color="primary">Avançar</Button>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Endereço</StepLabel>
                <StepContent>
                  <CampoFormTexto
                    carregando={this.props.carregandoCep}
                    label="CEP"
                    inputComponent={TextCep}
                    value={this.props.item.cep}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.cep) ? this.props.erro.errors.cep[0] : ''}
                    onChange={this.alterarCep} />
                  <CampoFormSelect
                    label="Estado"
                    value={this.props.item.estado}
                    onChange={e => this.props.alterarItem('estado', e.target.value)}
                    opcoes={[
                      {value: 'AC', label: 'AC'},
                      {value: 'AL', label: 'AL'},
                      {value: 'AP', label: 'AP'},
                      {value: 'AM', label: 'AM'},
                      {value: 'BA', label: 'BA'},
                      {value: 'CE', label: 'CE'},
                      {value: 'DF', label: 'DF'},
                      {value: 'ES', label: 'ES'},
                      {value: 'GO', label: 'GO'},
                      {value: 'MA', label: 'MA'},
                      {value: 'MT', label: 'MT'},
                      {value: 'MS', label: 'MS'},
                      {value: 'MG', label: 'MG'},
                      {value: 'PA', label: 'PA'},
                      {value: 'PB', label: 'PB'},
                      {value: 'PR', label: 'PR'},
                      {value: 'PE', label: 'PE'},
                      {value: 'PI', label: 'PI'},
                      {value: 'RJ', label: 'RJ'},
                      {value: 'RN', label: 'RN'},
                      {value: 'RS', label: 'RS'},
                      {value: 'RO', label: 'RO'},
                      {value: 'RR', label: 'RR'},
                      {value: 'SC', label: 'SC'},
                      {value: 'SP', label: 'SP'},
                      {value: 'SE', label: 'SE'},
                      {value: 'TO', label: 'TO'}
                    ]}/>
                  <CampoFormTexto
                    required
                    label="Cidade"
                    value={this.props.item.cidade}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.cidade) ? this.props.erro.errors.cidade[0] : ''}
                    onChange={e => this.props.alterarItem('cidade', e.target.value)} />
                  <CampoFormTexto
                    required
                    label="Bairro"
                    value={this.props.item.bairro}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.bairro) ? this.props.erro.errors.bairro[0] : ''}
                    onChange={e => this.props.alterarItem('bairro', e.target.value)} />
                  <CampoFormTexto
                    required
                    label="Logradouro"
                    value={this.props.item.logradouro}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.logradouro) ? this.props.erro.errors.logradouro[0] : ''}
                    onChange={e => this.props.alterarItem('logradouro', e.target.value)} />
                  <CampoFormTexto
                    type="number"
                    label="Número"
                    value={this.props.item.numero}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.numero) ? this.props.erro.errors.numero[0] : ''}
                    onChange={e => this.props.alterarItem('numero', e.target.value)} />
                  <CampoFormTexto
                    label="Complemento"
                    value={this.props.item.complemento}
                    erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.complemento) ? this.props.erro.errors.complemento[0] : ''}
                    onChange={e => this.props.alterarItem('complemento', e.target.value)} />
                  <div className="flex-end">
                    <Button
                      onClick={e => this.props.alterarStep(1)}
                      style={{marginRight: '.5em'}}
                      variant="contained">Voltar</Button>
                    <Button
                      onClick={e => this.props.alterarStep(3)}
                      variant="contained"
                      color="primary">Avançar</Button>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Final</StepLabel>
                <StepContent>
                  {/* REGRAS */}
                  <CampoFormTextarea
                    label="Regras do estabelecimento"
                    placeholder="Regras de pontuação e resgate do estabelecimento"
                    value={this.props.item.regras}
                    onChange={e => this.props.alterarItem('regras', e)} />
                  {(this.props.erro && this.props.erro.message)
                    ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
                    : ''}
                  {/* EXPIRACAO PONTOS */}
                  <CampoFormSelect
                    ajuda="Tempo de expiração dos pontos dos clientes."
                    label="Tempo de expiração dos pontos"
                    value={this.props.item.dias_expiracao_pontos}
                    onChange={e => this.props.alterarItem('dias_expiracao_pontos', e.target.value)}
                    opcoes={[
                      {value: 30, label: '1 mês'},
                      {value: 90, label: '3 meses'},
                      {value: 180, label: '6 meses'},
                      {value: 365, label: '1 ano'},
                    ]}/>
                  {/* STATUS */}
                  <CampoFormSelect
                    ajuda="Estabelecimentos inativos não serão exibidos para usuários do app."
                    label="Status"
                    value={this.props.item.status}
                    onChange={e => this.props.alterarItem('status', e.target.value)}
                    opcoes={[
                      {value: 'ativo', label: 'Ativo'},
                      {value: 'inativo', label: 'Inativo'},
                    ]}/>
                  <div className="flex-end">
                    <Button
                      onClick={e => this.props.alterarStep(2)}
                      style={{marginRight: '.5em'}}
                      variant="contained">Voltar</Button>
                    <Button
                      disabled={this.props.carregando}
                      type="submit"
                      style={{position: 'relative'}}
                      variant="contained"
                      color="primary">
                      Salvar
                      {(this.props.carregando)
                        ? <CircularProgress
                          size={24}
                          color="secondary"
                          style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                        : ''}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            </Stepper>
          </form>
        </Drawer>
      </div>
    )
  }
  
}

const mapStateToProps = state => {
  return {
    carregando: state.estabelecimentos.carregando,
    carregandoCep: state.cep.carregando,
    item: state.estabelecimentos.item,
    mostrar: state.estabelecimentos.mostrarForm,
    erro: state.estabelecimentos.erro,
    step: state.estabelecimentos.step,
    categorias: state.categoriasEstabelecimentos.itens,
    carregandoCatetorias: state.categoriasEstabelecimentos.carregando,
    erroCategorias: state.categoriasEstabelecimentos.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    alterarStep: (step) => dispatch(alterarStep(step)),
    salvar: (estabelecimento) => dispatch(salvar(estabelecimento)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm()),
    buscarDadosCep: (cep) => dispatch(buscarDadosCep(cep)),
    buscarCategorias: () => dispatch(buscarCategorias(1, 50))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerAdicionar)));