import React, { Component } from 'react';
import { CircularProgress, InputLabel, Input, FormControl, FormHelperText } from '@material-ui/core';

class CampoFormTexto extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        {(this.props.carregando) ? <CircularProgress color="secondary" size={24} style={{position: 'absolute', top: '1em', right: '1em'}} /> : ''}
        <InputLabel error={(this.props.erro) ? true : false}>{this.props.label}</InputLabel>
        <Input
          type={(this.props.type) ? this.props.type : 'text'}
          inputComponent={(this.props.inputComponent) ? this.props.inputComponent : 'input'}
          error={(this.props.erro) ? true : false}
          required={this.props.required}
          multiline={this.props.multiline}
          rows={(this.props.multiline) ? 5 : false}
          value={this.props.value}
          onChange={this.props.onChange} />
          {(this.props.erro || this.props.ajuda) ? 
            <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
            : ''}
      </FormControl>
    )
  }
}

export default CampoFormTexto;