const stateInicial = {
    carregando: false,
    erro: '',
    layout: 'tabela',
    itens: [],
    item: {},
    selecionados: [],
    mostrarForm: false
}

export const Types = {
    // ALTERACOES
    ALTERAR_LAYOUT: 'contatos/ALTERAR_LAYOUT',
    ALTERAR_ITEM: 'contatos/ALTERAR_ITEM',
    ADICIONAR_SELECAO: 'contatos/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'contatos/REMOVER_SELECAO',
    SELECIONAR_TODOS: 'contatos/SELECIONAR_TODOS',
    DESSELECIONAR_TODOS: 'contatos/DESSELECIONAR_TODOS',
    DEFINIR_ITEM: 'contatos/DEFINIR_ITEM',
    MOSTRAR_FORM: 'contatos/MOSTRAR_FORM',
    ESCONDER_FORM: 'contatos/ESCONDER_FORM',
    INICIAR_EDITAR: 'contatos/INICIAR_EDITAR',
    // SALVAR
    SALVAR_REQUEST: 'contatos/SALVAR_REQUEST',
    SALVAR_SUCESSO: 'contatos/SALVAR_SUCESSO',
    SALVAR_ERRO: 'contatos/SALVAR_ERRO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'contatos/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'contatos/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'contatos/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'contatos/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'contatos/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'contatos/BUSCAR_ITEM_ERRO',
    // REMOVER
    REMOVER_REQUEST: 'contatos/REMOVER_REQUEST',
    REMOVER_SUCESSO: 'contatos/REMOVER_SUCESSO',
    REMOVER_ERRO: 'contatos/REMOVER_ERRO'
}

export default function reducer(state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_LAYOUT: {
            if (['tabela', 'lista'].indexOf(action.layout) > -1) {
                return {...state, layout: action.layout};
            }
            return {...state};
        }
        case Types.ALTERAR_ITEM: {
            const item = {...state.item}
            item[action.campo] = action.valor;
            return {...state, item, erro: ''}
        }
        case Types.DEFINIR_ITEM: {
            return {
                ...state,
                item: action.item
            }
        }
        case Types.ESCONDER_FORM: {
            return {...state, mostrarForm: false}
        }
        case Types.MOSTRAR_FORM: {
            return {
                ...state,
                erro: '',
                mostrarForm: true,
                item: {}
            }
        }
        case Types.INICIAR_EDITAR: {
            const item = {...action.contato};
            return {
              ...state,
              item,
              erro: '',
              mostrarForm: true
            }
          }
        case Types.SALVAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.SALVAR_SUCESSO: {
            let novoState = {
                ...state,
                carregando: false,
                mostrarForm: false,
                item: action.contato
            }
            const itens = [...state.itens];
            itens.map((item, i) => {
                if (item.id === action.contato.id) return itens[i] = action.contato;
                return null;
            });
            novoState = {...novoState, itens };
            return novoState;
        }
        case Types.SALVAR_ERRO: {
            console.log(action.erro);
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.contato
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                itens: action.contatos.data
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.contato);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(contato => contato.id !== action.contato.id);
            return {...state, selecionados};
        }
        case Types.SELECIONAR_TODOS: {
            const selecionados = [...state.itens];
            return {...state, selecionados};
        }
        case Types.DESSELECIONAR_TODOS: {
            const selecionados = [];
            return {...state, selecionados};
        }
        case Types.REMOVER_REQUEST: {
            const itens = [...state.itens];
            itens.map((contato, i) => {
                if (contato.id === action.contato.id) {
                    return itens[i].removendo = true;
                }
                return null;
            });
            return {...state, itens};
        }
        case Types.REMOVER_SUCESSO: {
            let itens = [...state.itens];
            let selecionados = [...state.selecionados];
            itens = itens.filter((contato) => contato.id !== action.contato.id)
            selecionados = selecionados.filter((contato) => contato.id !== action.contato.id)
            return {...state, itens, selecionados};
        }
        case Types.REMOVER_ERRO: {
            let itens = [...state.itens];
            itens.map((contato, i) => {
                return itens[i].removendo = false;
            });
            return {...state, erro: action.erro, itens};
        }
        default: return state;
    }
}

export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarItem(campo, valor) {
    return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25) {
    return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function adicionarSelecao(contato) {
    return { type: Types.ADICIONAR_SELECAO, contato }
}

export function removerSelecao(contato) {
    return { type: Types.REMOVER_SELECAO, contato }
}

export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(contato) {
    return { type: Types.REMOVER_REQUEST, contato }
}

export function definirNovoItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(contato) {
    return { type: Types.SALVAR_REQUEST, contato }
}

export function definirItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function mostrarForm() {
    return { type: Types.MOSTRAR_FORM };
}
  
export function esconderForm() {
    return { type: Types.ESCONDER_FORM };
}

export function iniciarEditar(contato) {
    return { type: Types.INICIAR_EDITAR, contato }
}