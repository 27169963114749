import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  Card, CardContent, Grid, Typography, Divider,
  Button, CircularProgress
} from '@material-ui/core';

import {
  alterarPagina,
  adicionarSelecao,
  buscarTodos,
  removerSelecao,
  selecionarTodos,
  desselecionarTodos,
  definirItem,
  remover,
  iniciarEditar
} from '../../../store/ducks/categoriasEstabelecimentos';

class ListaCategorias extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }
  // INFINITE SCROLL PARA A VIEW EM LISTA
  handleScrolling = () => {
    const wrappedElement = document.getElementById('listaDados');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      if (this.props.paginas > this.props.pagina && !this.props.carregando) {
        this.props.alterarPagina(this.props.pagina + 1);
        this.props.buscarTodos(this.props.pagina, this.props.qtde);
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  mostrarConfirmacaoRemover = (categoria) => {
    this.refs.confirmacaoRemover.mostrar(categoria);
  }
  editar = (categoria) => {
    this.props.definirItem(categoria);
    this.props.irParaEditar(categoria.id);
  }
  render() {
    return (
      <div id="listaDados">
        {(this.props.carregando && this.props.pagina === 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {this.props.categorias.map((categoria, i) => {
          return (
            <Card key={i} raised style={{marginBottom: '.5em', padding: '.5em'}}>
              <CardContent>
                <Typography variant="h5" style={{textTransform: 'uppercase'}}>{categoria.nome}</Typography>
                <Divider style={{margin: '1em 0'}} />
                <Grid container>
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button 
                      onClick={e => this.props.iniciarEditar(categoria)}
                      variant="outlined"
                      size="small">Editar</Button>
                    <Button 
                      onClick={e => this.mostrarConfirmacaoRemover(categoria)}
                      variant="outlined"
                      size="small"
                      color="secondary">Remover</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {/* LOADER MAIS */}
        {(this.props.carregando && this.props.pagina > 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {/* CONFIRMAÇÃO */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.remover(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      categorias: state.categoriasEstabelecimentos.itens,
      selecionados: state.categoriasEstabelecimentos.selecionados,
      paginas: state.categoriasEstabelecimentos.paginas,
      pagina: state.categoriasEstabelecimentos.pagina,
      qtde: state.categoriasEstabelecimentos.qtde,
      carregando: state.categoriasEstabelecimentos.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
      alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
      adicionarSelecao: (categoria) => dispatch(adicionarSelecao(categoria)),
      removerSelecao: (categoria) => dispatch(removerSelecao(categoria)),
      selecionarTodos: () => dispatch(selecionarTodos()),
      desselecionarTodos: () => dispatch(desselecionarTodos()),
      remover: (categoria) => dispatch(remover(categoria)),
      buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
      definirItem: (categoria) => dispatch(definirItem(categoria)),
      iniciarEditar: (categoria) => dispatch(iniciarEditar(categoria))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaCategorias);