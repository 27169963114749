import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoUsuario';
import { Types } from '../ducks/usuarios';
import { Types as PesquisaTypes } from '../ducks/pesquisa';
const servico = new Servico();

function* buscarItem (action) {
    try {
        const usuario = yield call([servico, servico.buscarUsuarioPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, usuario});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const usuarios = yield call([servico, servico.buscarUsuarios], action.qtde, action.pagina, action.ordem);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, usuarios});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* pesquisar (action) {
    try {
        const usuarios = yield call([servico, servico.pesquisarUsuarios], action.pesquisa, action.escolaridade, action.sexo, action.qtde, action.pagina);
        yield put({type: Types.PESQUISAR_SUCESSO, usuarios});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.PESQUISAR_ERRO, erro: e.message});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerUsuario], action.usuario.id);
        yield put({type: Types.REMOVER_SUCESSO, usuario: action.usuario});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaEstab () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(Types.PESQUISAR_REQUEST, pesquisar);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
}

export default sagaEstab;