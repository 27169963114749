import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import FormLogin from '../forms/FormLogin';
import CampoFormErro from '../componentes/geral/CampoFormErro';
import ServicoLogin from '../servicos/ServicoLogin';
import * as moment from 'moment';
import '../css/PaginaLogin.css';

class PaginaLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = { carregando: false, desabilitado: false, erro: null };
    this.servicoLogin = new ServicoLogin();
  }

  efetuarLogin = (email, senha) => {
    this.setState({ carregando: true, desabilitado: true, erro: null });
    this.servicoLogin.efetuarLogin(email, senha)
      .then(token => {
        this.setState({ carregando: false, desabilitado: false });
        this.servicoLogin.buscarUsuarioApi()
          .then(usuario => {
            this.setState({ carregando: false, desabilitado: false, erro: null });
            this.props.history.push('/');
          }).catch(err => {
            this.setState({ carregando: false, desabilitado: false, erro: 'Falha ao buscar dados do usuário.' });    
          });
      }).catch(err => {
        this.setState({ carregando: false, desabilitado: false, erro: 'Usuário ou senha incorretos.' });
      });
  }

  renderCarregando = () => {
    if (this.state.carregando) {
      return <LinearProgress color="secondary" />;
    }
  }

  renderErro = () => {
    if (this.state.erro) {
      return <CampoFormErro titulo="Login inválido" mensagem="Seu usuário ou senha estão incorretos" />
    }
  }

  render() {
    return (
      <div className="login-wrapper">
        <img src="../../assets/imagens/logo-branco.png" alt="Pontua" />
        <Card raised>
          {this.renderCarregando()}
          <CardContent>
            <Typography variant="h4" className="mb-0" gutterBottom>Login</Typography>
            <Typography variant="subtitle1" className="mt-0">Painel de administração</Typography>
            <Divider />
            {this.renderErro()}
            <FormLogin aoLogin={this.efetuarLogin} desabilitado={this.state.desabilitado} />
          </CardContent>
        </Card>
        <p style={{textAlign: 'center', color: 'white'}}>{moment().year()} © Pontua fidelidade.</p>
      </div>
    );
  }
}

export default PaginaLogin;