import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  Chip, Card, CardContent, Grid, Typography, Divider,
  Table, TableBody, TableRow, TableCell, Button, CircularProgress
} from '@material-ui/core';

import {
  alterarPagina,
  adicionarSelecao,
  buscarTodos,
  removerSelecao,
  selecionarTodos,
  desselecionarTodos,
  definirNovoItem,
  remover,
  iniciarEditar
} from '../../../store/ducks/admins';

class ListaAdmins extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }
  // INFINITE SCROLL PARA A VIEW EM LISTA
  handleScrolling = () => {
    const wrappedElement = document.getElementById('listaDados');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      if (this.props.paginas > this.props.pagina && !this.props.carregando) {
        this.props.alterarPagina(this.props.pagina + 1);
        this.props.buscarTodos(this.props.pagina, this.props.qtde);
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  mostrarConfirmacaoRemover = (admin) => {
    this.refs.confirmacaoRemover.mostrar(admin);
  }
  render() {
    return (
      <div id="listaDados">
        {(this.props.carregando && this.props.pagina === 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {this.props.admins.map((admin, i) => {
          return (
            <Card key={i} raised style={{marginBottom: '.5em', padding: '.5em'}}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5" style={{textTransform: 'uppercase'}}>{admin.nome}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Chip color="primary" label={admin.funcao.nome} />
                  </Grid>
                </Grid>
                <Divider style={{margin: '1em 0'}} />
                <Grid container>
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell><strong>E-mail</strong></TableCell>
                          <TableCell>
                            <Typography>{admin.email}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button 
                      onClick={e => this.props.iniciarEditar(admin)}
                      variant="outlined"
                      size="small">Editar</Button>
                    <Button 
                      onClick={e => this.mostrarConfirmacaoRemover(admin)}
                      variant="outlined"
                      size="small"
                      color="secondary">Remover</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {/* LOADER MAIS */}
        {(this.props.carregando && this.props.pagina > 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {/* CONFIRMAÇÃO */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.remover(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      admins: state.admins.admins,
      selecionados: state.admins.selecionados,
      paginas: state.admins.paginas,
      pagina: state.admins.pagina,
      qtde: state.admins.qtde,
      carregando: state.admins.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
      alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
      adicionarSelecao: (admin) => dispatch(adicionarSelecao(admin)),
      removerSelecao: (admin) => dispatch(removerSelecao(admin)),
      selecionarTodos: () => dispatch(selecionarTodos()),
      desselecionarTodos: () => dispatch(desselecionarTodos()),
      remover: (admin) => dispatch(remover(admin)),
      buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
      definirNovoItem: (admin) => dispatch(definirNovoItem(admin)),
      iniciarEditar: (admin) => dispatch(iniciarEditar(admin))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaAdmins);