import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ErroForm from '../componentes/ErroForm';
import DrawerContatos from '../componentes/contatos/DrawerLer';
import TabelaContatos from '../componentes/contatos/TabelaContatos';

// REDUX
import {
  alterarLayout,
  buscarTodos,
  mostrarForm
} from '../store/ducks/contatos';

class PaginaContatos extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
          <DrawerContatos />
          <TabelaContatos />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.contatos.carregando,
    erro: state.contatos.erro,
    layout: state.contatos.layout,
    selecionados: state.contatos.selecionados
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    buscarTodos: () => dispatch(buscarTodos()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaContatos));