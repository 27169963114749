import React from 'react';
import { ButtonGroup, Button, Icon } from '@material-ui/core';

export default function MenuSelecaoLayout(props) {
  return (
    <ButtonGroup variant="contained" size="small" style={{border: 'none', boxShadow: 'none'}} >
      <Button
        onClick={() => props.aoAlterarLayout('lista')}
        color={`${(props.layout === 'lista') ? 'primary' : ''}`}>
        <Icon>view_agenda</Icon>
      </Button>
      <Button
        onClick={() => props.aoAlterarLayout('tabela')}
        color={`${(props.layout === 'tabela') ? 'primary' : ''}`}>
        <Icon>view_headline</Icon>
      </Button>
    </ButtonGroup>
  )
}
