const stateInicial = {
  pesquisa: '',
  pesquisado: '',
  pesquisando: false
}

export const Types = {
  ALTERAR_PESQUISA: 'pesquisa/ALTERAR_PESQUISA',
  PESQUISAR: 'pesquisa/PESQUISAR',
  REMOVER_LOADER: 'pesquisa/REMOVER_LOADER',
  REMOVER_PESQUISA: 'pesquisa/REMOVER_PESQUISA'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_PESQUISA: {
      return {
        ...state,
        pesquisa: action.pesquisa
      }
    }
    case Types.PESQUISAR: {
      return {
        ...state,
        pesquisa: '',
        pesquisando: true,
        pesquisado: action.pesquisa
      }
    }
    case Types.REMOVER_PESQUISA: {
      return {
        ...state,
        pesquisa: '',
        pesquisando: false,
        pesquisado: ''
      }
    }
    case Types.REMOVER_LOADER: {
      return {
        ...state,
        pesquisando: false
      }
    }
    default: return state;
  }
}

export function alterarPesquisa (pesquisa) {
  return {
    type: Types.ALTERAR_PESQUISA,
    pesquisa
  }
}

export function pesquisar(pesquisa) {
  return {
    type: Types.PESQUISAR,
    pesquisa
  }
}

export function removerPesquisa() {
  return {
    type: Types.REMOVER_PESQUISA
  }
}

export function removerLoader() {
  return {
    type: Types.REMOVER_LOADER
  }
}