import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import DrawerCategorias from '../componentes/categoriasEstabelecimentos/DrawerAdicionar';
import ErroForm from '../componentes/ErroForm';
import InputPesquisa from '../componentes/geral/InputPesquisa';
import MenuSelecaoLayout from '../componentes/geral/MenuSelecaoLayout';
import ListaCategorias from '../componentes/categoriasEstabelecimentos/ListaCategorias';
import TabelaCategorias from '../componentes/categoriasEstabelecimentos/TabelaCategorias';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  buscarTodos,
  pesquisar,
  mostrarForm
} from '../store/ducks/categoriasEstabelecimentos';

class PaginaCategoriasEstabelecimentos extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  ajustarTela = () => (window.innerWidth < 768)
    ? this.props.alterarLayout('lista')
    : this.props.alterarLayout('tabela')

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
    this.ajustarTela();
    window.addEventListener("resize", window.addEventListener("resize", this.ajustarTela.bind(this)));
   
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              {/* ADICIONAR */}
              <Button color="primary" variant="contained" onClick={this.props.mostrarForm}>Adicionar</Button>
            </Grid>
            <Grid item container md={6} sm={12} xs={12} justify="flex-end">
              {/* PESQUISA */}
              <InputPesquisa 
                aoPesquisar={() => this.props.pesquisar(this.props.pesquisa)}
                aoRemoverPesquisa={() => this.props.buscarTodos(1, this.props.qtde)} />
              {/* LAYOUT */}
              <MenuSelecaoLayout
                layout={this.props.layout}
                aoAlterarLayout={layout => this.props.alterarLayout(layout)} />
            </Grid>
          </Grid>
          <DrawerCategorias />
          {/* TABELA */}
          {(this.props.layout === 'tabela')
            ? <TabelaCategorias /> : ''}
          {/* LISTA */}
          {(this.props.layout === 'lista')
            ? <ListaCategorias /> : ''}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.categoriasEstabelecimentos.carregando,
    erro: state.categoriasEstabelecimentos.erro,
    layout: state.categoriasEstabelecimentos.layout,
    categorias: state.categoriasEstabelecimentos.categorias,
    selecionados: state.categoriasEstabelecimentos.selecionados,
    pagina: state.categoriasEstabelecimentos.pagina,
    qtde: state.categoriasEstabelecimentos.qtde,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    pesquisar: (pesquisa) => dispatch(pesquisar(pesquisa)),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaCategoriasEstabelecimentos));