import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';

// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormTextarea from '../../geral/CampoFormTextarea';
import CampoFormDatepicker from '../../geral/CampoFormDatepicker';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, 
  CircularProgress, Button
} from '@material-ui/core';

import {
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles'

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/promocoes';

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        maxWidth: '500px',
        width: '90%'
      }
    }
  }
});

class DrawerAdicionar extends Component {
  componentDidMount() {
    this.props.alterarItem('estabelecimentos_id', this.props.match.params.id);
  }
  salvar = (e) => {
    e.preventDefault();
    const promocao = {
      ...this.props.item,
      inicio_validade: this.props.item.inicio_validade.format("YYYY-MM-DD HH:mm:ss"),
      final_validade: this.props.item.final_validade.format("YYYY-MM-DD HH:mm:ss")
    };
    if (this.props.estabelecimento.id) {
      promocao['estabelecimentos_id'] = this.props.estabelecimento.id;
    }
    this.props.salvar(promocao);
  }
  render() {
    return (
      <div role="presentation">
        <MuiThemeProvider theme={theme}>
          <Drawer anchor="right" open={this.props.mostrar} onClose={e => this.props.esconderForm()}>
            <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1em'}}>
              {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
              <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar promoção</Typography>
              {/* NOME */}
              <CampoFormTexto
                required
                label="Nome"
                value={this.props.item.nome}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
                onChange={e => this.props.alterarItem('nome', e.target.value)} />
              {/* DESCRICAO */}
              <CampoFormTextarea
                label="Detalhes"
                placeholder="Informe detalhes sobre a promoção"
                value={this.props.item.descricao}
                onChange={e => this.props.alterarItem('descricao', e)} />
              {/* INICIO VALIDADE */}
              <CampoFormDatepicker
                label="Válida desde"
                value={this.props.item.inicio_validade}
                onChange={e => this.props.alterarItem('inicio_validade', e)}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.inicio_validade) ? this.props.erro.errors.inicio_validade[0] : ''}
                />
              <CampoFormDatepicker
                label="Valida até"
                value={this.props.item.final_validade}
                onChange={e => this.props.alterarItem('final_validade', e)}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.final_validade) ? this.props.erro.errors.final_validade[0] : ''}
                />
              {/* ATIVO */}
              <CampoFormSwitch
                label="Promoção ativa"
                checked={this.props.item.ativo}
                onChange={e => this.props.alterarItem('ativo', !this.props.item.ativo)} />
              {(this.props.erro && this.props.erro.message)
                ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
                : ''}
              <div className="flex-end">
                <Button
                  disabled={this.props.carregando}
                  type="submit"
                  style={{marginTop: '1em', position: 'relative'}}
                  variant="contained"
                  color="primary">
                  Salvar
                  {(this.props.carregando)
                    ? <CircularProgress
                      size={24}
                      color="secondary"
                      style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                    : ''}
                </Button>
              </div>
            </form>
          </Drawer>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.promocoes.carregando,
    estabelecimento: state.estabelecimentos.item,
    item: state.promocoes.item,
    mostrar: state.promocoes.mostrarForm,
    erro: state.promocoes.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (promocao) => dispatch(salvar(promocao)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAdicionar));