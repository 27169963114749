import * as moment from 'moment';

const novoItem = {
  id: '',
  titulo: '',
  descricao: '',
  tipo: 'percentual',
  percentual: 0,
  valor: 0,
  quantidade: 0,
  sem_limite_quantidade: false,
  inicio_validade: moment(),
  final_validade: moment().add('90', 'd'),
  nao_expira: false,
  funcao: 'interno',
  estabelecimentos_id: '',
  parceiros_id: ''
}

const stateInicial = {
  item: {...novoItem},
  itens: [],
  selecionados: [],
  layout: 'tabela',
  pagina: 1,
  paginas: 1,
  qtde: 25,
  tab: 0,
  mostrarForm: false,
  carregando: false,
  erro: '',
  desabilitado: false,
  step: 0
}

export const Types = {
  ALTERAR_LAYOUT: 'vouchers/ALTERAR_LAYOUT',
  ALTERAR_PAGINA: 'vouchers/ALTERAR_PAGINA',
  ALTERAR_QTDE: 'vouchers/ALTERAR_QTDE',
  ALTERAR_STEP: 'vouchers/ALTERAR_STEP',
  ADICIONAR_SELECAO: 'vouchers/ADICIONAR_SELECAO',
  REMOVER_SELECAO: 'vouchers/REMOVER_SELECAO',
  SELECIONAR_TODOS: 'vouchers/SELECIONAR_TODOS',
  DESSELECIONAR_TODOS: 'vouchers/DESSELECIONAR_TODOS',
  ALTERAR_ITEM: 'vouchers/ALTERAR_ITEM',
  ALTERAR_TAB: 'vouchers/ALTERAR_TAB',
  DEFINIR_ITEM: 'vouchers/DEFINIR_ITEM',
  MOSTRAR_FORM: 'vouchers/MOSTRAR_FORM',
  ESCONDER_FORM: 'vouchers/ESCONDER_FORM',
  INICIAR_EDITAR: 'vouchers/INICIAR_EDITAR',
  DEFINIR_ITENS: 'vouchers/DEFINIR_ITENS',
  BUSCAR_ITEM_REQUEST: 'vouchers/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'vouchers/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'vouchers/BUSCAR_ITEM_ERRO',
  SALVAR_REQUEST: 'vouchers/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'vouchers/SALVAR_SUCESSO',
  SALVAR_ERRO: 'vouchers/SALVAR_ERRO',
  REMOVER_REQUEST: 'vouchers/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'vouchers/REMOVER_SUCESSO',
  REMOVER_ERRO: 'vouchers/REMOVER_ERRO',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'vouchers/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'vouchers/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'vouchers/BUSCAR_TODOS_ERRO',
  // PESQUISAR
  PESQUISAR_REQUEST: 'vouchers/PESQUISAR_REQUEST',
  PESQUISAR_SUCESSO: 'vouchers/PESQUISAR_SUCESSO',
  PESQUISAR_ERRO: 'vouchers/PESQUISAR_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_PAGINA: {
        if (!isNaN(action.pagina)) {
            return {...state, pagina: action.pagina}
        }
        return {...state};
    }
    case Types.ALTERAR_QTDE: {
        if (!isNaN(action.qtde)) {
            return {...state, qtde: action.qtde, pagina: 1}
        }
        return {...state};
    }
    case Types.ALTERAR_STEP: {
      if(!isNaN(action.step)) {
          return {...state, step: action.step};
      }
      return {...state};
  }
    case Types.ALTERAR_LAYOUT: {
        if (['tabela', 'lista'].indexOf(action.layout) > -1) {
            return {...state, layout: action.layout};
        }
        return {...state};
    }
    case Types.ADICIONAR_SELECAO: {
        const selecionados = [...state.selecionados];
        selecionados.push(action.voucher);
        return {...state, selecionados};
    }
    case Types.REMOVER_SELECAO: {
        let selecionados = [...state.selecionados];
        selecionados = selecionados.filter(voucher => voucher.id !== action.voucher.id);
        return {...state, selecionados};
    }
    case Types.SELECIONAR_TODOS: {
        const selecionados = [...state.itens];
        return {...state, selecionados};
    }
    case Types.DESSELECIONAR_TODOS: {
        const selecionados = [];
        return {...state, selecionados};
    }
    case Types.BUSCAR_TODOS_REQUEST: {
        return {
            ...state,
            carregando: true,
            erro: ''
        }
    }
    case Types.BUSCAR_TODOS_SUCESSO: {
        const novoState = {
            ...state,
            carregando: false,
            itens: (state.layout === 'lista')
                ? [...state.itens].concat(action.vouchers.data)
                : action.vouchers.data,
            paginas: action.vouchers.meta.last_page
        }
        return novoState;
    }
    case Types.BUSCAR_TODOS_ERRO: {
        const novoState = {
            ...state,
            carregando: false,
            erro: action.erro
        }
        return novoState;
    }
    case Types.PESQUISAR_REQUEST: {
        return {
            ...state,
            pagina: 1,
            qtde: 25,
            erro: ''
        }
    }
    case Types.PESQUISAR_SUCESSO: {
        const novoState = {
            ...state,
            carregando: false,
            itens: action.vouchers.data,
            paginas: action.vouchers.meta.last_page
        }
        return novoState;
    }
    case Types.PESQUISAR_ERRO: {
        const novoState = {
            ...state,
            carregando: false,
            erro: action.erro
        }
        return novoState;
    }
    case Types.ESCONDER_FORM: {
      return {...state, mostrarForm: false}
    }
    case Types.MOSTRAR_FORM: {
      return {...state, mostrarForm: true, item: {...novoItem}, erro: ''}
    }
    case Types.ALTERAR_TAB: {
      if(!isNaN(action.tab)) return {...state, tab: action.tab};
      return {...state};
    }
    case Types.ALTERAR_ITEM: {
      const item = {...state.item}
      item[action.campo] = action.valor;
      return {...state, item, erro: ''};
    }
    case Types.BUSCAR_ITEM_REQUEST: {
      return {
        ...state,
        carregando: true,
        desabilitado: true,
        erro: ''
      }
    }
    case Types.BUSCAR_ITEM_SUCESSO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        item: action.voucher
      }
    }
    case Types.BUSCAR_ITEM_ERRO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        erro: action.erro
      }
    }
    case Types.DEFINIR_ITEM: {
      return {...state, item: action.item}
    }
    case Types.DEFINIR_ITENS: {
      return {...state, itens: action.itens}
    }
    case Types.SALVAR_REQUEST: {
      return {
        ...state,
        carregando: true,
        erro: ''
      }
    }
    case Types.SALVAR_SUCESSO: {
      let novoState = {
        ...state,
        carregando: false,
        mostrarForm: false,
        item: action.voucher
      }
      if (action.editar) {
        const itens = [...state.itens];
        itens.map((item, i) => {
          if (item.id === action.voucher.id) return itens[i] = action.voucher;
          return null;
        });
        novoState = {...novoState, itens };
      } else {
        novoState = {...novoState, itens: [...state.itens, action.voucher]};
      }
      return novoState;
    }
    case Types.SALVAR_ERRO: {
      return {
        ...state, carregando: false, erro: action.erro
      }
    }
    case Types.INICIAR_EDITAR: {
      const item = {...action.voucher};
      if (typeof item.inicio_validade === 'string') item.inicio_validade = moment(item.inicio_validade)
      if (typeof item.final_validade === 'string') item.final_validade = moment(item.final_validade)
      return {
        ...state,
        item,
        erro: '',
        mostrarForm: true
      }
    }
    case Types.REMOVER_REQUEST: {
      let itens = [...state.itens];
      itens.map((voucher, i) => {
          if (voucher.id === action.voucher.id) return itens[i].carregando = true;
          return null;
      });
      return { ...state, erro: '', itens }
    }
    case Types.REMOVER_SUCESSO: {
      let itens = [...state.itens];
      itens = itens.filter(voucher => voucher.id !== action.voucher.id);
      return { ...state, itens }
    }
    case Types.REMOVER_ERRO: {
      return { ...state, erro: action.erro }
    }
    default: return {...state};
  }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor };
}

export function alterarTab(tab) {
  return { type: Types.ALTERAR_TAB, tab }
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item };
}

export function buscarTodos(pagina = 1, qtde = 25) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function pesquisar(pesquisa) {
  return { type: Types.PESQUISAR_REQUEST, pesquisa }
}

export function definirItens(itens) {
  return { type: Types.DEFINIR_ITENS, itens }
}

export function mostrarForm() {
  return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
  return { type: Types.ESCONDER_FORM };
}

export function salvar(voucher) {
  return { type: Types.SALVAR_REQUEST, voucher }
}

export function iniciarEditar(voucher) {
  return { type: Types.INICIAR_EDITAR, voucher }
}

export function remover(voucher) {
  return { type: Types.REMOVER_REQUEST, voucher }
}

export function alterarLayout(layout) {
  return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
  return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
  return { type: Types.ALTERAR_PAGINA, pagina }
}


export function selecionarTodos() {
  return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
  return { type: Types.DESSELECIONAR_TODOS }
}

export function adicionarSelecao(estabelecimento) {
  return { type: Types.ADICIONAR_SELECAO, estabelecimento }
}

export function removerSelecao(estabelecimento) {
  return { type: Types.REMOVER_SELECAO, estabelecimento }
}

export function alterarStep(step) {
  return { type: Types.ALTERAR_STEP, step }
}