import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { AppBar, Avatar, ListItemAvatar, IconButton, Toolbar } from '@material-ui/core';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';
import ServicoLogin from '../../../servicos/ServicoLogin';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: [
      '-apple-system',
      'Lato',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#58359D'
    },
    secondary: {
      main: '#ff3f6e'
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: 'linear-gradient(to right top, #efefef, #f3f3f3, #f7f7f7, #fbfbfb, #ffffff)'
      }
    },
    MuiDrawer: {
      paper: {
        borderRight: '4px solid #58359D !important',
        backgroundImage: 'linear-gradient(to right top, #222222, #242424, #262626, #282828, #2a2a2a)'
      }
    },
    MuiListItemText: {
      primary: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
      secondary: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  }
});

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.servicoLogin = new ServicoLogin();
    this.state = {
      administradores: false,
      estabelecimentos: false,
      site: false,
      open: true,
      mostrarConfirmacaoSair: false,
      usuario: this.servicoLogin.usuario
    };
  }

  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    }, () => {
      this.ajustarWrapper();
    });
  }
  ajustarWrapper = () => {
    const appWrapper = document.querySelector('.app-wrapper');
    if (!this.state.open) {
      appWrapper.className = appWrapper.className.concat(' fullscreen');
    } else {
      appWrapper.className = appWrapper.className.replace(' fullscreen', '');
    }
  }
  handleResize = () => {
    this.setState({ open: !(window.innerWidth < 768) }, () => {
      this.ajustarWrapper();
    });
  }
  
  componentDidMount() {
    this.setState({ open: !(window.innerWidth < 768) });
    window.addEventListener("resize", window.addEventListener("resize", this.handleResize.bind(this)));
    const path = this.props.history.location.pathname;
    this.selecionarMenuPeloPath(path);
    this.inscreverAlteracoesRota();
  }

  componentWillReceiveProps(props) {
    if (props.usuario) this.setState({ usuario: props.usuario });
    if (props.selecionado) this.setState({ selecionado: props.selecionado });
  }

  selecionarMenuPeloPath(path) {
    if (path.indexOf('/editar/') > -1 || path.indexOf('/ver/') > -1) {
      const arrPath = path.split('/');
      arrPath.pop();
      path = arrPath.join('/');
    }
    switch(path) {
      case '/': return this.setState({
        selecionado: 'dashboard',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: false
      });
      case '/admins': return this.setState({
        selecionado: 'admins',
        assinaturas: false,
        administradores: true,
        estabelecimentos: false,
        site: false
      });
      case '/categorias-estabelecimentos': return this.setState({
        selecionado: 'categorias-estabelecimentos',
        assinaturas: false,
        administradores: false,
        estabelecimentos: true,
        site: false
      });
      case '/estabelecimentos': return this.setState({
        selecionado: 'estabelecimentos',
        assinaturas: false,
        administradores: false,
        estabelecimentos: true,
        site: false
      });
      case '/funcoes': return this.setState({
        selecionado: 'funcoes',
        assinaturas: false,
        administradores: true,
        estabelecimentos: false,
        site: false
      });
      case '/me': return this.setState({
        selecionado: 'perfil',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: false
      });
      case '/planos': return this.setState({
        selecionado: 'planos',
        assinaturas: true,
        administradores: false,
        estabelecimentos: false,
        site: false
      });
      case '/produtos': return this.setState({
        selecionado: 'produtos',
        assinaturas: false,
        administradores: false,
        estabelecimentos: true,
        site: false
      });
      case '/promocoes': return this.setState({
        selecionado: 'promocoes',
        assinaturas: false,
        administradores: false,
        estabelecimentos: true,
        site: false
      });
      case '/usuarios': return this.setState({
        selecionado: 'usuarios',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: false
      });
      case '/vouchers': return this.setState({
        selecionado: 'vouchers',
        assinaturas: false,
        administradores: false,
        estabelecimentos: true,
        site: false
      });
      case '/contatos-site': return this.setState({
        selecionado: 'contatos-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/inscricoes-site': return this.setState({
        selecionado: 'inscricoes-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/configuracoes-site': return this.setState({
        selecionado: 'configuracoes-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/depoimentos-site': return this.setState({
        selecionado: 'depoimentos-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/slides-site': return this.setState({
        selecionado: 'slides-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/textos-site': return this.setState({
        selecionado: 'textos-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/perguntas-frequentes-site': return this.setState({
        selecionado: 'perguntas-frequentes',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      case '/vantagens-site': return this.setState({
        selecionado: 'vantagens-site',
        assinaturas: false,
        administradores: false,
        estabelecimentos: false,
        site: true
      });
      default: return null;
    }
  }

  inscreverAlteracoesRota = () => {
    this.props.history.listen((location, action) => {
      if (action === 'PUSH' || action === 'POP') {
        const path = location.pathname;
        this.selecionarMenuPeloPath(path);
      }
    })
  }

  mostrarModalSair = (e) => {
    e.preventDefault();
    this.refs.confirmacaoSair.mostrar();
  }

  sair = () => {
    localStorage.clear();
    this.props.sair();
  }

  renderAvatar = () => {
    if (this.state.usuario.foto) {
      return (
        <Avatar src={this.state.usuario.foto.url_thumb}></Avatar>
      );
    } else {
      return (
        <Avatar style={{backgroundColor: 'white'}}>{
          `
          ${this.state.usuario.nome.split(' ')[0][0]}${(this.state.usuario.nome.split(' ').length) ? this.state.usuario.nome.split(' ')[this.state.usuario.nome.split(' ').length - 1][0] : ''}
          `
          }</Avatar>
      );
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <div style={{display: 'flex'}}>
        <MuiThemeProvider theme={theme}>
          <AppBar>
            <Toolbar variant="dense" style={{paddingLeft: (this.state.open) ? '246px' : ''}}>
              <IconButton color="primary" aria-label="Menu" onClick={this.toggleMenu}>
                <MenuIcon />
              </IconButton>
              <img src="../../assets/imagens/logo-cor.png" style={{marginLeft: '1em', maxHeight: '2em'}} alt="Pontua" />
              <div className="flex-grow-1"></div>
              <Typography color="primary">PAINEL DO ADMINISTRADOR</Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="persistent"
            anchor="left"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              }),
            }}
            open={this.state.open}>
            <List>
              <ListItem button onClick={ e => this.props.history.push('/me') }>
                <ListItemAvatar>
                  {this.renderAvatar()}
                </ListItemAvatar>
                <ListItemText primary={this.state.usuario.nome} secondary={this.state.usuario.email} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem selected={this.state.selecionado === 'dashboard'} button onClick={ e => this.props.history.push('/') }>
                <ListItemIcon><Icon>dashboard</Icon></ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              {/* ESTABELECIMENTOS */}
              <ListItem button onClick={ e => this.setState({ estabelecimentos: !this.state.estabelecimentos }) }>
                <ListItemIcon><Icon>storefront</Icon></ListItemIcon>
                <ListItemText primary="Estabelecimentos" />
                {this.state.estabelecimentos ? <ExpandLess style={{color: 'white'}} /> : <ExpandMore style={{color: 'white'}} />}
              </ListItem>
              <Collapse in={this.state.estabelecimentos} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem selected={this.state.selecionado === 'categorias-estabelecimentos'} button onClick={ e => this.props.history.push('/categorias-estabelecimentos') }>
                    <ListItemText inset primary="Categorias" />
                  </ListItem>
                  <ListItem selected={this.state.selecionado === 'estabelecimentos'} button onClick={ e => this.props.history.push('/estabelecimentos') }>
                    <ListItemText inset primary="Estabelecimentos" />
                  </ListItem>
                  <ListItem selected={this.state.selecionado === 'vouchers'} button onClick={ e => this.props.history.push('/vouchers') }>
                    {/* <ListItemIcon><Icon>loyalty</Icon></ListItemIcon> */}
                    <ListItemText inset primary="Cupons" />
                  </ListItem>
                  <ListItem selected={this.state.selecionado === 'produtos'} button onClick={ e => this.props.history.push('/produtos') }>
                    {/* <ListItemIcon><Icon>list</Icon></ListItemIcon> */}
                    <ListItemText inset primary="Prêmios" />
                  </ListItem>
                  <ListItem selected={this.state.selecionado === 'promocoes'} button onClick={ e => this.props.history.push('/promocoes') }>
                    {/* <ListItemIcon><Icon>shopping_cart</Icon></ListItemIcon> */}
                    <ListItemText inset primary="Promoções" />
                  </ListItem>
                </List>
              </Collapse>
              {/* SITE */}
              <ListItem button onClick={ e => this.setState({ site: !this.state.site }) }>
                <ListItemIcon>
                  <Icon>web</Icon>
                </ListItemIcon>
                <ListItemText primary="Site" />
                {this.state.site ? <ExpandLess style={{color: 'white'}} /> : <ExpandMore style={{color: 'white'}} />}
              </ListItem>
              <Collapse in={this.state.site} timeout="auto" unmountOnExit>
                <ListItem selected={this.state.selecionado === 'contatos-site'} button onClick={ e => this.props.history.push('/contatos-site') }>
                  <ListItemText inset primary="Contatos recebidos" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'inscricoes-site'} button onClick={ e => this.props.history.push('/inscricoes-site') }>
                  <ListItemText inset primary="Inscrições recebidas" />
                </ListItem>
                <Divider />
                <ListItem selected={this.state.selecionado === 'configuracoes-site'} button onClick={ e => this.props.history.push('/configuracoes-site') }>
                  <ListItemText inset primary="Configurações" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'depoimentos-site'} button onClick={ e => this.props.history.push('/depoimentos-site') }>
                  <ListItemText inset primary="Depoimentos" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'slides-site'} button onClick={ e => this.props.history.push('/slides-site') }>
                  <ListItemText inset primary="Slides" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'textos-site'} button onClick={ e => this.props.history.push('/textos-site') }>
                  <ListItemText inset primary="Textos" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'perguntas-frequentes-site'} button onClick={ e => this.props.history.push('/perguntas-frequentes-site') }>
                  <ListItemText inset primary="Perguntas frequentes" />
                </ListItem>
                <ListItem selected={this.state.selecionado === 'vantagens-site'} button onClick={ e => this.props.history.push('/vantagens-site') }>
                  <ListItemText inset primary="Vantagens" />
                </ListItem>
              </Collapse>
              {/* PLANOS E ASSINATURAS */}
              {/* <ListItem button onClick={ e => this.setState({ assinaturas: !this.state.assinaturas }) }>
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Assinaturas" />
                {this.state.assinaturas ? <ExpandLess style={{color: 'white'}} /> : <ExpandMore style={{color: 'white'}} />}
              </ListItem>
              <Collapse in={this.state.assinaturas} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem selected={this.state.selecionado === 'planos'} button onClick={ e => this.props.history.push('/planos') }>
                    <ListItemText inset primary="Planos" />
                  </ListItem>
                </List>
              </Collapse> */}
              {/* ADMINISTRADORES */}
              <ListItem button onClick={ e => this.setState({ administradores: !this.state.administradores }) }>
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText primary="Administradores" />
                {this.state.administradores ? <ExpandLess style={{color: 'white'}} /> : <ExpandMore style={{color: 'white'}} />}
              </ListItem>
              <Collapse in={this.state.administradores} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem selected={this.state.selecionado === 'admins'} button onClick={ e => this.props.history.push('/admins') }>
                    <ListItemText inset primary="Administradores" />
                  </ListItem>
                  <ListItem selected={this.state.selecionado === 'funcoes'} button onClick={ e => this.props.history.push('/funcoes') }>
                    <ListItemText inset primary="Funções" />
                  </ListItem>
                </List>
              </Collapse>
              {/* GERAL */}
              <ListItem selected={this.state.selecionado === 'usuarios'} button onClick={ e => this.props.history.push('/usuarios') }>
                <ListItemIcon><Icon>person</Icon></ListItemIcon>
                <ListItemText primary="Usuários" />
              </ListItem>
              {/* SAIR */}
              <ListItem button onClick={this.mostrarModalSair}>
                <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </List>
          </Drawer>
        </MuiThemeProvider>
        <ModalConfirmacao
            titulo="Sair?"
            ref="confirmacaoSair"
            aoConfirmar={this.sair}>
            Você realmente deseja sair?
        </ModalConfirmacao>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
      sair: () => dispatch(push('/login'))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar)));