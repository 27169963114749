import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ErroForm from '../../ErroForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/admins';

class TabelaAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, admin) => {
        return e.target.checked
            ? this.props.adicionarSelecao(admin)
            : this.props.removerSelecao(admin);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (admin) => {
        this.refs.confirmacaoRemover.mostrar(admin);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.admins.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Função</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.admins.map((admin, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === admin.id)}
                                                onChange={(e) => this.alterarSelecao(e, admin)}
                                                value={`${admin.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>{admin.nome}</TableCell>
                                        <TableCell>{admin.email}</TableCell>
                                        <TableCell>{admin.funcao.nome}</TableCell>
                                        <TableCell padding="none">
                                            <IconButton size="small" onClick={e => this.props.iniciarEditar(admin)} color="default" aria-label="Editar">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={admin.removendo}
                                                onClick={e => this.mostrarConfirmacaoRemover(admin)}
                                                color="secondary"
                                                aria-label="Remover"
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                {(admin.removendo)
                                                    ? <CircularProgress color="secondary" size={24} style={{position: 'absolute', right: '.2em', top: '.2em'}} />
                                                    : ''}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.admins.length)
                                ? <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum administrador cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={4} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        admins: state.admins.admins,
        selecionados: state.admins.selecionados,
        paginas: state.admins.paginas,
        pagina: state.admins.pagina,
        qtde: state.admins.qtde,
        carregando: state.admins.carregando,
        erro: state.admins.erro
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (admin) => dispatch(adicionarSelecao(admin)),
        removerSelecao: (admin) => dispatch(removerSelecao(admin)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (admin) => dispatch(remover(admin)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (admin) => dispatch(definirItem(admin)),
        iniciarEditar: (admin) => dispatch(iniciarEditar(admin))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaAdmins);