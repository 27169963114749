import axios from 'axios';
class ServicoImagemAdmin {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/imagens-admins` : 'https://api.pontuafidelidade.com.br/api/admin/imagens-admins';

  constructor() {
    this.buscarToken();
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  enviarImagem(data) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

}

export default ServicoImagemAdmin;