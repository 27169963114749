import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CampoFormErro from '../componentes/geral/CampoFormErro';
import DrawerConfiguracoes from '../componentes/configuracoesSite/DrawerAdicionar';
import TabelaConfiguracoes from '../componentes/configuracoesSite/TabelaConfiguracoes';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  buscarTodos,
  pesquisar
} from '../store/ducks/configuracoesSite';

class PaginaTextosSite extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
   
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <CampoFormErro titulo="Erro" mensagem={this.props.erro} /> : ''}
          {/* MENU */}
          <DrawerConfiguracoes />
          {/* TABELA */}
          <TabelaConfiguracoes />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.textosSite.carregando,
    erro: state.textosSite.erro,
    layout: state.textosSite.layout,
    textos: state.textosSite.itens,
    selecionados: state.textosSite.selecionados,
    pagina: state.textosSite.pagina,
    qtde: state.textosSite.qtde,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    pesquisar: (pesquisa) => dispatch(pesquisar(pesquisa))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaTextosSite));