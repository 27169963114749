import React from 'react';

import '../css/MenuPaginacao.css';
import { Icon } from '@material-ui/core';

class MenuPaginacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      atual: props.atual || 1,
      paginas: props.paginas || 1
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      atual: props.atual || 1,
      paginas: props.paginas || 1
    });
  }

  irPara = (e, pagina) => {
    e.preventDefault();
    if (typeof this.props.aoIrPara === 'function') this.props.aoIrPara(pagina);
  }

  avancar = (e) => {
    e.preventDefault();
    if (this.state.atual < this.state.paginas) {
      if (typeof this.props.aoIrPara === 'function') this.props.aoIrPara(this.state.atual+1);
    }
  }

  voltar = (e) => {
    e.preventDefault();
    if (this.state.atual > 1) {
      if (typeof this.props.aoIrPara === 'function') this.props.aoIrPara(this.state.atual-1);
    }
  }

  renderVoltar = () => {
    return <a onClick={this.voltar} className="menupaginacao-anterior" href="/"><Icon>chevron_left</Icon></a>
  }
  
  renderAnterior = () => {
    if (this.state.atual > 1) {
      return <a className="menupaginacao-item" href="/" onClick={(e) => {this.irPara(e, this.state.atual-1)}}>{this.state.atual-1}</a>
    }
  }

  renderProxima = () => {
    if (this.state.atual <= this.state.paginas - 1) {
      return <a className="menupaginacao-item" href="/" onClick={(e) => {this.irPara(e, this.state.atual+1)}}>{this.state.atual+1}</a>
    }
  }

  renderAvancar = () => {
    return <a disabled onClick={this.avancar} className="menupaginacao-proximo" href="/"><Icon>chevron_right</Icon></a>
  }

  render() {
    return (
      <div className="menupaginacao-wrapper">
        {this.renderVoltar()}
        {this.renderAnterior()}
        <a className="menupaginacao-item active" href="/" onClick={e => e.preventDefault()}>{this.state.atual}</a>
        {this.renderProxima()}
        {this.renderAvancar()}
      </div>
    );
  }
}

export default MenuPaginacao;