const novoItem = {
    id: '',
    razao_social: '',
    nome_fantasia: '',
    tipo: 'pessoa_juridica',
    cpf: '',
    cnpj: '',
    email: '',
    telefone: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    site: '',
    estado: 'RS',
    cidade: '',
    bairro: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    latitude: 0,
    longitude: 0,
    compartilhamento_automatico: true,
    dias_expiracao_pontos: 365,
    regras: '',
    status: 'inativo',
    admins: [],
    imagens: [],
    produtos: [],
    promocoes: [],
    vouchers: []
}

const stateInicial = {
    carregando: false,
    desabilitado: false,
    erro: '',
    layout: 'tabela',
    pagina: 1,
    paginas: 1,
    qtde: 25,
    tab: 0,
    ordem: 'razao_social',
    estabelecimentos: [],
    selecionados: [],
    item: {...novoItem},
    mostrarForm: false,
    step: 0
}

export const Types = {
    // ALTERACOES
    ALTERAR_LAYOUT: 'estabelecimentos/ALTERAR_LAYOUT',
    ALTERAR_PAGINA: 'estabelecimentos/ALTERAR_PAGINA',
    ALTERAR_QTDE: 'estabelecimentos/ALTERAR_QTDE',
    ALTERAR_ORDEM: 'estabelecimentos/ALTERAR_ORDEM',
    ALTERAR_TAB: 'estabelecimentos/ALTERAR_TAB',
    ALTERAR_ITEM: 'estabelecimentos/ALTERAR_ITEM',
    ALTERAR_STEP: 'estabelecimentos/ALTERAR_STEP',
    ADICIONAR_SELECAO: 'estabelecimentos/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'estabelecimentos/REMOVER_SELECAO',
    SELECIONAR_TODOS: 'estabelecimentos/SELECIONAR_TODOS',
    DESSELECIONAR_TODOS: 'estabelecimentos/DESSELECIONAR_TODOS',
    DEFINIR_ITEM: 'estabelecimentos/DEFINIR_ITEM',
    MOSTRAR_FORM: 'estabelecimentos/MOSTRAR_FORM',
    ESCONDER_FORM: 'estabelecimentos/ESCONDER_FORM',
    INICIAR_EDITAR: 'estabelecimentos/INICIAR_EDITAR',
    // SALVAR
    SALVAR_REQUEST: 'estabelecimentos/SALVAR_REQUEST',
    SALVAR_SUCESSO: 'estabelecimentos/SALVAR_SUCESSO',
    SALVAR_ERRO: 'estabelecimentos/SALVAR_ERRO',
    // PESQUISAR
    PESQUISAR_REQUEST: 'estabelecimentos/PESQUISAR_REQUEST',
    PESQUISAR_SUCESSO: 'estabelecimentos/PESQUISAR_SUCESSO',
    PESQUISAR_ERRO: 'estabelecimentos/PESQUISAR_ERRO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'estabelecimentos/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'estabelecimentos/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'estabelecimentos/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'estabelecimentos/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'estabelecimentos/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'estabelecimentos/BUSCAR_ITEM_ERRO',
    // REMOVER
    REMOVER_REQUEST: 'estabelecimentos/REMOVER_REQUEST',
    REMOVER_SUCESSO: 'estabelecimentos/REMOVER_SUCESSO',
    REMOVER_ERRO: 'estabelecimentos/REMOVER_ERRO',
    // ENVIAR FOTO
    ENVIAR_FOTO_REQUEST: 'estabelecimentos/ENVIAR_FOTO_REQUEST',
    ENVIAR_FOTO_SUCESSO: 'estabelecimentos/ENVIAR_FOTO_SUCESSO',
    ENVIAR_FOTO_ERRO: 'estabelecimentos/ENVIAR_FOTO_ERRO',
    // REMOVER FOTO
    REMOVER_FOTO_REQUEST: 'estabelecimentos/REMOVER_FOTO_REQUEST',
    REMOVER_FOTO_SUCESSO: 'estabelecimentos/REMOVER_FOTO_SUCESSO',
    REMOVER_FOTO_ERRO: 'estabelecimentos/REMOVER_FOTO_ERRO',
    // DEFINIR CAPA
    DEFINIR_CAPA_REQUEST: 'estabelecimentos/DEFINIR_CAPA_REQUEST',
    DEFINIR_CAPA_SUCESSO: 'estabelecimentos/DEFINIR_CAPA_SUCESSO',
    DEFINIR_CAPA_ERRO: 'estabelecimentos/DEFINIR_CAPA_ERRO',
    // DEFINIR PERFIL
    DEFINIR_PERFIL_REQUEST: 'estabelecimentos/DEFINIR_PERFIL_REQUEST',
    DEFINIR_PERFIL_SUCESSO: 'estabelecimentos/DEFINIR_PERFIL_SUCESSO',
    DEFINIR_PERFIL_ERRO: 'estabelecimentos/DEFINIR_PERFIL_ERRO',
    // REMOVER CAPA
    REMOVER_CAPA_REQUEST: 'estabelecimentos/REMOVER_CAPA_REQUEST',
    REMOVER_CAPA_SUCESSO: 'estabelecimentos/REMOVER_CAPA_SUCESSO',
    REMOVER_CAPA_ERRO: 'estabelecimentos/REMOVER_CAPA_ERRO',
    // REMOVER PERFIL
    REMOVER_PERFIL_REQUEST: 'estabelecimentos/REMOVER_PERFIL_REQUEST',
    REMOVER_PERFIL_SUCESSO: 'estabelecimentos/REMOVER_PERFIL_SUCESSO',
    REMOVER_PERFIL_ERRO: 'estabelecimentos/REMOVER_PERFIL_ERRO'
}

export default function reducer (state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_PAGINA: {
            if (!isNaN(action.pagina)) {
                return {...state, pagina: action.pagina}
            }
            return {...state};
        }
        case Types.ALTERAR_QTDE: {
            if (!isNaN(action.qtde)) {
                return {...state, qtde: action.qtde, pagina: 1}
            }
            return {...state};
        }
        case Types.ALTERAR_ORDEM: {
            return {...state, ordem: action.ordem, pagina: 1}
        }
        case Types.ALTERAR_LAYOUT: {
            if (['tabela', 'lista'].indexOf(action.layout) > -1) {
                return {...state, layout: action.layout};
            }
            return {...state};
        }
        case Types.ALTERAR_TAB: {
            if(!isNaN(action.tab)) {
                return {...state, tab: action.tab};
            }
            return {...state};
        }
        case Types.ALTERAR_STEP: {
            if(!isNaN(action.step)) {
                return {...state, step: action.step};
            }
            return {...state};
        }
        case Types.ALTERAR_ITEM: {
            const item = {...state.item}
            item[action.campo] = action.valor;
            return {...state, item, erro: ''}
        }
        case Types.DEFINIR_ITEM: {
            return {
                ...state,
                item: action.item
            }
        }
        case Types.ESCONDER_FORM: {
            return {...state, mostrarForm: false}
        }
        case Types.MOSTRAR_FORM: {
            return {
                ...state,
                erro: '',
                mostrarForm: true,
                step: 0,
                item: {...novoItem}
            }
        }
        case Types.INICIAR_EDITAR: {
            const item = {...action.estabelecimento};
            return {
              ...state,
              item,
              erro: '',
              mostrarForm: true,
              step: 0
            }
          }
        case Types.SALVAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.SALVAR_SUCESSO: {
            let novoState = {
                ...state,
                carregando: false,
                mostrarForm: false,
                item: action.estabelecimento
            }
            if (action.editar) {
                const estabelecimentos = [...state.estabelecimentos];
                estabelecimentos.map((item, i) => {
                  if (item.id === action.estabelecimento.id) return estabelecimentos[i] = action.estabelecimento;
                  return null;
                });
                novoState = {...novoState, estabelecimentos };
            } else {
                novoState = {...novoState, estabelecimentos: [...state.estabelecimentos, action.estabelecimento]};
            }
            return novoState;
        }
        case Types.SALVAR_ERRO: {
            console.log(action.erro);
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.PESQUISAR_REQUEST: {
            return {
                ...state,
                pagina: 1,
                qtde: 25,
                erro: ''
            }
        }
        case Types.PESQUISAR_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                estabelecimentos: action.estabelecimentos.data,
                paginas: action.estabelecimentos.meta.last_page
            }
            return novoState;
        }
        case Types.PESQUISAR_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.estabelecimento
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                estabelecimentos: (state.layout === 'lista')
                    ? [...state.estabelecimentos].concat(action.estabelecimentos.data)
                    : action.estabelecimentos.data,
                paginas: action.estabelecimentos.meta.last_page
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.estabelecimento);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(estabelecimento => estabelecimento.id !== action.estabelecimento.id);
            return {...state, selecionados};
        }
        case Types.SELECIONAR_TODOS: {
            const selecionados = [...state.estabelecimentos];
            return {...state, selecionados};
        }
        case Types.DESSELECIONAR_TODOS: {
            const selecionados = [];
            return {...state, selecionados};
        }
        case Types.REMOVER_REQUEST: {
            const estabelecimentos = [...state.estabelecimentos];
            estabelecimentos.map((estabelecimento, i) => {
                if (estabelecimento.id === action.estabelecimento.id) {
                    return estabelecimentos[i].removendo = true;
                }
                return null;
            });
            return {...state, estabelecimentos};
        }
        case Types.REMOVER_SUCESSO: {
            let estabelecimentos = [...state.estabelecimentos];
            let selecionados = [...state.selecionados];
            estabelecimentos = estabelecimentos.filter((estabelecimento) => estabelecimento.id !== action.estabelecimento.id)
            selecionados = selecionados.filter((estabelecimento) => estabelecimento.id !== action.estabelecimento.id)
            return {...state, estabelecimentos, selecionados};
        }
        case Types.REMOVER_ERRO: {
            let estabelecimentos = [...state.estabelecimentos];
            estabelecimentos.map((estabelecimento, i) => {
                return estabelecimentos[i].removendo = false;
            });
            return {...state, erro: action.erro, estabelecimentos};
        }
        case Types.ENVIAR_FOTO_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.ENVIAR_FOTO_SUCESSO: {
            let imagens = [...state.item.imagens];
            imagens.splice(0, 0, action.foto);
            let item = {...state.item, imagens};
            return {
                ...state,
                item,
                carregando: false
            }
        }
        case Types.ENVIAR_FOTO_ERRO: {
            return {
                ...state,
                carregando: false,
                erro: action.erro
            }
        }
        case Types.DEFINIR_CAPA_REQUEST: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => {
                if (foto.id === action.foto.id) return imagens[i].carregando = true;
                return null;
            });
            let item = {...state.item, imagens}
            return { ...state, erro: '', item }
        }
        case Types.DEFINIR_CAPA_SUCESSO: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => imagens[i].carregando = false);
            let item = {...state.item, imagens, capa: action.foto}
            return { ...state, item, carregando: false }
        }
        case Types.DEFINIR_CAPA_ERRO: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => imagens[i].carregando = false);
            let item = {...state.item, imagens}
            return { ...state, erro: action.erro, item }
        }
        case Types.DEFINIR_PERFIL_REQUEST: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => {
                if (foto.id === action.foto.id) return imagens[i].carregando = true;
                return null;
            });
            let item = {...state.item, imagens}
            return { ...state, erro: '', item }
        }
        case Types.DEFINIR_PERFIL_SUCESSO: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => imagens[i].carregando = false);
            let item = {...state.item, imagens, perfil: action.foto}
            return { ...state, item, carregando: false }
        }
        case Types.DEFINIR_PERFIL_ERRO: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => imagens[i].carregando = false);
            let item = {...state.item, imagens}
            return { ...state, erro: action.erro, item }
        }
        case Types.REMOVER_FOTO_REQUEST: {
            let imagens = [...state.item.imagens];
            imagens.map((foto, i) => {
                if (foto.id === action.foto.id) return imagens[i].carregando = true;
                return null;
            });
            let item = {...state.item, imagens}
            return { ...state, erro: '', item }
        }
        case Types.REMOVER_FOTO_SUCESSO: {
            let imagens = [...state.item.imagens];
            imagens = imagens.filter(foto => foto.id !== action.foto.id);
            let item = {...state.item, imagens}
            return { ...state, item, carregando: false }
        }
        case Types.REMOVER_FOTO_ERRO: {
            return { ...state, carregando: false, erro: action.erro }
        }
        case Types.REMOVER_CAPA_REQUEST: {
            let capa = {...state.item.capa};
            capa.carregando = true;
            let item = {...state.item, capa}
            return { ...state, erro: '', item }
        }
        case Types.REMOVER_CAPA_SUCESSO: {
            let item = {...state.item, capa: null}
            return { ...state, item }
        }
        case Types.REMOVER_CAPA_ERRO: {
            let capa = {...state.item.capa};
            capa.carregando = false;
            let item = {...state.item, capa}
            return { ...state, erro: action.erro, item }
        }
        case Types.REMOVER_PERFIL_REQUEST: {
            let perfil = {...state.item.perfil};
            perfil.carregando = true;
            let item = {...state.item, perfil}
            return { ...state, erro: '', item }
        }
        case Types.REMOVER_PERFIL_SUCESSO: {
            let item = {...state.item, perfil: null}
            return { ...state, item }
        }
        case Types.REMOVER_PERFIL_ERRO: {
            let perfil = {...state.item.perfil};
            perfil.carregando = false;
            let item = {...state.item, perfil}
            return { ...state, erro: action.erro, item }
        }
        default: return state;
    }
}

export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
    return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
    return { type: Types.ALTERAR_PAGINA, pagina }
}

export function alterarTab(tab) {
    return { type: Types.ALTERAR_TAB, tab }
}

export function alterarStep(step) {
    return { type: Types.ALTERAR_STEP, step }
}

export function alterarItem(campo, valor) {
    return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function pesquisar(pesquisa) {
    return { type: Types.PESQUISAR_REQUEST, pesquisa }
}

export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25, ordem = null) {
    return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde, ordem }
}

export function adicionarSelecao(estabelecimento) {
    return { type: Types.ADICIONAR_SELECAO, estabelecimento }
}

export function removerSelecao(estabelecimento) {
    return { type: Types.REMOVER_SELECAO, estabelecimento }
}

export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(estabelecimento) {
    return { type: Types.REMOVER_REQUEST, estabelecimento }
}

export function definirNovoItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(estabelecimento) {
    return { type: Types.SALVAR_REQUEST, estabelecimento }
}

export function definirItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function enviarFoto(id, foto) {
    return { type: Types.ENVIAR_FOTO_REQUEST, id, foto }
}

export function removerFoto(foto) {
    return { type: Types.REMOVER_FOTO_REQUEST, foto }
}

export function removerCapa(foto) {
    return { type: Types.REMOVER_CAPA_REQUEST, foto }
}

export function removerPerfil(foto) {
    return { type: Types.REMOVER_PERFIL_REQUEST, foto }
}

export function definirCapa(foto) {
    return { type: Types.DEFINIR_CAPA_REQUEST, foto }
}

export function definirPerfil(foto) {
    return { type: Types.DEFINIR_PERFIL_REQUEST, foto }
}

export function mostrarForm() {
    return { type: Types.MOSTRAR_FORM };
}
  
export function esconderForm() {
    return { type: Types.ESCONDER_FORM };
}

export function iniciarEditar(estabelecimento) {
    return { type: Types.INICIAR_EDITAR, estabelecimento }
}

export function alterarOrdem(ordem) {
    return { type: Types.ALTERAR_ORDEM, ordem }
}