import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import * as Sentry from '@sentry/browser';
Sentry.init({dsn: "https://ee3ba3ef7b1c41fc99e2fe8154e0d4ca@sentry.io/1724364"});

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App history={history} />
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'));
