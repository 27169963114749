const novoItem = {

}

const stateInicial = {
  carregando: false,
  erro: '',
  item: {...novoItem},
  funcoes: []
}

export const Types = {
  ALTERAR_ITEM: 'funcoesEstabelecimentos/ALTERAR_ITEM',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'funcoesEstabelecimentos/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'funcoesEstabelecimentos/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'funcoesEstabelecimentos/BUSCAR_TODOS_ERRO',
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_ITEM: {
      const item = {...state.item}
      item[action.campo] = action.valor;
      return {...state, item}
    }
    case Types.BUSCAR_TODOS_REQUEST: {
      return {
          ...state,
          carregando: true,
          erro: ''
      }
  }
  case Types.BUSCAR_TODOS_SUCESSO: {
      const novoState = {
          ...state,
          carregando: false,
          funcoes: (state.layout === 'lista')
              ? [...state.funcoes].concat(action.funcoes.data)
              : action.funcoes.data,
          paginas: action.funcoes.meta.last_page
      }
      return novoState;
  }
  case Types.BUSCAR_TODOS_ERRO: {
      const novoState = {
          ...state,
          carregando: false,
          erro: action.erro
      }
      return novoState;
  }
    default: return {...state}
  }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function buscarTodos(pagina = 1, qtde = 25) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}