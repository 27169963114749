import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormRadio from '../../geral/CampoFormRadio';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, CircularProgress, Button
} from '@material-ui/core';

import {
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles'

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar,
} from '../../../store/ducks/planos';

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        maxWidth: '500px',
        width: '90%'
      }
    }
  }
});

class DrawerAdicionar extends Component {
  salvar = (e) => {
    e.preventDefault();
    const admin = { ...this.props.item };
    this.props.salvar(admin);
  }
  render() {
    return (
      <div role="presentation">
        <MuiThemeProvider theme={theme}>
          <Drawer anchor="right" open={this.props.mostrar} onClose={e => this.props.esconderForm()}>
            <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1em'}}>
              <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar plano</Typography>
              {/* NOME */}
              <CampoFormTexto
                required
                label="Nome"
                value={this.props.item.nome}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
                onChange={e => this.props.alterarItem('nome', e.target.value)} />
              {/* PERIODO */}
              <CampoFormRadio
                label="Período"
                value={this.props.item.periodo}
                onChange={e => this.props.alterarItem('periodo', e.target.value)}
                opcoes={[
                  {value: 'MONTHLY', label: 'Mensal'},
                  {value: 'YEARLY', label: 'Anual'}
                ]}/>
              {/* VALOR */}
              <CampoFormTexto
                required
                type="number"
                label="Valor (R$)"
                value={this.props.item.valor}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.valor) ? this.props.erro.errors.valor[0] : ''}
                onChange={e => this.props.alterarItem('valor', e.target.value)} />
              {/* TAXA DE INSCRIÇÃO */}
              <CampoFormTexto
                type="number"
                label="Taxa de inscrição (R$)"
                value={this.props.item.taxa_inscricao}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.taxa_inscricao) ? this.props.erro.errors.taxa_inscricao[0] : ''}
                onChange={e => this.props.alterarItem('taxa_inscricao', e.target.value)} />
              {/* DIAS TESTE */}
              <CampoFormTexto
                type="number"
                label="Período de teste (dias free)"
                value={this.props.item.dias_teste}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.dias_teste) ? this.props.erro.errors.dias_teste[0] : ''}
                onChange={e => this.props.alterarItem('dias_teste', e.target.value)} />
              {/* DESCRIÇÃO */}
              <CampoFormTexto
                multiline
                label="Descrição"
                value={this.props.item.descricao}
                erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.descricao) ? this.props.erro.errors.descricao[0] : ''}
                onChange={e => this.props.alterarItem('descricao', e.target.value)} />
              {/* ERRO */}
              {(this.props.erro && this.props.erro.message)
                  ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
                  : ''}
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  disabled={this.props.carregando}
                  type="submit"
                  style={{marginTop: '1em', position: 'relative'}}
                  variant="contained"
                  color="primary">
                  Salvar
                  {(this.props.carregando)
                    ? <CircularProgress
                        size={24}
                        color="secondary"
                        style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                    : ''}
                </Button>
              </div>
            </form>
          </Drawer>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.planos.carregando,
    deveAlterarSenha: state.planos.deveAlterarSenha,
    item: state.planos.item,
    mostrar: state.planos.mostrarForm,
    erro: state.planos.erro,
    carregandoFuncoes: state.funcoes.carregando,
    funcoes: state.funcoes.funcoes,
    erroFuncoes: state.funcoes.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (plano) => dispatch(salvar(plano)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAdicionar));