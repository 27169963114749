const novoItem = {
  id: '',
  nome: '',
  slug: '',
  superadmin: false,
  permissoes_ids: [],
  permissoes: []
}

const stateInicial = {
  carregando: false,
  desabilitado: false,
  erro: '',
  layout: 'tabela',
  pagina: 1,
  paginas: 1,
  qtde: 25,
  funcoes: [],
  selecionados: [],
  mostrarForm: false,
  item: {...novoItem},
}

export const Types = {
  // ALTERACOES
  ALTERAR_LAYOUT: 'funcoes/ALTERAR_LAYOUT',
  ALTERAR_PAGINA: 'funcoes/ALTERAR_PAGINA',
  ALTERAR_QTDE: 'funcoes/ALTERAR_QTDE',
  ALTERAR_ITEM: 'funcoes/ALTERAR_ITEM',
  MOSTRAR_FORM: 'funcoes/MOSTRAR_FORM',
  ESCONDER_FORM: 'funcoes/ESCONDER_FORM',
  ADICIONAR_SELECAO: 'funcoes/ADICIONAR_SELECAO',
  REMOVER_SELECAO: 'funcoes/REMOVER_SELECAO',
  SELECIONAR_TODOS: 'funcoes/SELECIONAR_TODOS',
  DESSELECIONAR_TODOS: 'funcoes/DESSELECIONAR_TODOS',
  DEFINIR_ITEM: 'funcoes/DEFINIR_ITEM',
  INICIAR_EDITAR: 'funcoes/INICIAR_EDITAR',
  // SALVAR
  SALVAR_REQUEST: 'funcoes/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'funcoes/SALVAR_SUCESSO',
  SALVAR_ERRO: 'funcoes/SALVAR_ERRO',
  // PESQUISAR
  PESQUISAR_REQUEST: 'funcoes/PESQUISAR_REQUEST',
  PESQUISAR_SUCESSO: 'funcoes/PESQUISAR_SUCESSO',
  PESQUISAR_ERRO: 'funcoes/PESQUISAR_ERRO',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'funcoes/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'funcoes/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'funcoes/BUSCAR_TODOS_ERRO',
  // ITEM
  BUSCAR_ITEM_REQUEST: 'funcoes/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'funcoes/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'funcoes/BUSCAR_ITEM_ERRO',
  // REMOVER
  REMOVER_REQUEST: 'funcoes/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'funcoes/REMOVER_SUCESSO',
  REMOVER_ERRO: 'funcoes/REMOVER_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
      case Types.ALTERAR_PAGINA: {
          if (!isNaN(action.pagina)) {
              return {...state, pagina: action.pagina}
          }
          return {...state};
      }
      case Types.ALTERAR_QTDE: {
          if (!isNaN(action.qtde)) {
              return {...state, qtde: action.qtde, pagina: 1}
          }
          return {...state};
      }
      case Types.ALTERAR_LAYOUT: {
          if (['tabela', 'lista'].indexOf(action.layout) > -1) {
              return {...state, layout: action.layout};
          }
          return {...state};
      }
      case Types.ESCONDER_FORM: {
        const novoState = {...state, mostrarForm: false}
        return novoState;
      }
      case Types.MOSTRAR_FORM: {
        return {...state, mostrarForm: true, item: {...novoItem}, erro: ''}
      }
      case Types.ALTERAR_ITEM: {
          const item = {...state.item}
          item[action.campo] = action.valor;
          return {...state, item, erro: ''}
      }
      case Types.DEFINIR_ITEM: {
          return {
              ...state,
              item: action.item
          }
      }
      case Types.INICIAR_EDITAR: {
        const item = {...action.funcao};
        item.permissoes_ids = [];
        item.permissoes.map((permissao, i) => item.permissoes_ids.push(permissao.id));
        return {
          ...state,
          item,
          erro: '',
          mostrarForm: true
        }
      }
      case Types.SALVAR_REQUEST: {
          return {
              ...state,
              carregando: true,
              desabilitado: true,
              erro: ''
          }
      }
      case Types.SALVAR_SUCESSO: {
        let novoState = {
          ...state,
          carregando: false,
          mostrarForm: false
        }
        if (action.editar) {
          const funcoes = [...state.funcoes];
          funcoes.map((item, i) => {
            if (item.id === action.funcao.id) return funcoes[i] = action.funcao;
            return null;
          });
          novoState = {...novoState, funcoes };
        } else {
          novoState = {...novoState, funcoes: [...state.funcoes, action.funcao]};
        }
        return novoState;
      }
      case Types.SALVAR_ERRO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              erro: action.erro
          }
      }
      case Types.PESQUISAR_REQUEST: {
          return {
              ...state,
              pagina: 1,
              qtde: 25,
              erro: ''
          }
      }
      case Types.PESQUISAR_SUCESSO: {
          const novoState = {
              ...state,
              carregando: false,
              funcoes: action.funcoes.data,
              paginas: action.funcoes.meta.last_page
          }
          return novoState;
      }
      case Types.PESQUISAR_ERRO: {
          const novoState = {
              ...state,
              carregando: false,
              erro: action.erro
          }
          return novoState;
      }
      case Types.BUSCAR_ITEM_REQUEST: {
          return {
              ...state,
              carregando: true,
              desabilitado: true,
              erro: ''
          }
      }
      case Types.BUSCAR_ITEM_SUCESSO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              item: action.funcao
          }
      }
      case Types.BUSCAR_ITEM_ERRO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              erro: action.erro
          }
      }
      case Types.BUSCAR_TODOS_REQUEST: {
          return {
              ...state,
              carregando: true,
              erro: ''
          }
      }
      case Types.BUSCAR_TODOS_SUCESSO: {
          const novoState = {
              ...state,
              carregando: false,
              funcoes: (state.layout === 'lista')
                  ? [...state.funcoes].concat(action.funcoes.data)
                  : action.funcoes.data,
              paginas: action.funcoes.meta.last_page
          }
          return novoState;
      }
      case Types.BUSCAR_TODOS_ERRO: {
          const novoState = {
              ...state,
              carregando: false,
              erro: action.erro
          }
          return novoState;
      }
      case Types.ADICIONAR_SELECAO: {
          const selecionados = [...state.selecionados];
          selecionados.push(action.funcao);
          return {...state, selecionados};
      }
      case Types.REMOVER_SELECAO: {
          let selecionados = [...state.selecionados];
          selecionados = selecionados.filter(funcao => funcao.id !== action.funcao.id);
          return {...state, selecionados};
      }
      case Types.SELECIONAR_TODOS: {
          const selecionados = [...state.funcoes];
          return {...state, selecionados};
      }
      case Types.DESSELECIONAR_TODOS: {
          const selecionados = [];
          return {...state, selecionados};
      }
      case Types.REMOVER_REQUEST: {
          const funcoes = [...state.funcoes];
          funcoes.map((funcao, i) => {
              if (funcao.id === action.funcao.id) {
                  return funcoes[i].removendo = true;
              }
              return null;
          });
          return {...state, funcoes};
      }
      case Types.REMOVER_SUCESSO: {
          let funcoes = [...state.funcoes];
          let selecionados = [...state.selecionados];
          funcoes = funcoes.filter((funcao) => funcao.id !== action.funcao.id)
          selecionados = selecionados.filter((funcao) => funcao.id !== action.funcao.id)
          return {...state, funcoes, selecionados};
      }
      case Types.REMOVER_ERRO: {
          let funcoes = [...state.funcoes];
          funcoes.map((funcao, i) => {
              return funcoes[i].removendo = false;
          });
          return {...state, erro: action.erro, funcoes};
      }
      default: return state;
  }
}

export function alterarLayout(layout) {
  return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
  return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
  return { type: Types.ALTERAR_PAGINA, pagina }
}


export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function pesquisar(pesquisa) {
  return { type: Types.PESQUISAR_REQUEST, pesquisa }
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function adicionarSelecao(funcao) {
  return { type: Types.ADICIONAR_SELECAO, funcao }
}

export function removerSelecao(funcao) {
  return { type: Types.REMOVER_SELECAO, funcao }
}

export function selecionarTodos() {
  return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
  return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(funcao) {
  return { type: Types.REMOVER_REQUEST, funcao }
}

export function definirNovoItem(item) {
  return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(funcao) {
  return { type: Types.SALVAR_REQUEST, funcao }
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item }
}

export function mostrarForm() {
    return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
    return { type: Types.ESCONDER_FORM };
}

export function iniciarEditar(funcao) {
    return { type: Types.INICIAR_EDITAR, funcao }
  }