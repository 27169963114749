import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";

class CampoFormDatepicker extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        <KeyboardDatePicker
          format="DD/MM/YYYY"
          fullWidth
          label={this.props.label}
          value={this.props.value}
          onChange={this.props.onChange}
          error={(this.props.erro) ? true : false}
          helperText={(this.props.erro) ? this.props.erro
                      : (this.props.ajuda) ? this.props.ajuda : ''}
          margin="normal" />
          
      </FormControl>
    )
  }
}

export default CampoFormDatepicker;