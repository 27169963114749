const novoItem = {
  id: '',
  nome: '',
  email: '',
  senha: '',
  funcoes_estabelecimentos_id: '',
  estabelecimentos_id: ''
}

const stateInicial = {
  item: {...novoItem},
  itens: [],
  mostrarForm: false,
  carregando: false,
  erro: ''
}

export const Types = {
  ALTERAR_ITEM: 'adminsEstabelecimentos/ALTERAR_ITEM',
  DEFINIR_ITEM: 'adminsEstabelecimentos/DEFINIR_ITEM',
  MOSTRAR_FORM: 'adminsEstabelecimentos/MOSTRAR_FORM',
  ESCONDER_FORM: 'adminsEstabelecimentos/ESCONDER_FORM',
  INICIAR_EDITAR: 'adminsEstabelecimentos/INICIAR_EDITAR',
  DEFINIR_ITENS: 'adminsEstabelecimentos/DEFINIR_ITENS',
  SALVAR_REQUEST: 'adminsEstabelecimentos/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'adminsEstabelecimentos/SALVAR_SUCESSO',
  SALVAR_ERRO: 'adminsEstabelecimentos/SALVAR_ERRO',
  REMOVER_REQUEST: 'adminsEstabelecimentos/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'adminsEstabelecimentos/REMOVER_SUCESSO',
  REMOVER_ERRO: 'adminsEstabelecimentos/REMOVER_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ESCONDER_FORM: {
      return {...state, mostrarForm: false}
    }
    case Types.MOSTRAR_FORM: {
      return {...state, mostrarForm: true, erro: '', item: {...novoItem}}
    }
    case Types.ALTERAR_ITEM: {
      const item = {...state.item}
      item[action.campo] = action.valor;
      return {...state, item, erro: ''};
    }
    case Types.DEFINIR_ITEM: {
      return {...state, item: action.item}
    }
    case Types.DEFINIR_ITENS: {
      return {...state, itens: action.itens}
    }
    case Types.SALVAR_REQUEST: {
      return {
        ...state,
        carregando: true,
        erro: ''
      }
    }
    case Types.SALVAR_SUCESSO: {
      let novoState = {
        ...state,
        carregando: false,
        mostrarForm: false,
        item: {...novoItem}
      }
      if (action.editar) {
        const itens = [...state.itens];
        itens.map((item, i) => {
          if (item.id === action.admin.id) return itens[i] = action.admin;
          return null;
        });
        novoState = {...novoState, itens };
      } else {
        novoState = {...novoState, itens: [...state.itens, action.admin]};
      }
      return novoState;
    }
    case Types.SALVAR_ERRO: {
      return {
        ...state, carregando: false, erro: action.erro
      }
    }
    case Types.INICIAR_EDITAR: {
      return {
        ...state,
        erro: '',
        item: action.admin,
        mostrarForm: true
      }
    }
    case Types.REMOVER_REQUEST: {
      let itens = [...state.itens];
      itens.map((admin, i) => {
          if (admin.id === action.admin.id) return itens[i].carregando = true;
          return null;
      });
      return { ...state, erro: '', itens }
    }
    case Types.REMOVER_SUCESSO: {
      let itens = [...state.itens];
      itens = itens.filter(admin => admin.id !== action.admin.id);
      return { ...state, itens }
    }
    case Types.REMOVER_ERRO: {
      return { ...state, erro: action.erro }
    }
    default: return {...state};
  }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor };
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item };
}

export function definirItens(itens) {
  return { type: Types.DEFINIR_ITENS, itens }
}

export function mostrarForm() {
  return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
  return { type: Types.ESCONDER_FORM };
}

export function salvar(admin) {
  return { type: Types.SALVAR_REQUEST, admin }
}

export function iniciarEditar(admin) {
  return { type: Types.INICIAR_EDITAR, admin }
}

export function remover(admin) {
  return { type: Types.REMOVER_REQUEST, admin }
}