import React from 'react';
import { connect } from 'react-redux';

import { Avatar, Button, CircularProgress, Divider, Grid, Paper,
  Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

import ErroPagina from '../componentes/ErroPagina';
import ServicoLogin from '../servicos/ServicoLogin';
import ServicoImagemAdmin from '../servicos/ServicoImagemAdmin';


class PaginaPerfil extends React.Component {
  constructor (props) {
    super(props);
    this.servicoImagemAdmin = new ServicoImagemAdmin();
    this.servicoLogin = new ServicoLogin();
    this.refInputFoto = React.createRef();
    this.state = { 
      carregando: false,
      mensagemErro: '',
      mostrarErro: false,
      usuario: this.servicoLogin.usuario
    };
  }

  componentDidMount() {
    this.buscarConfiguracoes();
  }

  buscarConfiguracoes = () => {
    this.setState({ carregando: true });
  }

  abrirDialogFoto = (e) => {
    this.refInputFoto.click();
  }

  uploadFoto = (e) => {
    const arquivo = this.refInputFoto.files[0];
    const data = new FormData();
    data.append('foto', arquivo, arquivo.name)
    this.setState({ enviandoFoto: true });
    this.servicoImagemAdmin.enviarImagem(data)
      .then(res => {
        this.servicoLogin.buscarUsuarioApi()
          .then(usuario => {
            this.setState({ usuario: usuario });
          }).catch(err => {
            console.log(err);
          });
        this.setState({ enviandoFoto: false });
      }).catch(err => {
        this.setState({ enviandoFoto: false });
      });
  }

  efetuarLogout = () => {
    this.servicoLogin.efetuarLogout();
    this.props.history.push('/login');
  }

  renderNenhumaPermissao = () => {
    if (!this.state.usuario.funcao.permissoes || !this.state.usuario.funcao.permissoes.length) {
      return <p>Nenhuma permissão vinculada ao usuário.</p>
    }
  }
  
  renderPermissoes = () => {
    if (this.state.usuario.funcao.permissoes && this.state.usuario.funcao.permissoes.length) {
      return this.state.usuario.funcao.permissoes.map((permissao) => {
        return <li>{permissao.nome}</li>
      });
    }
  }

  renderAvatar = () => {
    if (this.state.usuario.foto) {
      return (
        <Avatar
          src={this.state.usuario.foto.url_thumb}
          style={{
            position: 'absolute',
            left: '50%',
            width: '128px',
            height: '128px',
            fontSize: '2em',
            transform: 'translate(-50%, -50%)'
        }}></Avatar>
      );
    } else {
      return (
        <Avatar style={{
          position: 'absolute',
          left: '50%',
          width: '128px',
          height: '128px',
          fontSize: '2em',
          transform: 'translate(-50%, -50%)'
        }}>{
          `
          ${this.state.usuario.nome.split(' ')[0][0]}${(this.state.usuario.nome.split(' ').length) ? this.state.usuario.nome.split(' ')[this.state.usuario.nome.split(' ').length - 1][0] : ''}
          `
          }</Avatar>
      );
    }
  }

  render () {
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Paper elevation={6} style={{position: 'relative', marginTop: '64px'}}>
              {this.renderAvatar()}
              <div style={{padding: '84px 1.5em', textAlign: 'center'}}>
                <Typography variant="h4">{this.state.usuario.nome}</Typography>
                <Typography style={{marginTop: '.5em'}}>{this.state.usuario.email}</Typography>
                <Button onClick={e => this.props.history.push(`/admins/${this.state.usuario.id}`)} style={{marginTop: '1em', width: '100%'}} variant="contained" color="primary">Editar</Button>
                <Button disabled={this.state.enviandoFoto} style={{marginTop: '1em', width: '100%', position: 'relative'}} onClick={this.abrirDialogFoto} variant="contained" color="primary">
                  Alterar foto
                  {(this.state.enviandoFoto) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary"/> : ''}
                </Button>
                <Button onClick={this.efetuarLogout} style={{marginTop: '1em', width: '100%'}} variant="outlined" color="secondary">Sair</Button>
                <input onChange={this.uploadFoto} type="file" ref={e => this.refInputFoto = e} style={{display: 'none'}} />
              </div>
            </Paper>
          </Grid>
          <Grid item md={8} xs={12}>
            <Paper elevation={6} style={{position: 'relative', marginTop: '64px', padding: '2em'}}>
              <Typography variant="h5">Função</Typography>
              <Divider style={{marginTop: '1em'}} />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="collapsing"><strong>Função</strong></TableCell>
                    <TableCell>{this.state.usuario.funcao.nome}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="collapsing"><strong>Superadmin</strong></TableCell>
                    <TableCell>{(this.state.usuario.funcao.superadmin) ? 'Sim' : 'Não'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant="h5" style={{marginTop: '.5em'}}>Permissões</Typography>
              {this.renderNenhumaPermissao()}
              <ul>
                {this.renderPermissoes()}
              </ul>
              <Typography variant="h5" style={{marginTop: '.5em'}}>Logs</Typography>
              <p>Nenhum log para este usuário.</p>
            </Paper>
          </Grid>
        </Grid>
        <ErroPagina
          aoFechar={e => this.setState({ mensagemErro: '', mostrarErro: false })}
          mostrar={this.state.mostrarErro}
          mensagem={this.state.mensagemErro} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin: state.admins.item,
    carregando: state.admins.carregando,
    erro: state.admins.erro
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginaPerfil);