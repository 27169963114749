import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';
import './index.css';


// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormDatepicker from '../../geral/CampoFormDatepicker';
import CampoFormSelect from '../../geral/CampoFormSelect';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormTextarea from '../../geral/CampoFormTextarea';
import CampoFormRadio from '../../geral/CampoFormRadio';
import CampoFormAutocomplete from '../../geral/CampoFormAutocomplete';


import {
  Drawer, Typography,
  CircularProgress, Button,
  Stepper, Step, StepLabel, StepContent,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Card, CardMedia, CardContent, Grid
} from '@material-ui/core';

import {
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles'

import {
  alterarItem,
  alterarStep,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/vouchers';

import {
  pesquisar as pesquisarEstabelecimentos
} from '../../../store/ducks/estabelecimentos';

const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        maxWidth: '500px',
        width: '90%'
      }
    }
  }
});

class DrawerAdicionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estabelecimento_parceiro: null
    }
  }
  componentDidMount() {
    this.props.alterarItem('estabelecimentos_id', this.props.match.params.id);
  }
  componentDidUpdate() {
    if (!this.state.estabelecimento_parceiro && this.props.item && this.props.item.parceiro && this.props.item.parceiro.id) {
      this.setState({estabelecimento_parceiro: this.props.item.parceiro}, () => {
        this.props.pesquisarEstabelecimentos(this.state.estabelecimento_parceiro.razao_social);
        console.log(this.props.estabelecimentos);
      });
    }
  }
  salvar = (e) => {
    e.preventDefault();
    const voucher = {
      ...this.props.item,
      inicio_validade: this.props.item.inicio_validade.format("YYYY-MM-DD HH:mm:ss"),
      final_validade: this.props.item.final_validade.format("YYYY-MM-DD HH:mm:ss")
    };
    if (this.props.estabelecimento.id) {
      voucher['estabelecimentos_id'] = this.props.estabelecimento.id; 
    }
    if(voucher.funcao === 'parceria' && this.state.estabelecimento_parceiro) {
      voucher['parceiros_id'] = this.state.estabelecimento_parceiro.id;
    } else {
      delete voucher['parceiros_id'];
    } 
    this.props.salvar(voucher);
  }
  render() {
    return (
      <div role="presentation">
        <MuiThemeProvider theme={theme}>
          <Drawer anchor="right" open={this.props.mostrar} onClose={e => this.props.esconderForm()}>
            <form id="drawer-adicionar-voucher" noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1em'}}>
              {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
              <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar cupom de desconto</Typography>
              <Stepper orientation="vertical" activeStep={this.props.step}>
              <Step style={{width: '100%'}}>
                  <StepLabel>Função</StepLabel>
                  <StepContent style={{width: '100%'}}>
                    {/* FUNÇÃO */}
                    <a href="/" style={{fontSize: '1.2em'}} class="andes-link" onClick={e => {
                      e.preventDefault();
                      this.setState({abrir_modal_funcoes: true});
                    }}>Clique para conhecer as funções dos cupons de desconto</a>
                    <CampoFormRadio
                      label="Função"
                      value={this.props.item.funcao}
                      onChange={e => this.props.alterarItem('funcao', e.target.value)}
                      opcoes={[
                        {value: 'interno', label: 'Interno'},
                        {value: 'parceria', label: 'Parceria comercial'},
                        {value: 'global', label: 'Global'}
                      ]}/>
                    {/* PARCEIRO */}
                    {(this.props.item.funcao === 'parceria')
                      ? <div className="form-parceria">
                        {/* ESTABELECIMENTO PARCEIRO */}
                        <CampoFormAutocomplete
                          label="Estabelecimento parceiro"
                          carregando={this.props.carregandoEstabelecimentos}
                          value={this.state.parceiros_id}
                          options={this.props.estabelecimentos}
                          getOptionLabel={estabelecimento_parceiro => estabelecimento_parceiro.razao_social}
                          onInputChange={(e, val) => this.props.pesquisarEstabelecimentos(val)}
                          onChange={(e, estabelecimento_parceiro) => this.setState({estabelecimento_parceiro})} />
                      </div>
                      : ''}
                    {/* BOTOES */}
                    <div className="flex-end">
                      <Button
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                          this.props.alterarStep(1)
                        }}
                        variant="contained"
                        color="primary"
                        style={{marginTop: '1em'}}>Avançar</Button>
                    </div>
                  </StepContent>
                </Step>
                <Step style={{width: '100%'}}>
                  <StepLabel>Dados</StepLabel>
                  <StepContent style={{width: '100%'}}>
                    {/* TITULO */}
                    <CampoFormTexto
                      required
                      label="Título"
                      value={this.props.item.titulo}
                      erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.titulo) ? this.props.erro.errors.titulo[0] : ''}
                      onChange={e => this.props.alterarItem('titulo', e.target.value)} />
                    {/* DESCRICAO */}
                    <CampoFormTextarea
                      label="Detalhes"
                      placeholder="Detalhes sobre o cupom"
                      value={this.props.item.descricao}
                      onChange={e => this.props.alterarItem('descricao', e)} />
                    {/* TIPO CONVERSAO */}
                    <CampoFormSelect
                      label="Tipo"
                      value={this.props.item.tipo}
                      onChange={e => this.props.alterarItem('tipo', e.target.value)}
                      opcoes={[
                        {value: 'percentual', label: 'Percentual'},
                        {value: 'valor', label: 'Valor (R$)'}
                      ]}/>
                    {/* PERCENTUAL */}
                    {(this.props.item.tipo === 'percentual')
                      ? <CampoFormTexto
                          label="Percentual"
                          type="number"
                          value={this.props.item.percentual}
                          erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.percentual) ? this.props.erro.errors.percentual[0] : ''}
                          onChange={e => this.props.alterarItem('percentual', e.target.value)} />
                          : ''}
                    {/* VALOR */}
                    {(this.props.item.tipo === 'valor')
                      ? <CampoFormTexto
                          label="Valor"
                          type="number"
                          value={this.props.item.valor}
                          erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.valor) ? this.props.erro.errors.valor[0] : ''}
                          onChange={e => this.props.alterarItem('valor', e.target.value)} />
                          : ''}
                    {/* INICIO VALIDADE */}
                    <CampoFormDatepicker
                      label="Válido desde"
                      value={this.props.item.inicio_validade}
                      onChange={e => this.props.alterarItem('inicio_validade', e)}
                      erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.inicio_validade) ? this.props.erro.errors.inicio_validade[0] : ''}
                      />
                    {/* ILIMITADO */}
                    <CampoFormSwitch
                      label="Este cupom não expira"
                      checked={this.props.item.nao_expira}
                      onChange={e => this.props.alterarItem('nao_expira', !this.props.item.nao_expira)} />
                    {/* FINAL VALIDADE */}
                    {(!this.props.item.nao_expira)
                      ? <CampoFormDatepicker
                        label="Valido até"
                        value={this.props.item.final_validade}
                        onChange={e => this.props.alterarItem('final_validade', e)}
                        erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.final_validade) ? this.props.erro.errors.final_validade[0] : ''}
                        />
                        : ''}
                    {/* ILIMITADO */}
                    <CampoFormSwitch
                      label="Tornar este cupom ilimitado (quantidade)"
                      checked={this.props.item.sem_limite_quantidade}
                      onChange={e => this.props.alterarItem('sem_limite_quantidade', !this.props.item.sem_limite_quantidade)} />
                    {/* QUANTIDADE */}
                    {(!this.props.item.sem_limite_quantidade)
                      ? <CampoFormTexto
                          label="Quantidade"
                          type="number"
                          value={this.props.item.quantidade}
                          erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.quantidade) ? this.props.erro.errors.quantidade[0] : ''}
                          onChange={e => this.props.alterarItem('quantidade', e.target.value)} />
                          : ''}
                    <div className="flex-end">
                      <Button
                        onClick={e => this.props.alterarStep(0)}
                        style={{marginRight: '.5em'}}
                        variant="contained">Voltar</Button>
                      <Button
                        disabled={this.props.carregando}
                        type="submit"
                        style={{position: 'relative'}}
                        variant="contained"
                        color="primary">
                        Salvar
                        {(this.props.carregando)
                          ? <CircularProgress
                            size={24}
                            color="secondary"
                            style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                          : ''}
                      </Button>
                    </div>
                  </StepContent>
                </Step>
              </Stepper>
            </form>
          </Drawer>
          <Dialog maxWidth="xl" open={this.state.abrir_modal_funcoes} onClose={() => this.setState({abrir_modal_funcoes: false})}>
            <DialogTitle>Funções dos cupons de desconto</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* INTERNO */}
                <Grid item md={4} sm={12} xs={12}>
                  <Card>
                    <CardMedia title="Cupom de desconto interno">
                      <img style={{width: `100%`}} src="/assets/imagens/cupom-interno.png" alt="Cupom de desconto interno" />
                    </CardMedia>
                    <CardContent>
                    <Typography variant="h4">Interno</Typography>
                      <Typography>
                      Cupons de desconto INTERNOS são, automaticamente, compartilhados com os seus clientes. Podem ser criados para direcionar o seu público ou para divulgar um produto do seu portifólio. Também podem ser usados para estimular o retorno dos seus clientes ao seu estabelecimento.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* PARCERIA */}
                <Grid item md={4} sm={12} xs={12}>
                  <Card>
                    <CardMedia title="Cupom de desconto de parceria">
                      <img style={{width: `100%`}} src="/assets/imagens/cupom-parceria.png" alt="Cupom de desconto de parceria" />
                    </CardMedia>
                    <CardContent>
                    <Typography variant="h4">Parceria comercial</Typography>
                      <Typography>
                      Cupons de desconto de PARCERIA COMERCIAL são, automaticamente, compartilhados com os SEUS CLIENTES. Estes cupons oferecem desconto em um estabelecimento parceiro. Podem ser usados para oficializar uma parceria comercial e impulsionar o seu público para frequencia no estabelecimento parceiro.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* GLOBAL */}
                <Grid item md={4} sm={12} xs={12}>
                  <Card>
                    <CardMedia title="Cupom de desconto global">
                      <img style={{width: `100%`}} src="/assets/imagens/cupom-global.png" alt="Cupom de desconto global" />
                    </CardMedia>
                    <CardContent>
                      <Typography variant="h4">Global</Typography>
                      <Typography>
                      Cupons de desconto GLOBAIS porerão ser exibidos para TODOS OS USUÁRIOS do Pontua, dependendo de filtros usados pelo usuário. Estes cupons podem ser usados para impulsionar o seu estabelecimento e para aumentar o seu público. Na assinatura padrão, o estabelecimento só pode cadastrar 1 cupom global.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({abrir_modal_funcoes: false})} color="primary">OK</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.vouchers.carregando,
    carregandoEstabelecimentos: state.estabelecimentos.carregando,
    estabelecimento: state.estabelecimentos.item,
    estabelecimentos: state.estabelecimentos.estabelecimentos,
    item: state.vouchers.item,
    step: state.vouchers.step,
    mostrar: state.vouchers.mostrarForm,
    erro: state.vouchers.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    alterarStep: (step) => dispatch(alterarStep(step)),
    salvar: (voucher) => dispatch(salvar(voucher)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm()),
    pesquisarEstabelecimentos: (pesquisa) => dispatch(pesquisarEstabelecimentos(pesquisa))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAdicionar));