import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress,
    Menu, MenuItem, Button
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/categoriasEstabelecimentos';

class TabelaCategorias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorMenu: null,
            categoria: null
        };
    }
    alterarSelecao = (e, categoria) => {
        return e.target.checked
            ? this.props.adicionarSelecao(categoria)
            : this.props.removerSelecao(categoria);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (categoria) => {
        this.refs.confirmacaoRemover.mostrar(categoria);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    iniciarRemover = (e) => {
        if (e) e.preventDefault();
        this.setState({anchorMenu: null}, () => {
            this.mostrarConfirmacaoRemover(this.state.categoria);
        });
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (categoria, e = null) => {
        if (e) e.preventDefault();
        this.props.definirItem(categoria);
        this.props.irParaMostrar(categoria.id);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.categorias.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Qtde estabelecimentos</TableCell>
                                <TableCell padding="none" align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.categorias.map((categoria, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === categoria.id)}
                                                onChange={(e) => this.alterarSelecao(e, categoria)}
                                                value={`${categoria.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>{categoria.nome}</TableCell>
                                        <TableCell>{categoria.estabelecimentos.length}</TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}} align="right">
                                            <Button
                                                className="mb-0"
                                                variant="contained"
                                                color="primary"
                                                disabled={categoria.removendo}
                                                size="small"
                                                onClick={e => this.props.iniciarEditar(categoria)} aria-label="Editar">
                                                Editar
                                                {(categoria.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '2em', top: '.2em'}} />
                                                    : ''}
                                            </Button>
                                            <IconButton
                                                size="small"
                                                color="default"
                                                onClick={e => this.setState({anchorMenu: e.currentTarget, categoria})}>
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                            {/* <IconButton
                                                onClick={e => this.mostrarConfirmacaoRemover(categoria)}
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                
                                            </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.categorias.length)
                                ? <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={4}>
                                        <Typography>Nenhuma categoria cadastrada.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={3} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                {/* MENU ITEM */}
                <Menu id="menu-categorias"
                    anchorEl={this.state.anchorMenu}
                    keepMounted
                    open={Boolean(this.state.anchorMenu)}
                    onClose={e => this.setState({anchorMenu: null})}>
                        <MenuItem onClick={(e) => this.iniciarRemover(e)}>Remover</MenuItem>
                </Menu>
                {/* MODAIS */}
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        categorias: state.categoriasEstabelecimentos.itens,
        selecionados: state.categoriasEstabelecimentos.selecionados,
        paginas: state.categoriasEstabelecimentos.paginas,
        pagina: state.categoriasEstabelecimentos.pagina,
        qtde: state.categoriasEstabelecimentos.qtde,
        carregando: state.categoriasEstabelecimentos.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (produto) => dispatch(adicionarSelecao(produto)),
        removerSelecao: (produto) => dispatch(removerSelecao(produto)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (produto) => dispatch(remover(produto)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (produto) => dispatch(definirItem(produto)),
        iniciarEditar: (produto) => dispatch(iniciarEditar(produto))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaCategorias);