import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';


import HeaderForm from '../../HeaderForm';

import {
  Paper, Grid, Typography, Table,
  TableBody, TableRow, TableCell
} from "@material-ui/core";

class TabDados extends Component {
  componentDidMount() {
    console.log(this.props.item);
  }
  renderCpf = () => {
    if (this.props.item.tipo === 'pessoa_fisica') {
      return (
        <TableRow>
          <TableCell>CPF</TableCell>
          <TableCell>{this.props.item.cpf}</TableCell>
        </TableRow>
      );
    }
  }
  renderCnpj = () => {
    if (this.props.item.tipo === 'pessoa_juridica') {
      return (
        <TableRow>
          <TableCell>CNPJ</TableCell>
          <TableCell>{this.props.item.cnpj}</TableCell>
        </TableRow>
      );
    }
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Dados do estabelecimento"/>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
          {/* GERAL */}
            <Typography style={{marginTop: '1em'}} variant="h6">Geral</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>{this.props.item.razao_social}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>{this.props.item.nome_fantasia}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>{(this.props.item.tipo === 'pessoa_fisica') ? 'Pessoa física' : 'Pessoa jurídica'}</TableCell>
                </TableRow>
                {this.renderCpf()}
                {this.renderCnpj()}
                <TableRow>
                  <TableCell>Categoria</TableCell>
                  <TableCell>{(this.props.item.categoria) ? this.props.item.categoria.nome : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{this.props.item.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Regras</TableCell>
                  <TableCell>
                    <div>
                      {(this.props.item.regras) ? renderHTML(this.props.item.regras) : ''}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* CONTATO */}
            <Typography style={{marginTop: '1em'}} variant="h6">Contato</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>E-mail</TableCell>
                  <TableCell>{this.props.item.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Telefone</TableCell>
                  <TableCell>{this.props.item.telefone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Whatsapp</TableCell>
                  <TableCell>{this.props.item.whatsapp}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Facebook</TableCell>
                  <TableCell>
                    {(this.props.item.facebook)
                      ? <a href={this.props.item.facebook} rel="noopener noreferrer" target="_blank" className="andes-link">{this.props.item.facebook}</a>
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Instagram</TableCell>
                  <TableCell>
                    {(this.props.item.instagram)
                      ? <a href={this.props.item.instagram} rel="noopener noreferrer" target="_blank" className="andes-link">{this.props.item.instagram}</a>
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Site</TableCell>
                  <TableCell>
                    {(this.props.item.site)
                      ? <a href={this.props.item.site} rel="noopener noreferrer" target="_blank" className="andes-link">{this.props.item.site}</a>
                      : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* ENDEREÇO */}
            <Typography style={{marginTop: '1em'}} variant="h6">Endereço</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Estado</TableCell>
                  <TableCell>{this.props.item.estado}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cidade</TableCell>
                  <TableCell>{this.props.item.cidade}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bairro</TableCell>
                  <TableCell>{this.props.item.bairro}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CEP</TableCell>
                  <TableCell>{this.props.item.cep}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Logradouro</TableCell>
                  <TableCell>{this.props.item.logradouro}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell>{this.props.item.numero}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Complemento</TableCell>
                  <TableCell>{this.props.item.complemento}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    item: state.estabelecimentos.item
  }
}

export default connect(mapStateToProps)(TabDados);