import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoFuncaoEstabelecimento from '../../servicos/ServicoFuncaoEstabelecimento';
import { Types } from '../ducks/funcoesEstabelecimentos';
const servicoFuncao = new ServicoFuncaoEstabelecimento();

function* buscarTodos (action) {
    try {
        const funcoes = yield call([servicoFuncao, servicoFuncao.buscarFuncoes], action.qtde, action.pagina);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, funcoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* sagaFuncEstab () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
}

export default sagaFuncEstab;