import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';

// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, CircularProgress, Button
} from '@material-ui/core';

import { IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/vantagensSite';

const styles = {
  overFlowVisible: {
    overflow: 'visible !important'
  },
  drawerNormal: {
    width: '500px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  drawerExtendido: {
    width: '768px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  btnFloatHover: {
    backgroundColor: 'white'
  },
  btnFloatNormal: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  },
  btnFloatExtendido: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%) rotate(180deg)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  }
};

class DrawerAdicionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendido: false
    }
  }
  extenderMenu = (e) => {
    e.preventDefault();
    this.setState({extendido: !this.state.extendido});
  }
  salvar = (e) => {
    e.preventDefault();
    const pergunta = { ...this.props.item };
    this.props.salvar(pergunta);
  }
  render() {
    return (
      <div role="presentation">
        <Drawer
          classes={{
            paper: (this.state.extendido) ? this.props.classes.drawerExtendido : this.props.classes.drawerNormal
          }}
          anchor="right"
          open={this.props.mostrar}
          onClose={e => this.props.esconderForm()}>
          <IconButton
            classes={{
              root: (this.state.extendido) ? this.props.classes.btnFloatExtendido : this.props.classes.btnFloatNormal
            }}
            size="small"
            onClick={this.extenderMenu}
            color="primary"
            aria-label="Extender">
              <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1.5em', overflow: 'auto'}}>
            {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
            <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar vantagem</Typography>
            {/* NOME */}
            <CampoFormTexto
              required
              label="Título"
              value={this.props.item.titulo}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.titulo) ? this.props.erro.errors.titulo[0] : ''}
              onChange={e => this.props.alterarItem('titulo', e.target.value)} />
            {/* DEWTALHES */}
            <CampoFormTexto
              required
              multiline
              label="Conteúdo"
              value={this.props.item.conteudo}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.conteudo) ? this.props.erro.errors.conteudo[0] : ''}
              onChange={e => this.props.alterarItem('conteudo', e.target.value)} />
            {/* ERRO */}
            {(this.props.erro && this.props.erro.message)
              ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
              : ''}
            <div className="flex-end">
              <Button
                disabled={this.props.carregando}
                type="submit"
                style={{marginTop: '1em', position: 'relative'}}
                variant="contained"
                color="primary">
                Salvar
                {(this.props.carregando)
                  ? <CircularProgress
                    size={24}
                    color="secondary"
                    style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                  : ''}
              </Button>
            </div>
          </form>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.vantagensSite.carregando,
    item: state.vantagensSite.item,
    mostrar: state.vantagensSite.mostrarForm,
    erro: state.vantagensSite.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (vantagem) => dispatch(salvar(vantagem)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerAdicionar)));