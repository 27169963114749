import React from 'react';
import { connect } from 'react-redux';
// MATERIAL
import { CircularProgress, Grid, Typography, Paper, Divider } from '@material-ui/core';
// GRAFICO
import {
  ResponsiveContainer, XAxis, YAxis, Tooltip, PieChart, Pie, Cell, Legend,
  CartesianGrid, BarChart, Bar, LineChart, Line
} from 'recharts';
// COMPONENTES CUSTOMIZADOS
import CardEstatistica from '../componentes/geral/CardEstatistica';
import TabelaTopUsuarios from '../componentes/usuarios/TabelaTopUsuarios';
// REDUX
import { buscarItem as buscarEstatisticas } from '../store/ducks/estatisticasGerais';
import { buscarTodos as buscarUsuarios } from '../store/ducks/usuarios';
import { alterarOrdem as alterarOrdemUsuarios } from '../store/ducks/usuarios';

const cores = ['#58359d', '#ff3f6e', '#ff6033', '#FFBB28', '#FF8042'];

class PaginaDashboard extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarEstatisticas();
    this.props.alterarOrdemUsuarios('pontos-desc');
    setTimeout(() => {
      this.props.buscarUsuarios(1, 10, this.props.ordem);
    });
  }

  renderGrafico = () => {
    return (
      <>
        <Typography>Estabelecimentos cadastrados</Typography>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={this.props.estatisticasGerais.historicoMensal} height={180}>
            <Line
              dot={{ stroke: '#58359D', strokeWidth: 1, fill: '#58359D' }}
              stroke="#58359D"
              strokeWidth={2}
              type="natural"
              dataKey="estabelecimentos"
              name="Estabelecimentos" />
            <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
        <Typography>Pontos convertidos</Typography>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={this.props.estatisticasGerais.historicoMensal}>
            <Line
              dot={{ stroke: '#FF3F6E', strokeWidth: 1, fill: '#FF3F6E' }}
              stroke="#FF3F6E"
              strokeWidth={2}
              type="natural"
              dataKey="pontos"
              name="Pontos convertidos" />
            <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
        <Typography>Usuários cadastrados</Typography>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={this.props.estatisticasGerais.historicoMensal}>
            <Line
              dot={{ stroke: '#FF6033', strokeWidth: 1, fill: '#FF6033' }}
              stroke="#FF6033"
              strokeWidth={2}
              type="natural"
              dataKey="usuarios"
              name="Usuários" />
            <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  };

  renderGraficoUsuariosPorSexo = () => {
    const itensGrafico = [];
    if(this.props.estatisticasGerais && this.props.estatisticasGerais.usuarios && this.props.estatisticasGerais.usuarios.por_sexo) {
      console.log(this.props.estatisticasGerais.usuarios);
      const { por_sexo } = this.props.estatisticasGerais.usuarios;
      let total = 0;
      por_sexo.map(sexo => {
        total += parseInt(sexo.quantidade);
        return itensGrafico.push({name: (sexo.nome_sexo) ? sexo.nome_sexo : 'Não informado', value: parseInt(sexo.quantidade)})
      });
      return (
        <Paper elevation={3} style={{width: '100%', height: '300px', padding: '1em'}}>
          <Typography variant="h6">Por sexo</Typography>
          <ResponsiveContainer height={250}>
            <PieChart>
              <Pie isAnimationActive={false} dataKey="value" data={itensGrafico} fill="#222222"
              cx="50%" cy="50%" outerRadius={80}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text
                    x={x} y={y} fill="#222222"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central">
                    {itensGrafico[index].name} ({Math.ceil((100*value)/total)}%)
                  </text>
                );
                }}>
                  {
                    itensGrafico.map((entry, index) => <Cell key={index} fill={cores[index % cores.length]}/>)
                  }
                </Pie>
                <Legend wrapperStyle={{bottom: '10px'}} />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      );
    }
  }

  renderGraficoUsuariosPorFaixaEtaria = () => {
    const itensGrafico = [];
    if(this.props.estatisticasGerais && this.props.estatisticasGerais.usuarios && this.props.estatisticasGerais.usuarios.por_faixa_etaria) {
      console.log(this.props.estatisticasGerais.usuarios);
      const { por_faixa_etaria } = this.props.estatisticasGerais.usuarios;
      let total = 0;
      por_faixa_etaria.map(faixa => {
        total += parseInt(faixa.quantidade);
        return itensGrafico.push({name: faixa.faixa, value: parseInt(faixa.quantidade)})
      });
      return (
        <Paper elevation={3} style={{width: '100%', height: '300px', padding: '1em'}}>
          <Typography variant="h6">Por faixa etária</Typography>
          <ResponsiveContainer height={250}>
            <BarChart data={itensGrafico}>
              <Bar dataKey="value" name="Pontos" fill="#FF6033">
                {
                  itensGrafico.map((entry, index) => <Cell key={index} fill={cores[index % cores.length]}/>)
                }
              </Bar>
              <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      );
    }
  }

  render () {
    return (
      <div>
        <Paper elevation={6} style={{padding: '1em 1.5em'}}>
          {/* GERAL */}
          <Typography variant="h4" style={{paddingTop: '.5em'}}>Geral</Typography>
          <Grid container spacing={2} style={{marginBottom: '.5em', marginTop: '.5em'}}>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="primary"
                titulo={(this.props.estatisticasGerais.pontuacoes) ? this.props.estatisticasGerais.pontuacoes.total : '-'}
                adicional={
                  (this.props.estatisticasGerais.pontuacoes && this.props.estatisticasGerais.pontuacoes.hoje > 0) ? `+${this.props.estatisticasGerais.pontuacoes.hoje} hoje` : ''
                }
                texto={'Pontuações'}
                link="/estabelecimentos"
                icone="done" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="primary"
                titulo={(this.props.estatisticasGerais.pontos) ? this.props.estatisticasGerais.pontos.total : '-'}
                adicional={
                  (this.props.estatisticasGerais.pontos && this.props.estatisticasGerais.pontos.hoje > 0) ? `+${this.props.estatisticasGerais.pontos.hoje} hoje` : ''
                }
                texto={'Pontos'}
                link="/estabelecimentos"
                icone="done_all" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="secondary"
                titulo={(this.props.estatisticasGerais.resgates_produtos) ? this.props.estatisticasGerais.resgates_produtos.total : '0'}
                adicional={
                  (this.props.estatisticasGerais.resgates_produtos && this.props.estatisticasGerais.resgates_produtos.hoje > 0) ? `+${this.props.estatisticasGerais.resgates_produtos.hoje} hoje` : ''
                }
                texto={'Resgates de prêmios'}
                link="/estabelecimentos"
                icone="emoji_events" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="tertiary"
                titulo={(this.props.estatisticasGerais.resgates_vouchers) ? this.props.estatisticasGerais.resgates_vouchers.total : '0'}
                adicional={
                  (this.props.estatisticasGerais.resgates_vouchers && this.props.estatisticasGerais.resgates_vouchers.hoje > 0) ? `+${this.props.estatisticasGerais.resgates_vouchers.hoje} hoje` : ''
                }
                texto={'Resgates de cupons'}
                link="/estabelecimentos"
                icone="insert_link" />
            </Grid>
          </Grid>
          {/* USUARIOS */}
          <Divider style={{marginTop: '1.5em'}} />
          <Paper style={{padding: '.5em 1em'}} elevation={0}>
            {(this.props.carregandoUsuarios) ? <CircularProgress size={24} color="secondary" /> : ''}
            {/* TITULO */}
            <Grid container>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography variant="h4" style={{paddingTop: '.5em', marginBottom: '.5em'}}>Usuários</Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6} justify="flex-end" alignItems="center">
                  <Typography color="primary" className="text-uppercase" style={{display: 'inline'}}>Total de usuários: <strong>{(this.props.estatisticasGerais) ? this.props.estatisticasGerais.usuarios.total : 0}</strong></Typography>
                  <Typography color="secondary" style={{display: 'inline', marginLeft: '.5em'}}>{(this.props.estatisticasGerais.usuarios && this.props.estatisticasGerais.usuarios.hoje > 0) ? `+${this.props.estatisticasGerais.usuarios.hoje} hoje` : ''}</Typography>
                </Grid>
            </Grid>
            {/* PIZZA USUARIOS */}
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                {this.renderGraficoUsuariosPorSexo()}
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                {this.renderGraficoUsuariosPorFaixaEtaria()}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
                {/* TOP USUARIOS */}
              <Grid item md={4} sm={12} xs={12}>
                <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                  <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Maiores pontuadores</Typography>
                  <TabelaTopUsuarios usuarios={this.props.usuarios} />
                </Paper>
              </Grid>
              {/* MAIS VEZES */}
              <Grid item md={4} sm={12} xs={12}>
                <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                  <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Pontuaram mais vezes</Typography>
                  <TabelaTopUsuarios colunaDados="Vezes" usuarios={this.props.estatisticasGerais.usuarios.mais_vezes} />
                </Paper>
              </Grid>
              {/* MAIS VEZES */}
              <Grid item md={4} sm={12} xs={12}>
                <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                  <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Pontuaram menos vezes</Typography>
                  <TabelaTopUsuarios colunaDados="Vezes" usuarios={this.props.estatisticasGerais.usuarios.menos_vezes} />
                </Paper>
              </Grid>
            </Grid>

          </Paper>
          <Divider style={{marginTop: '1em'}} />
          {/* HISTÓRICO */}
          <Paper style={{padding: '.5em 1em', marginBottom: '1em'}} elevation={0}>
            <Typography variant="h4" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Histórico</Typography>
            {this.renderGrafico()}
          </Paper>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.estatisticasGerais.carregando,
    erro: state.estatisticasGerais.erro,
    estatisticasGerais: state.estatisticasGerais.item,
    carregandoUsuarios: state.usuarios.carregando,
    usuarios: state.usuarios.usuarios,
    ordem: state.usuarios.ordem
  }
}

const mapDispatchToProps = dispatch => {
  return {
    buscarEstatisticas: () => dispatch(buscarEstatisticas()),
    buscarUsuarios: (pagina, qtde, ordem) => dispatch(buscarUsuarios(pagina, qtde, ordem)),
    alterarOrdemUsuarios: (ordem) => dispatch(alterarOrdemUsuarios(ordem))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginaDashboard);