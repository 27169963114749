import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    Chip, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/promocoes';

class TabelaPromocoes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, promocao) => {
        return e.target.checked
            ? this.props.adicionarSelecao(promocao)
            : this.props.removerSelecao(promocao);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (promocao) => {
        this.refs.confirmacaoRemover.mostrar(promocao);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (promocao, e = null) => {
        if (e) e.preventDefault();
        this.props.definirItem(promocao);
        this.props.irParaMostrar(promocao.id);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.promocoes.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Ativo</TableCell>
                                <TableCell>Estab.</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.promocoes.map((promocao, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === promocao.id)}
                                                onChange={(e) => this.alterarSelecao(e, promocao)}
                                                value={`${promocao.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>
                                            <Link onClick={e => this.mostrar(promocao, e)} className="andes-link" to={`/promocoes/ver/${promocao.id}`}>{promocao.nome}</Link>
                                        </TableCell>
                                        <TableCell>{promocao.valor}</TableCell>
                                        <TableCell>{(promocao.ativo ? <Chip label="Ativa" color="primary" /> : '-')}</TableCell>
                                        <TableCell>
                                            {(promocao.estabelecimento)
                                                ? <Link className="andes-link" to={`/estabelecimentos/ver/${promocao.estabelecimento.id}?tab=promocoes`}>{promocao.estabelecimento.razao_social}</Link>
                                                : '-'}
                                        </TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                            <IconButton size="small" onClick={e => this.mostrar(promocao)} color="default" aria-label="Detalhes">
                                                <Icon>search</Icon>
                                            </IconButton>
                                            <IconButton size="small" onClick={e => this.props.iniciarEditar(promocao)} color="default" aria-label="Editar">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={promocao.removendo}
                                                onClick={e => this.mostrarConfirmacaoRemover(promocao)}
                                                color="secondary"
                                                aria-label="Remover"
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                {(promocao.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '.2em', top: '.2em'}} />
                                                    : ''}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.promocoes.length)
                                ? <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum promoção cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={6} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        promocoes: state.promocoes.itens,
        selecionados: state.promocoes.selecionados,
        paginas: state.promocoes.paginas,
        pagina: state.promocoes.pagina,
        qtde: state.promocoes.qtde,
        carregando: state.promocoes.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (promocao) => dispatch(adicionarSelecao(promocao)),
        removerSelecao: (promocao) => dispatch(removerSelecao(promocao)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (promocao) => dispatch(remover(promocao)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (promocao) => dispatch(definirItem(promocao)),
        irParaMostrar: (id) => dispatch(push(`/promocoes/ver/${id}`)),
        iniciarEditar: (promocao) => dispatch(iniciarEditar(promocao))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaPromocoes);