const stateInicial = {
    titulo: '',
    pagina: 1,
    qtde: 25,
    layout: 'tabela',
    pesquisando: false
}

export const Types = {
    ALTERAR_TITULO: 'paginas/ALTERAR_TITULO',
    PESQUISAR_REQUEST: 'paginas/PESQUISAR_REQUEST',
    PESQUISAR_SUCESSO: 'paginas/PESQUISAR_SUCESSO',
    PESQUISAR_ERRO: 'paginas/PESQUISAR_ERRO'
}

export default function reducer (state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_TITULO: {
            return {...state, titulo: action.titulo}
        }
        case Types.PESQUISAR_REQUEST: {
            return {
                ...state,
                pesquisando: true
            }
        }
        default: return {...state};
    }
}

export function alterarTitulo (titulo) {
    return {
        type: Types.ALTERAR_TITULO,
        titulo
    }
}