import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoInscricao from '../../servicos/ServicoInscricao';
import { Types } from '../ducks/inscricoes';
import { Types as PesquisaTypes } from '../ducks/pesquisa';
const servico = new ServicoInscricao();

function* buscarItem (action) {
    try {
        const inscricao = yield call([servico, servico.buscarInscricaoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, inscricao});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const inscricoes = yield call([servico, servico.buscarInscricoes], action.qtde, action.pagina);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, inscricoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* exportar(action) {
    try {
        const link = yield call([servico, servico.exportarInscricoes]);
        yield put({ type: Types.EXPORTAR_SUCESSO, link: link.link });
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.EXPORTAR_ERRO, erro: e.message});
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerInscricao], action.inscricao.id);
        yield put({type: Types.REMOVER_SUCESSO, inscricao: action.inscricao});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* pesquisar (action) {
    try {
        const inscricoes = yield call([servico, servico.pesquisarInscricoes], action.pesquisa, action.qtde, action.pagina);
        yield put({type: Types.PESQUISAR_SUCESSO, inscricoes});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.PESQUISAR_ERRO, erro: e.message});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     }
}

function* sagaContatos () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
    yield takeLatest(Types.PESQUISAR_REQUEST, pesquisar);
    yield takeLatest(Types.EXPORTAR_REQUEST, exportar);
}

export default sagaContatos;