import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AppBar, Tabs, Tab, Button, LinearProgress, ButtonGroup } from '@material-ui/core';

import DrawerPromocoes from '../componentes/promocoes/DrawerAdicionar';
import TabDados from '../componentes/promocoes/TabDados';
import TabImagens from '../componentes/promocoes/TabImagens';

import {
  alterarTab,
  buscarItem,
  iniciarEditar
} from '../store/ducks/promocoes';

class PaginaDetalhesPromocao extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      tab: 0,
      carregando: false,
      promocao: props.promocao || {}
    };
  }

  componentDidMount() {
    this.props.buscarItem(this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <ButtonGroup variant="contained" style={{marginBottom: '1em'}}>
          <Button
            style={{marginBottom: '0'}}
            component={Link}
            to={`/promocoes`}
            color="default">
              Voltar
          </Button>
          <Button
            style={{marginBottom: '0'}}
            onClick={e => this.props.iniciarEditar(this.props.item)}
            color="primary">
              Editar
          </Button>
        </ButtonGroup>
        {/* TABS */}
        <AppBar position="static">
          <Tabs 
            variant="scrollable"
            scrollButtons="auto"
            value={this.props.tab}
            onChange={(e, val) => { this.props.alterarTab(val) }}>
            <Tab label="Dados" />
            <Tab label="Imagens" />
          </Tabs>
        </AppBar>
        {/* LOADER */}
        {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
        {/* TABS */}
        {this.props.tab === 0 && <TabDados />}
        {this.props.tab === 1 && <TabImagens />}
        <DrawerPromocoes />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.promocoes.carregando,
    tab: state.promocoes.tab,
    item: state.promocoes.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarTab: (tab) => dispatch(alterarTab(tab)),
    buscarItem: (id) => dispatch(buscarItem(id)),
    iniciarEditar: (item) => dispatch(iniciarEditar(item)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaDetalhesPromocao));