const stateInicial = {
  titulo: '',
  breadcrumbs: []
}

export const Types = {
  ALTERAR_TITULO: 'header/ALTERAR_TITULO',
  ADICIONAR_BREADCRUMB: 'header/ADICIONAR_BREADCRUMB',
  REMOVER_BREADCRUMB: 'header/REMOVER_BREADCRUMB',
  LIMPAR_BREADCRUMBS: 'header/LIMPAR_BREADCRUMBS'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_TITULO: {
      return {...state, titulo: action.titulo}
    }
    case Types.ADICIONAR_BREADCRUMB: {
      const breadcrumbs = [...state.breadcrumbs];
      breadcrumbs.push(action.breadcrumb);
      return {...state, breadcrumbs};
    }
    case Types.REMOVER_BREADCRUMB: {
      const breadcrumbs = [...state.breadcrumbs];
      breadcrumbs.pop();
      return {...state, breadcrumbs};
    }
    case Types.LIMPAR_BREADCRUMBS: {
      return {...state, breadcrumbs: []}
    }
    default: return {...state};
  }
}

export function alterarTitulo (titulo) {
  return {
    type: Types.ALTERAR_TITULO,
    titulo
  }
}

export function adicionarBreadcrumb (breadcrumb) {
  return {
    type: Types.ADICIONAR_BREADCRUMB,
    breadcrumb
  }
}

export function removerBreadcrumb () {
  return {
    type: Types.REMOVER_BREADCRUMB
  }
}

export function limparBreadcrumbs () {
  return {
    type: Types.LIMPAR_BREADCRUMBS
  }
}