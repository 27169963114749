import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import CampoFormErro from '../componentes/geral/CampoFormErro';
import InputPesquisa from '../componentes/geral/InputPesquisa';
import DrawerTextos from '../componentes/textosSite/DrawerAdicionar';
import TabelaTextos from '../componentes/textosSite/TabelaTextos';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  buscarTodos,
  pesquisar
} from '../store/ducks/textosSite';

class PaginaTextosSite extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <CampoFormErro titulo="Erro" mensagem={this.props.erro} /> : ''}
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item container md={12} sm={12} xs={12} justify="flex-end">
              {/* PESQUISA */}
              <InputPesquisa 
                aoPesquisar={() => this.props.pesquisar(this.props.pesquisa)}
                aoRemoverPesquisa={() => this.props.buscarTodos(1, this.props.qtde)} />
            </Grid>
          </Grid>
          <DrawerTextos />
          <TabelaTextos />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.textosSite.carregando,
    erro: state.textosSite.erro,
    layout: state.textosSite.layout,
    textos: state.textosSite.itens,
    selecionados: state.textosSite.selecionados,
    pagina: state.textosSite.pagina,
    qtde: state.textosSite.qtde,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    pesquisar: (pesquisa) => dispatch(pesquisar(pesquisa))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaTextosSite));