// STATE INICIAL

const stateInicial = {
  email: '',
  senha: '',
  carregando: false,
  erro: ''
}

export const Types = {
  ALTERAR_EMAIL: 'auth/ALTERAR_EMAIL',
  ALTERAR_SENHA: 'auth/ALTERAR_SENHA',
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCESSO: 'auth/LOGIN_SUCESSO',
  LOGIN_ERRO: 'auth/LOGIN_ERRO',
}

export default function reducer(state = stateInicial, action) {
  switch (action.type) {
    case Types.ALTERAR_EMAIL: {
        return {...state, email: action.email}
    }
    case Types.ALTERAR_SENHA: {
      return {...state, senha: action.senha}
    }
    case Types.LOGIN_REQUEST: {
      return {...state, erro: '', carregando: true}
    }
    case Types.LOGIN_SUCESSO: {
      return {
        ...state,
        erro: '',
        carregando: false,
        email: '',
        senha: ''
      }
    }
    case Types.LOGIN_ERRO: {
      return {
        ...state,
        erro: action.erro,
        carregando: false
      }
    }
    default: return {...state}
  }
}

export function alterarEmail(email) {
  return {
    type: Types.ALTERAR_EMAIL,
    email
  }
}

export function alterarSenha(senha) {
  return {
    type: Types.ALTERAR_SENHA,
    senha
  }
}

export function efetuarLogin(email, senha) {
  return {
    type: Types.LOGIN_REQUEST,
    email,
    senha
  }
}