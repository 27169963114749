import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import HeaderForm from '../../HeaderForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import ErroForm from '../../ErroForm';
import moment from 'moment';

import { Paper, Button, Table,
  TableHead, TableRow, TableCell,
  TableBody, IconButton, Icon,
  CircularProgress, Typography
} from '@material-ui/core';

import {
  iniciarEditar,
  mostrarForm,
  remover
} from '../../../store/ducks/vouchers';

class TabVouchers extends Component {
  renderVouchers = () => {
    return this.props.itens.map((voucher, index) => {
      return (
        <TableRow key={voucher.id}>
          <TableCell padding="none">{index + 1}</TableCell>
          <TableCell>{voucher.titulo}</TableCell>
          <TableCell style={{textAlign: 'center'}}>
            {(voucher.funcao === 'parceria')
              ? <> 
                  <span>parceria com</span><br />
                  <Icon>compare_arrows</Icon><br /><Link className="andes-link" to={`/estabelecimentos/ver/${voucher.parceiro.id}`}>{voucher.parceiro.razao_social}</Link>
              </>
              : voucher.nomeFuncao}
          </TableCell>
          <TableCell>{(voucher.tipo === 'percentual') ? 'Percentual' : 'Valor'}</TableCell>
          <TableCell>{(voucher.tipo === 'percentual') ? `${voucher.percentual}%` : `R$${voucher.valor}`}</TableCell>
          <TableCell>
            de {moment(voucher.inicio_validade).format('DD/MM/YYYY')} <br /> até {
            (!voucher.nao_expira) ? moment(voucher.final_validade).format('DD/MM/YYYY') : 'indefinido (não expira)'
            }
          </TableCell>
          <TableCell>{(voucher.sem_limite_quantidade) ? 'Ilimitado' : voucher.quantidade}</TableCell>
          <TableCell padding="none">
            <IconButton size="small" onClick={e => this.props.irParaDetalhes(voucher.id)} color="default" aria-label="Ver">
              <Icon>search</Icon>
            </IconButton>
            <IconButton size="small" onClick={e => this.props.iniciarEditar(voucher)} color="default" aria-label="Editar">
              <Icon>edit</Icon>
            </IconButton>
            <IconButton size="small" disabled={(voucher.carregando) ? true : false} style={{position: 'relative'}} onClick={() => this.refs.confirmacaoRemover.mostrar(voucher)} color="secondary" aria-label="Remover">
              <Icon>delete</Icon>
              {(voucher.carregando) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary" /> : ''}
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Cupons do estabelecimento"/>
        {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
        <Button onClick={this.props.mostrarForm} style={{marginTop: '1em'}} variant="contained" color="primary">Adicionar</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Título</TableCell>
              <TableCell style={{textAlign: 'center'}}>Função</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Percent/Valor</TableCell>
              <TableCell>Validade</TableCell>
              <TableCell>Quantidade</TableCell>
              <TableCell padding="none">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(this.props.itens && this.props.itens.length)
              ? this.renderVouchers()
              : <TableRow>
                  <TableCell padding="none"></TableCell>
                  <TableCell colSpan="7">
                    <Typography>Nenhum cupom de desconto encontrado para este estabelecimento.</Typography>
                  </TableCell>
                </TableRow>}
          </TableBody>
        </Table>
        <ModalConfirmacao
          titulo="Remover o cupom?"
          ref="confirmacaoRemover"
          aoConfirmar={voucher => this.props.remover(voucher)}>
          Você tem certeza que deseja remover o cupom?
        </ModalConfirmacao>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    itens: state.vouchers.itens,
    item: state.vouchers.item,
    erro: state.vouchers.erro
  }
}

const mapDispatchToProps = dispatch => {
  return {
    iniciarEditar: (item) => dispatch(iniciarEditar(item)),
    remover: (voucher) => dispatch(remover(voucher)),
    mostrarForm: () => dispatch(mostrarForm()),
    irParaDetalhes: (id) => dispatch(push(`/vouchers/ver/${id}`))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabVouchers);