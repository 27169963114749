import axios from 'axios';
class ServicoResgate {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/resgates` : 'https://api.pontuafidelidade.com.br/api/admin/resgates';

  constructor() {
    this.buscarToken();
    this.resgates = (localStorage.getItem('resgates')) ? JSON.parse(localStorage.getItem('resgates')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarResgate(idVoucher, qtde = 25, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}/${idVoucher}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

}

export default ServicoResgate;