import React, { Component } from 'react';
import {
    FormControlLabel, FormLabel, RadioGroup,
    FormControl, Radio, FormHelperText
} from '@material-ui/core';

class CampoFormRaio extends Component {
    constructor(props) {
        super(props);
        this.state = { erro: props.erro || '' }
      }
      componentWillReceiveProps(props) {
        this.setState({erro: props.erro});
      }
    render() {
        return (
            <FormControl component="fieldset" margin="normal" fullWidth style={{position: 'relative'}}>
                <FormLabel component="legend">{this.props.label}</FormLabel>
                <RadioGroup
                    value={this.props.value}
                    onChange={this.props.onChange}
                    error={(this.props.erro) ? true : false}>
                {this.props.opcoes.map((opcao, i) => {
                    return <FormControlLabel key={i} value={opcao.value} control={<Radio />} label={opcao.label} />
                })}
                </RadioGroup>
                {(this.props.erro || this.props.ajuda) ? 
                    <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
                    : ''}
            </FormControl>
        )
    }
}

export default CampoFormRaio;
