import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import DrawerFuncoes from '../componentes/funcoes/DrawerAdicionar';
import MenuSelecaoLayout from '../componentes/geral/MenuSelecaoLayout';
import ListaFuncoes from '../componentes/funcoes/ListaFuncoes';
import TabelaFuncoes from '../componentes/funcoes/TabelaFuncoes';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  buscarTodos,
  pesquisar,
  mostrarForm
} from '../store/ducks/funcoes';

class PaginaFuncoes extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  ajustarTela = () => (window.innerWidth < 768)
    ? this.props.alterarLayout('lista')
    : this.props.alterarLayout('tabela')

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
    this.ajustarTela();
    window.addEventListener("resize", window.addEventListener("resize", this.ajustarTela.bind(this)));
   
  }

  render () {
    return (
        <div>
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Button
                onClick={e => this.props.mostrarForm()}
                variant="contained"
                color="primary">
                  Adicionar
              </Button>
            </Grid>
            <Grid item container md={6} sm={12} xs={12} justify="flex-end">
              {/* LAYOUT */}
              <MenuSelecaoLayout
                layout={this.props.layout}
                aoAlterarLayout={layout => this.props.alterarLayout(layout)} />
            </Grid>
          </Grid>
          <DrawerFuncoes />
          {/* TABELA */}
          {(this.props.layout === 'tabela')
            ? <TabelaFuncoes /> : ''}
          {/* LISTA */}
          {(this.props.layout === 'lista')
            ? <ListaFuncoes /> : ''}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.funcoes.carregando,
    erro: state.funcoes.erro,
    layout: state.funcoes.layout,
    funcoes: state.funcoes.funcoes,
    selecionados: state.funcoes.selecionados,
    pagina: state.funcoes.pagina,
    qtde: state.funcoes.qtde,
    pesquisa: state.pesquisa.pesquisa
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    pesquisar: (pesquisa) => dispatch(pesquisar(pesquisa)),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaFuncoes));