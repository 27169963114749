import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoCategoriaEstabelecimento';
import { Types as CategoriasTypes } from '../ducks/categoriasEstabelecimentos';
import { Types as PesquisaTypes } from '../ducks/pesquisa';
const servico = new Servico();

function* salvar (action) {
    if (action.categoria.id) {
        try {
            const categoria = yield call([servico, servico.atualizarCategoria], action.categoria.id, action.categoria);
            yield put({type: CategoriasTypes.SALVAR_SUCESSO, categoria: categoria.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: CategoriasTypes.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const categoria = yield call([servico, servico.adicionarCategoria], action.categoria);
            yield put({type: CategoriasTypes.SALVAR_SUCESSO, categoria});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: CategoriasTypes.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerCategoria], action.categoria.id);
        yield put({type: CategoriasTypes.REMOVER_SUCESSO, categoria: action.categoria});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: CategoriasTypes.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const categoria = yield call([servico, servico.buscarCategoriaPorId], action.id);
        yield put({type: CategoriasTypes.BUSCAR_ITEM_SUCESSO, categoria});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: CategoriasTypes.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const categorias = yield call([servico, servico.buscarCategorias], action.qtde, action.pagina);
        yield put({type: CategoriasTypes.BUSCAR_TODOS_SUCESSO, categorias});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: CategoriasTypes.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* pesquisar (action) {
    try {
        const categorias = yield call([servico, servico.pesquisarCategorias], action.pesquisa, action.qtde, action.pagina);
        yield put({type: CategoriasTypes.PESQUISAR_SUCESSO, categorias});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: CategoriasTypes.PESQUISAR_ERRO, erro: e.message});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     }
}

function* saga () {
    yield takeLatest(CategoriasTypes.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(CategoriasTypes.SALVAR_REQUEST, salvar);
    yield takeLatest(CategoriasTypes.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(CategoriasTypes.PESQUISAR_REQUEST, pesquisar);
    yield takeEvery(CategoriasTypes.REMOVER_REQUEST, remover);
}

export default saga;