import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderForm from '../../HeaderForm';

import {
  Paper, Grid, Typography, Table,
  TableBody, TableRow, TableCell, Chip
} from "@material-ui/core";

class TabDados extends Component {
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Dados da promoção"/>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
          {/* GERAL */}
            <Typography style={{marginTop: '1em'}} variant="h6">Geral</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Estabelecimento</TableCell>
                  <TableCell>
                  {(this.props.item.estabelecimento)
                    ? <Link className="andes-link" to={`/estabelecimentos/ver/${this.props.item.estabelecimento.id}`}>{this.props.item.estabelecimento.razao_social}</Link>
                    : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>{this.props.item.nome}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valor</TableCell>
                  <TableCell>{this.props.item.valor}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ativo</TableCell>
                  <TableCell>{(this.props.item.ativo ? <Chip label="Ativa" color="primary" /> : '-')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    item: state.promocoes.item
  }
}

export default connect(mapStateToProps)(TabDados);