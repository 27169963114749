import React from 'react';
import { connect } from 'react-redux';

class PaginaConfiguracoes extends React.Component {
  render () {
    return (
      <div></div>
    );
  }
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginaConfiguracoes);