import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import HeaderForm from '../../HeaderForm';

import {
  Paper, Grid, Typography, Table, Icon,
  TableBody, TableRow, TableCell, Chip
} from "@material-ui/core";

class TabDados extends Component {
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Dados do cupom"/>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
          {/* GERAL */}
            <Typography style={{marginTop: '1em'}} variant="h6">Geral</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Estabelecimento</TableCell>
                  <TableCell>
                  {(this.props.item.estabelecimento)
                    ? <Link className="andes-link" to={`/estabelecimentos/ver/${this.props.item.estabelecimento.id}`}>{this.props.item.estabelecimento.razao_social}</Link>
                    : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>{this.props.item.titulo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Função</TableCell>
                  <TableCell style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    {(this.props.item.funcao === 'parceria')
                      ? <>
                          {(this.props.item.funcao === 'parceria')
                              ? <> 
                                  <span>parceria com </span>
                                  <Icon style={{marginLeft: '.5em', marginRight: '.5em'}}>compare_arrows</Icon><Link className="andes-link" to={`/estabelecimentos/ver/${this.props.item.parceiro.id}`}>{this.props.item.parceiro.razao_social}</Link>
                              </>
                              : ''}
                      </>
                      : 'Interno'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>{(this.props.item.tipo === 'percentual') ? 'Percentual' : 'Valor'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Perc/Valor</TableCell>
                  <TableCell>{(this.props.item.tipo === 'percentual') ? `${this.props.item.percentual}%` : `R$${this.props.item.valor}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data de início</TableCell>
                  <TableCell>{moment(this.props.item.inicio_validade).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data final</TableCell>
                  <TableCell>{(!this.props.item.nao_expira) ? moment(this.props.item.final_validade).format('DD/MM/YYYY') : 'indefinido (não expira)'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Quantidade</TableCell>
                  <TableCell>{(this.props.item.sem_limite_quantidade) ? <Chip label="Ilimitado" color="primary" /> : this.props.item.quantidade}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    item: state.vouchers.item
  }
}

export default connect(mapStateToProps)(TabDados);