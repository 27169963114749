import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';
import HeaderForm from '../../HeaderForm';
import { Paper, Typography, Grid,
  Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import * as moment from 'moment';
import {
  ResponsiveContainer, PieChart, Pie, Cell,
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

import { buscarItem } from '../../../store/ducks/estatisticasVouchers';

const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
const cores = ['#16213b', '#594b9e', '#00C49F', '#FFBB28', '#FF8042'];

class TabEstatisticas extends Component {
  componentDidMount() {
    if (this.props.voucher && this.props.voucher.id) {
      this.props.buscarItem(this.props.voucher.id);
    }
  }
  renderUltimosResgates = () => {
    if(this.props.estatisticas && this.props.estatisticas.resgates) {
      return this.props.estatisticas.resgates.map((resgate, index) => {
        return (
          <TableRow key={index}>
            <TableCell padding="none">{index + 1}</TableCell>
            <TableCell>{resgate.usuario.nome}</TableCell>
            <TableCell>{resgate.usuario.email}</TableCell>
            <TableCell padding="none">{moment(resgate.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
          </TableRow>
        );
      });
    } else {
      return (
        <TableRow>
          <TableCell colSpan={4}>Nenhum resgate realizado</TableCell>
        </TableRow>
      )
    }
  }

  renderTopUsuarios = () => {
    if(this.props.estatisticas && this.props.estatisticas.usuarios) {
      return this.props.estatisticas.usuarios.map((usuario, index) => {
        return (
          <TableRow key={index}>
            <TableCell padding="none">{index + 1}</TableCell>
            <TableCell>{usuario.usuario.nome}</TableCell>
            <TableCell>{usuario.usuario.email}</TableCell>
            <TableCell padding="none">{usuario.quantidade}</TableCell>
          </TableRow>
        );
      });
    }
  }

  renderHistorico = () => {
    const itensGrafico = [];
    if(this.props.estatisticas && this.props.estatisticas.historico) {
      const { historico } = this.props.estatisticas;
      historico.map(dia => {
        itensGrafico.push({
          name: moment(dia.dia).format('D/M/Y'),
          value: parseInt(dia.quantidade)
        });
      });
      return (
        <ResponsiveContainer>
          <AreaChart
            data={itensGrafico}
            margin={{
              top: 10, right: 30, left: 0, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="value" stroke="#594b9e" fill="#594b9e" />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
  }

  renderTopDias = () => {
    const itensGrafico = [];
    if(this.props.estatisticas && this.props.estatisticas.dias) {
      const { dias } = this.props.estatisticas;
      let total = 0;
      dias.map(dia => {
        total += parseInt(dia.quantidade);
        itensGrafico.push({name: diasDaSemana[parseInt(dia.dia_semana)], value: parseInt(dia.quantidade)});
      });
      return (
        <div style={{width: '100%', height: 'calc(320px - 2em)', padding: '1em'}}>
          <ResponsiveContainer>
            <PieChart>
              <Pie isAnimationActive={false} dataKey="value" data={itensGrafico} fill="#16213b"
              cx="50%" cy="50%" outerRadius={100}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text
                    x={x} y={y} fill="#16213b"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central">
                    {itensGrafico[index].name} ({Math.ceil((100*value)/total)}%)
                  </text>
                );
                }}>
                  {
                    itensGrafico.map((entry, index) => <Cell fill={cores[index % cores.length]}/>)
                  }
                </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    }
  }

  renderTopCidades = () => {
    const itensGrafico = [];
    if(this.props.estatisticas && this.props.estatisticas.cidades) {
      const { cidades } = this.props.estatisticas;
      let total = 0;
      cidades.map(cidade => {
        total += parseInt(cidade.quantidade);
        return itensGrafico.push({name: cidade.cidade, value: parseInt(cidade.quantidade)})
      });
      
      return (
        <div style={{width: '100%', height: 'calc(320px - 2em)', padding: '1em'}}>
          <ResponsiveContainer>
            <PieChart>
              <Pie isAnimationActive={false} dataKey="value" data={itensGrafico} fill="#16213b"
              cx="50%" cy="50%" outerRadius={100}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text
                    x={x} y={y} fill="#16213b"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central">
                    {itensGrafico[index].name} ({Math.ceil((100*value)/total)}%)
                  </text>
                );
                }}>
                  {
                    itensGrafico.map((entry, index) => <Cell fill={cores[index % cores.length]}/>)
                  }
                </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    }
  }

  render () {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Estatísticas do prêmio"/>
        {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
        {/* CONVERSÕES */}
        <Typography style={{marginTop: '1em'}} variant="h6">Conversões</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Total de resgates realizados</TableCell>
              <TableCell><strong>{(this.props.estatisticas) ? this.props.estatisticas.totalResgates : 0}</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Resgates realizados hoje</TableCell>
              <TableCell><strong>{(this.props.estatisticas) ? this.props.estatisticas.resgatesHoje : 0}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{display: 'block'}}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} style={{height: '240px'}}>
              <Typography style={{marginTop: '1em'}} variant="h6">Histórico recente</Typography>
              {this.renderHistorico()}
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{height: '320px', marginTop: '1em'}}>
              <Typography style={{marginTop: '1em'}} variant="h6">Cidades</Typography>
              {this.renderTopCidades()}
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{height: '320px'}}>
              <Typography style={{marginTop: '1em'}} variant="h6">Dias da semana</Typography>
              {this.renderTopDias()}
            </Grid>
          </Grid>
        </div>
        {/* ÚLTIMAS PONTUAÇÕES */}
        <Typography style={{marginTop: '1em'}} variant="h6">Últimos resgates</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell padding="none">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderUltimosResgates()}
          </TableBody>
        </Table>
        {/* TOP USUÁRIOS */}
        <Typography style={{marginTop: '1em'}} variant="h6">Top usuários</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell padding="none">Resgates</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderTopUsuarios()}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    voucher: state.vouchers.item,
    estatisticas: state.estatisticasVouchers.item,
    erro: state.estatisticasVouchers.erro,
    carregando: state.vouchers.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
    buscarItem: (id) => dispatch(buscarItem(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabEstatisticas);