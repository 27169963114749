import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  Card, CardContent, Grid, Typography, Divider,
  Table, TableBody, TableRow, TableCell, Button, CircularProgress
} from '@material-ui/core';

import {
  alterarPagina,
  adicionarSelecao,
  buscarTodos,
  removerSelecao,
  selecionarTodos,
  desselecionarTodos,
  definirNovoItem,
  remover,
  iniciarEditar,
  definirItem
} from '../../../store/ducks/estabelecimentos';

class ListaEstabelecimentos extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }
  // INFINITE SCROLL PARA A VIEW EM LISTA
  handleScrolling = () => {
    const wrappedElement = document.getElementById('listaDados');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      if (this.props.paginas > this.props.pagina && !this.props.carregando) {
        this.props.alterarPagina(this.props.pagina + 1);
        this.props.buscarTodos(this.props.pagina, this.props.qtde);
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  mostrarConfirmacaoRemover = (estabelecimento) => {
    this.refs.confirmacaoRemover.mostrar(estabelecimento);
  }
  mostrar = (estabelecimento) => {
    this.props.definirItem(estabelecimento);
    this.props.irParaMostrar(estabelecimento.id);
  }
  render() {
    return (
      <div id="listaDados">
        {(this.props.carregando && this.props.pagina === 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {this.props.estabelecimentos.map((estabelecimento, i) => {
          return (
            <Card key={i} raised style={{marginBottom: '.5em', padding: '.5em'}}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5" style={{textTransform: 'uppercase'}}>{estabelecimento.razao_social}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    {estabelecimento.nome_fantasia}
                  </Grid>
                </Grid>
                <Divider style={{margin: '1em 0'}} />
                <Grid container>
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell><strong>Tipo</strong></TableCell>
                          <TableCell>{(estabelecimento.tipo === 'pessoa_fisica') ? 'PF' : 'PJ'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Cidade</strong></TableCell>
                          <TableCell>{estabelecimento.cidade}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Estado</strong></TableCell>
                          <TableCell>{estabelecimento.estado}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button onClick={e => this.mostrar(estabelecimento)} variant="outlined" size="small" color="primary">Detalhes</Button>
                    <Button onClick={e => this.props.iniciarEditar(estabelecimento)} variant="outlined" size="small" color="primary">Editar</Button>
                    <Button 
                      onClick={e => this.mostrarConfirmacaoRemover(estabelecimento)}
                      variant="outlined"
                      size="small"
                      color="secondary">Remover</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {/* LOADER MAIS */}
        {(this.props.carregando && this.props.pagina > 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {/* CONFIRMAÇÃO */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.remover(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      estabelecimentos: state.estabelecimentos.estabelecimentos,
      selecionados: state.estabelecimentos.selecionados,
      paginas: state.estabelecimentos.paginas,
      pagina: state.estabelecimentos.pagina,
      qtde: state.estabelecimentos.qtde,
      carregando: state.estabelecimentos.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
      alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
      adicionarSelecao: (estabelecimento) => dispatch(adicionarSelecao(estabelecimento)),
      removerSelecao: (estabelecimento) => dispatch(removerSelecao(estabelecimento)),
      selecionarTodos: () => dispatch(selecionarTodos()),
      desselecionarTodos: () => dispatch(desselecionarTodos()),
      remover: (estabelecimento) => dispatch(remover(estabelecimento)),
      buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
      definirNovoItem: (estabelecimento) => dispatch(definirNovoItem(estabelecimento)),
      definirItem: (estabelecimento) => dispatch(definirItem(estabelecimento)),
      irParaMostrar: (id) => dispatch(push(`/estabelecimentos/ver/${id}`)),
      iniciarEditar: (estabelecimento) => dispatch(iniciarEditar(estabelecimento))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaEstabelecimentos);