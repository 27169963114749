import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as queryString from 'query-string';
import { AppBar, Tabs, Tab, Button, LinearProgress, ButtonGroup } from '@material-ui/core';

import DrawerAdmins from '../componentes/adminsEstabelecimentos/DrawerAdicionar';
import DrawerEstabelecimentos from '../componentes/estabelecimentos/DrawerAdicionar';
import DrawerProdutos from '../componentes/produtos/DrawerAdicionar';
import DrawerPromocoes from '../componentes/promocoes/DrawerAdicionar';
import DrawerVouchers from '../componentes/vouchers/DrawerAdicionar';
import TabDashboard from '../componentes/estabelecimentos/TabDashboard';
import TabDados from '../componentes/estabelecimentos/TabDados';
import TabImagens from '../componentes/estabelecimentos/TabImagens';
import TabAdmins from '../componentes/estabelecimentos/TabAdmins';
import TabPromocoes from '../componentes/estabelecimentos/TabPromocoes';
import TabVouchers from '../componentes/estabelecimentos/TabVouchers';

import {
  alterarTab,
  buscarItem,
  iniciarEditar
} from '../store/ducks/estabelecimentos';

import {
  alterarTitulo
} from '../store/ducks/header';

import { definirItens as definirAdmins } from '../store/ducks/adminsEstabelecimentos';
import { definirItens as definirProdutos } from '../store/ducks/produtos';
import { definirItens as definirPromocoes } from '../store/ducks/promocoes';
import { definirItens as definirVouchers } from '../store/ducks/vouchers';
import TabProdutos from '../componentes/estabelecimentos/TabProdutos';

class PaginaDetalhesEstabelecimento extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  
  componentDidMount() {
    if (!this.props.item.id) {
      this.props.buscarItem(this.props.match.params.id);
    }
    this.alterarTabPelaQueryString();
    this.inscreverAlteracoesRota();
  }

  componentDidUpdate() {
    this.props.definirAdmins(this.props.item.admins);
    this.props.definirProdutos(this.props.item.produtos);
    this.props.definirPromocoes(this.props.item.promocoes);
    this.props.definirVouchers(this.props.item.vouchers);
    if (this.props.item && this.props.item.razao_social) {
      this.props.alterarTitulo(this.props.item.razao_social);
    }
  }

  inscreverAlteracoesRota = () => {
    this.props.history.listen((location, action) => {
      if (action === 'PUSH' || action === 'POP') {
        setTimeout(() => {
          // this.props.buscarItem(this.props.match.params.id);
          this.alterarTabPelaQueryString();
        });
      }
    })
  }

  alterarTabPelaQueryString = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.tab && params.tab != this.props.tab) {
      switch (params.tab) {
        case 'dashboard': { return this.props.alterarTab(0) }
        case 'dados': { return this.props.alterarTab(1) }
        case 'imagens': { return this.props.alterarTab(2) }
        case 'admins': { return this.props.alterarTab(3) }
        case 'produtos': { return this.props.alterarTab(4) }
        case 'promocoes': { return this.props.alterarTab(5) }
        case 'vouchers': { return this.props.alterarTab(6) }
        default: return;
      }
    }
  }

  alterarTab = (tab) => {
    const pathname = this.props.location.pathname;
    switch (tab) {
      case 0: {
        return this.props.history.push({ pathname, search: `?tab=dashboard` });
      }
      case 1: {
        return this.props.history.push({ pathname, search: `?tab=dados` });
      }
      case 2: {
        return this.props.history.push({ pathname, search: `?tab=imagens` });
      }
      case 3: {
        return this.props.history.push({ pathname, search: `?tab=admins` });
      }
      case 4: {
        return this.props.history.push({ pathname, search: `?tab=produtos` });
      }
      case 5: {
        return this.props.history.push({ pathname, search: `?tab=promocoes` });
      }
      case 6: {
        return this.props.history.push({ pathname, search: `?tab=vouchers` });
      }
      default: return;
    }
  }

  render() {
    return (
      <div>
        <ButtonGroup variant="contained" style={{marginBottom: '1em'}}>
          <Button
            style={{marginBottom: '0'}}
            component={Link}
            to={`/estabelecimentos`}
            color="default">
              Voltar
          </Button>
          <Button
            style={{marginBottom: '0'}}
            onClick={e => this.props.iniciarEditar(this.props.item)}
            color="primary">
              Editar
          </Button>
        </ButtonGroup>
        {/* TABS */}
        <AppBar position="static">
          <Tabs 
            variant="scrollable"
            scrollButtons="auto"
            value={this.props.tab}
            onChange={(e, val) => this.alterarTab(val)}>
            <Tab label="Dashboard" />
            <Tab label="Dados" />
            <Tab label="Imagens" />
            <Tab label="Admins" />
            <Tab label="Prêmios" />
            <Tab label="Promoções" />
            <Tab label="Cupons" />
          </Tabs>
        </AppBar>
        {/* LOADER */}
        {(this.props.carregando) ? <LinearProgress style={{position: 'absolute', width: 'calc(100% - 243px - 3em)'}} color="secondary" /> : ''}
        {/* TABS */}
        {(this.props.item && this.props.item.id)
          ? <div>
              {this.props.tab === 0 && <TabDashboard />}
              {this.props.tab === 1 && <TabDados />}
              {this.props.tab === 2 && <TabImagens />}
              {this.props.tab === 3 && <TabAdmins />}
              {this.props.tab === 4 && <TabProdutos />}
              {this.props.tab === 5 && <TabPromocoes />}
              {this.props.tab === 6 && <TabVouchers />}
            </div>
          : ''}
        {/* ADICIONAR ADMIN */}
        <DrawerAdmins />
        <DrawerEstabelecimentos />
        <DrawerProdutos />
        <DrawerPromocoes />
        <DrawerVouchers />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.estabelecimentos.carregando,
    tab: state.estabelecimentos.tab,
    item: state.estabelecimentos.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarTitulo: (titulo) => dispatch(alterarTitulo(titulo)),
    alterarTab: (tab) => dispatch(alterarTab(tab)),
    buscarItem: (id) => dispatch(buscarItem(id)),
    definirAdmins: (itens) => dispatch(definirAdmins(itens)),
    definirProdutos: (itens) => dispatch(definirProdutos(itens)),
    definirPromocoes: (itens) => dispatch(definirPromocoes(itens)),
    definirVouchers: (itens) => dispatch(definirVouchers(itens)),
    iniciarEditar: (item) => dispatch(iniciarEditar(item))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaDetalhesEstabelecimento));