import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoPerguntaFrequenteSite from '../../servicos/ServicoPerguntaFrequenteSite';
import { Types } from '../ducks/perguntasFrequentesSite';
const servico = new ServicoPerguntaFrequenteSite();

function* salvar (action) {
    if (action.pergunta.id) {
        try {
            const pergunta = yield call([servico, servico.atualizarPergunta], action.pergunta.id, action.pergunta);
            yield put({type: Types.SALVAR_SUCESSO, pergunta: pergunta.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const pergunta = yield call([servico, servico.adicionarPergunta], action.pergunta);
            yield put({type: Types.SALVAR_SUCESSO, pergunta});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* reordenar (action) {
    try {
        yield call([servico, servico.reordenarPerguntas], action.id, action.destino);
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REORDENAR_ERRO, erro: e.message});
     }
}

function* buscarItem (action) {
    try {
        const pergunta = yield call([servico, servico.buscarPerguntaPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, pergunta});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const perguntas = yield call([servico, servico.buscarPerguntas]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, perguntas});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerPergunta], action.pergunta.id);
        yield put({type: Types.REMOVER_SUCESSO, pergunta: action.pergunta});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaPerguntas () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
    yield takeEvery(Types.REORDENAR_REQUEST, reordenar);
}

export default sagaPerguntas;