import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    Chip, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress,
    Button, Menu, MenuItem
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/produtos';

class TabelaProdutos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorMenu: null,
            produto: null
        };
    }
    alterarSelecao = (e, produto) => {
        return e.target.checked
            ? this.props.adicionarSelecao(produto)
            : this.props.removerSelecao(produto);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    iniciarRemover = (e) => {
        if (e) e.preventDefault();
        this.setState({anchorMenu: null}, () => {
            this.mostrarConfirmacaoRemover(this.state.produto);
        });
    }
    mostrarConfirmacaoRemover = (produto) => {
        this.refs.confirmacaoRemover.mostrar(produto);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    editar = (e) => {
        if (e) e.preventDefault();
        this.props.iniciarEditar(this.state.produto);
        this.setState({anchorMenu: null});
    }
    mostrar = (produto, e = null) => {
        if (e) e.preventDefault();
        this.props.definirItem(produto);
        this.props.irParaMostrar(produto.id);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.produtos.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Tipo Pontuação</TableCell>
                                <TableCell>Qtde/Pontos</TableCell>
                                <TableCell>Ativo</TableCell>
                                <TableCell>Estab.</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.produtos.map((produto, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === produto.id)}
                                                onChange={(e) => this.alterarSelecao(e, produto)}
                                                value={`${produto.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>
                                            <Link onClick={e => this.mostrar(produto, e)} className="andes-link" to={`/produtos/ver/${produto.id}`}>{produto.nome}</Link>
                                        </TableCell>
                                        <TableCell>{(produto.tipo_pontuacao === 'pontos') ? 'Pontuação' : 'Quantidade'}</TableCell>
                                        <TableCell>{(produto.tipo_pontuacao === 'pontos') ? produto.pontos : produto.quantidade}</TableCell>
                                        <TableCell>{(produto.ativo ? <Chip label="Ativa" color="primary" /> : '-')}</TableCell>
                                        <TableCell>
                                            {(produto.estabelecimento)
                                                ? <Link className="andes-link" to={`/estabelecimentos/ver/${produto.estabelecimento.id}?tab=produtos`}>{produto.estabelecimento.razao_social}</Link>
                                                : '-'}
                                        </TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                            <Button
                                                className="mb-0"
                                                variant="contained"
                                                color="primary"
                                                disabled={produto.removendo}
                                                size="small" onClick={e => this.mostrar(produto)}>
                                                Detalhes
                                                {(produto.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '2em', top: '.2em'}} />
                                                    : ''}
                                            </Button>
                                            <IconButton
                                                size="small"
                                                color="default"
                                                onClick={e => this.setState({anchorMenu: e.currentTarget, produto})}>
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.produtos.length)
                                ? <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum produto cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={6} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                {/* MENU ITEM */}
                <Menu id="menu-produtos"
                    anchorEl={this.state.anchorMenu}
                    keepMounted
                    open={Boolean(this.state.anchorMenu)}
                    onClose={e => this.setState({anchorMenu: null})}>
                        <MenuItem onClick={(e) => this.editar(e)}>Editar</MenuItem>
                        <MenuItem onClick={(e) => this.iniciarRemover(e)}>Remover</MenuItem>
                </Menu>
                {/* MODAIS */}
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        produtos: state.produtos.itens,
        selecionados: state.produtos.selecionados,
        paginas: state.produtos.paginas,
        pagina: state.produtos.pagina,
        qtde: state.produtos.qtde,
        carregando: state.produtos.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (produto) => dispatch(adicionarSelecao(produto)),
        removerSelecao: (produto) => dispatch(removerSelecao(produto)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (produto) => dispatch(remover(produto)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (produto) => dispatch(definirItem(produto)),
        irParaMostrar: (id) => dispatch(push(`/produtos/ver/${id}`)),
        iniciarEditar: (produto) => dispatch(iniciarEditar(produto))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaProdutos);