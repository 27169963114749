import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Divider, Typography } from '@material-ui/core';
import Breadcrumbs from '../Breadcrumbs';
import '../../../css/HeaderPagina.css';

import {
  alterarTitulo,
  adicionarBreadcrumb,
  limparBreadcrumbs
} from '../../../store/ducks/header';

function buscarTituloPeloPath (path) {
  if (path.indexOf('/editar/') > -1 || path.indexOf('/ver/') > -1) {
    const arrPath = path.split('/');
    arrPath.pop();
    path = arrPath.join('/');
  }
  switch(path) {
    case '/': return 'Dashboard';
    case '/me': return 'Perfil';
    case '/configuracoes': return 'Configurações';
    case '/categorias-estabelecimentos': return 'Categorias de estabelecimentos';
    case '/estabelecimentos': return 'Estabelecimentos';
    case '/estabelecimentos/ver': return 'Detalhes do estabelecimento';
    case '/planos': return 'Planos';
    case '/produtos': return 'Prémios';
    case '/produtos/ver': return 'Detalhes do prêmio';
    case '/promocoes': return 'Promoções';
    case '/promocoes/ver': return 'Detalhes do promoções';
    case '/vouchers': return 'Cupons de desconto';
    case '/vouchers/ver': return 'Detalhes do cupom';
    case '/usuarios': return 'Usuários';
    case '/admins': return 'Administradores';
    case '/funcoes': return 'Funções';
    case '/configuracoes-site': return 'Configurações do site';
    case '/contatos-site': return 'Contatos recebidos pelo site';
    case '/inscricoes-site': return 'Inscrições na newsletter';
    case '/depoimentos-site': return 'Depoimentos do site';
    case '/slides-site': return 'Slides do site';
    case '/textos-site': return 'Textos do site';
    case '/perguntas-frequentes-site': return 'Perguntas frequentes do site';
    case '/vantagens-site': return 'Vantagens do app';
    default: return 'Pontua';
  }
}
function buscarBreadcrumbsPeloPath (path) {
  if (path.indexOf('/editar/') > -1 || path.indexOf('/ver/') > -1) {
    const arrPath = path.split('/');
    arrPath.pop();
    path = arrPath.join('/');
  }
  switch(path) {
    case '/': return [];
    case '/configuracoes': return [
      {nome: 'Configuracoes', endereco: path}
    ]
    case '/me': return [
      {nome: 'Perfil', endereco: path}
    ]
    case '/categorias-estabelecimentos': return [
      {nome: 'Categorias de estabelecimentos', endereco: path}
    ]
    case '/estabelecimentos': return [
      {nome: 'Estabelecimentos', endereco: path}
    ]
    case '/estabelecimentos/ver': return [
      {nome: 'Estabelecimentos', endereco: '/estabelecimentos'},
      {nome: 'Detalhes', endereco: path}
    ]
    case '/planos': return [
      {nome: 'Planos', endereco: path}
    ]
    case '/produtos': return [
      {nome: 'Prêmios', endereco: path}
    ]
    case '/produtos/ver': return [
      {nome: 'Prêmios', endereco: '/produtos'},
      {nome: 'Detalhes', endereco: path}
    ]
    case '/promocoes': return [
      {nome: 'Promoções', endereco: path}
    ]
    case '/vouchers': return [
      {nome: 'Cupons de desconto', endereco: path}
    ]
    case '/vouchers/ver': return [
      {nome: 'Cupons de desconto', endereco: '/vouchers'},
      {nome: 'Detalhes', endereco: path}
    ]
    case '/usuarios': return [
      {nome: 'Usuários', endereco: path}
    ]
    case '/admins': return [
      {nome: 'Administradores', endereco: path}
    ]
    case '/funcoes': return [
      {nome: 'Funções', endereco: path}
    ]
    case '/contatos-site': return [
      {nome: 'Contatos recebidos pelo site', endereco: path}
    ]
    case '/inscricoes-site': return [
      {nome: 'Inscrições na newsletter', endereco: path}
    ]
    case '/configuracoes-site': return [
      {nome: 'Configurações do site', endereco: path}
    ]
    case '/depoimentos-site': return [
      {nome: 'Depoimentos do site', endereco: path}
    ]
    case '/slides-site': return [
      {nome: 'Slides do site', endereco: path}
    ]
    case '/textos-site': return [
      {nome: 'Textos do site', endereco: path}
    ]
    case '/perguntas-frequentes-site': return [
      {nome: 'Perguntas frequentes do site', endereco: path}
    ]
    case '/vantagens-site': return [
      {nome: 'Vantagens do app', endereco: path}
    ]
    default: return [];
  }
}

class HeaderPagina extends React.Component {
  componentDidMount() {
    const path = this.props.history.location.pathname;
    this.props.alterarTitulo(buscarTituloPeloPath(path));
    this.props.limparBreadcrumbs();
    buscarBreadcrumbsPeloPath(path).map((breadcrumb) => {
      return this.props.adicionarBreadcrumb(breadcrumb);
    })
    this.inscreverAlteracoesRota();
  }

  inscreverAlteracoesRota = () => {
    this.props.history.listen((location, action) => {
      if (action === 'PUSH' || action === 'POP') {
        const path = location.pathname;
        this.props.alterarTitulo(buscarTituloPeloPath(path));
        this.props.limparBreadcrumbs();
        buscarBreadcrumbsPeloPath(path).map((breadcrumb) => {
          return this.props.adicionarBreadcrumb(breadcrumb);
        })
      }
    })
  }

  render() {
    return (
      <>
        <Breadcrumbs home itens={this.props.breadcrumbs}  />
        <Typography variant="h4" gutterBottom>{this.props.titulo}</Typography>
        <Divider style={{width: '100%', marginBottom: '1em'}}/>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    titulo: state.header.titulo,
    breadcrumbs: state.header.breadcrumbs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarTitulo: titulo => dispatch(alterarTitulo(titulo)),
    adicionarBreadcrumb: breadcrumb => dispatch(adicionarBreadcrumb(breadcrumb)),
    limparBreadcrumbs: () => dispatch(limparBreadcrumbs())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderPagina));