import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import Servico from '../../servicos/ServicoAdmin';
import { Types } from '../ducks/admins';
const servico = new Servico();

function* salvar (action) {
    if (action.admin.id) {
        try {
            const admin = yield call([servico, servico.atualizarAdmin], action.admin.id, action.admin);
            yield put({type: Types.SALVAR_SUCESSO, admin: admin.data, editar: true});
        } catch (e) {
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const admin = yield call([servico, servico.adicionarAdmin], action.admin);
            yield put({type: Types.SALVAR_SUCESSO, admin});
        } catch (e) {
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerAdmin], action.admin.id);
        yield put({type: Types.REMOVER_SUCESSO, admin: action.admin});
    } catch (e) {
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const admin = yield call([servico, servico.buscarAdminPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, admin});
     } catch (e) {
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const admins = yield call([servico, servico.buscarAdmins], action.qtde, action.pagina);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, admins});
     } catch (e) {
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
}

export default saga;