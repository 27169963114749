import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoPromocao';
import ServicoImagem from '../../servicos/ServicoImagemPromocao';
import { Types as PromocoesTypes } from '../ducks/promocoes';
import { Types as PesquisaTypes } from '../ducks/pesquisa';
const servico = new Servico();
const servicoImagem = new ServicoImagem();

function* definirPerfil (action) {
    try {
        yield call([servicoImagem, servicoImagem.definirComoPerfil], action.foto.id);
        yield put({type: PromocoesTypes.DEFINIR_PERFIL_SUCESSO, foto: action.foto});
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.DEFINIR_PERFIL_ERRO, erro: e.message});
    }
}

function* definirCapa (action) {
    try {
        yield call([servicoImagem, servicoImagem.definirComoCapa], action.foto.id);
        yield put({type: PromocoesTypes.DEFINIR_CAPA_SUCESSO, foto: action.foto});
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.DEFINIR_CAPA_ERRO, erro: e.message});
    }
}

function* removerPerfil (action) {
    try {
        yield call([servicoImagem, servicoImagem.removerPerfil], action.foto.id);
        yield put({type: PromocoesTypes.REMOVER_PERFIL_SUCESSO, foto: action.foto});
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.REMOVER_PERFIL_ERRO, erro: e.message});
    }
}

function* removerCapa (action) {
    try {
        yield call([servicoImagem, servicoImagem.removerCapa], action.foto.id);
        yield put({type: PromocoesTypes.REMOVER_CAPA_SUCESSO, foto: action.foto});
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.REMOVER_CAPA_ERRO, erro: e.message});
    }
}

function* enviarFoto (action) {
    try {
        const foto = yield call([servicoImagem, servicoImagem.enviarImagem], action.id, action.foto);
        yield put({type: PromocoesTypes.ENVIAR_FOTO_SUCESSO, foto});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.ENVIAR_FOTO_ERRO, erro: e.message});
    }
}

function* removerFoto (action) {
    try {
        yield call([servicoImagem, servicoImagem.removerImagem], action.foto.id);
        yield put({type: PromocoesTypes.REMOVER_FOTO_SUCESSO, foto: action.foto});
    } catch(e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.REMOVER_FOTO_ERRO, erro: e.message});
    }
}

function* salvar (action) {
    if (action.promocao.id) {
        try {
            const promocao = yield call([servico, servico.atualizarPromocao], action.promocao.id, action.promocao);
            yield put({type: PromocoesTypes.SALVAR_SUCESSO, promocao: promocao.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: PromocoesTypes.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const promocao = yield call([servico, servico.adicionarPromocao], action.promocao);
            yield put({type: PromocoesTypes.SALVAR_SUCESSO, promocao});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: PromocoesTypes.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerPromocao], action.promocao.id);
        yield put({type: PromocoesTypes.REMOVER_SUCESSO, promocao: action.promocao});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const promocao = yield call([servico, servico.buscarPromocaoPorId], action.id);
        yield put({type: PromocoesTypes.BUSCAR_ITEM_SUCESSO, promocao});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const promocoes = yield call([servico, servico.buscarPromocoes], action.qtde, action.pagina);
        yield put({type: PromocoesTypes.BUSCAR_TODOS_SUCESSO, promocoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* pesquisar (action) {
    try {
        const promocoes = yield call([servico, servico.pesquisarPromocoes], action.pesquisa, action.qtde, action.pagina);
        yield put({type: PromocoesTypes.PESQUISAR_SUCESSO, promocoes});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PromocoesTypes.PESQUISAR_ERRO, erro: e.message});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     }
}

function* saga () {
    yield takeLatest(PromocoesTypes.DEFINIR_PERFIL_REQUEST, definirPerfil);
    yield takeLatest(PromocoesTypes.DEFINIR_CAPA_REQUEST, definirCapa);
    yield takeLatest(PromocoesTypes.REMOVER_PERFIL_REQUEST, removerPerfil);
    yield takeLatest(PromocoesTypes.REMOVER_CAPA_REQUEST, removerCapa);
    yield takeLatest(PromocoesTypes.ENVIAR_FOTO_REQUEST, enviarFoto);
    yield takeLatest(PromocoesTypes.REMOVER_FOTO_REQUEST, removerFoto);
    yield takeLatest(PromocoesTypes.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(PromocoesTypes.SALVAR_REQUEST, salvar);
    yield takeLatest(PromocoesTypes.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(PromocoesTypes.PESQUISAR_REQUEST, pesquisar);
    yield takeEvery(PromocoesTypes.REMOVER_REQUEST, remover);
}

export default saga;