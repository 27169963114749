const stateInicial = {
    carregando: false,
    desabilitado: false,
    erro: '',
    layout: 'tabela',
    pagina: 1,
    paginas: 1,
    qtde: 25,
    tab: 0,
    usuarios: [],
    total: 0,
    ordem: 'alfabetica',
    selecionados: [],
}

export const Types = {
    // ALTERACOES
    ALTERAR_LAYOUT: 'usuarios/ALTERAR_LAYOUT',
    ALTERAR_PAGINA: 'usuarios/ALTERAR_PAGINA',
    ALTERAR_QTDE: 'usuarios/ALTERAR_QTDE',
    ALTERAR_ORDEM: 'usuarios/ALTERAR_ORDEM',
    ALTERAR_TAB: 'usuarios/ALTERAR_TAB',
    ALTERAR_ITEM: 'usuarios/ALTERAR_ITEM',
    ADICIONAR_SELECAO: 'usuarios/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'usuarios/REMOVER_SELECAO',
    SELECIONAR_TODOS: 'usuarios/SELECIONAR_TODOS',
    DESSELECIONAR_TODOS: 'usuarios/DESSELECIONAR_TODOS',
    DEFINIR_ITEM: 'usuarios/DEFINIR_ITEM',
    // SALVAR
    SALVAR_REQUEST: 'usuarios/SALVAR_REQUEST',
    SALVAR_SUCESSO: 'usuarios/SALVAR_SUCESSO',
    SALVAR_ERRO: 'usuarios/SALVAR_ERRO',
    // PESQUISAR
    PESQUISAR_REQUEST: 'usuarios/PESQUISAR_REQUEST',
    PESQUISAR_SUCESSO: 'usuarios/PESQUISAR_SUCESSO',
    PESQUISAR_ERRO: 'usuarios/PESQUISAR_ERRO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'usuarios/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'usuarios/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'usuarios/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'usuarios/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'usuarios/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'usuarios/BUSCAR_ITEM_ERRO',
    // REMOVER
    REMOVER_REQUEST: 'usuarios/REMOVER_REQUEST',
    REMOVER_SUCESSO: 'usuarios/REMOVER_SUCESSO',
    REMOVER_ERRO: 'usuarios/REMOVER_ERRO'
}

export default function reducer (state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_PAGINA: {
            if (!isNaN(action.pagina)) {
                return {...state, pagina: action.pagina}
            }
            return {...state};
        }
        case Types.ALTERAR_QTDE: {
            if (!isNaN(action.qtde)) {
                return {...state, qtde: action.qtde, pagina: 1}
            }
            return {...state};
        }
        case Types.ALTERAR_ORDEM: {
            return {...state, ordem: action.ordem, pagina: 1}
        }
        case Types.ALTERAR_LAYOUT: {
            if (['tabela', 'lista'].indexOf(action.layout) > -1) {
                return {...state, layout: action.layout};
            }
            return {...state};
        }
        case Types.ALTERAR_TAB: {
            if(!isNaN(action.tab)) {
                return {...state, tab: action.tab};
            }
            return {...state};
        }
        case Types.ALTERAR_ITEM: {
            const item = {...state.item}
            item[action.campo] = action.valor;
            return {...state, item}
        }
        case Types.DEFINIR_ITEM: {
            return {
                ...state,
                item: action.item
            }
        }
        case Types.SALVAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.SALVAR_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                usuarios: [...state.usuarios, action.usuario]
            }
        }
        case Types.SALVAR_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.PESQUISAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                pagina: 1,
                qtde: 50,
                erro: ''
            }
        }
        case Types.PESQUISAR_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                usuarios: action.usuarios.data,
                total: action.usuarios.meta.total,
                paginas: action.usuarios.meta.last_page
            }
            return novoState;
        }
        case Types.PESQUISAR_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.usuario
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                usuarios: (state.layout === 'lista')
                    ? [...state.usuarios].concat(action.usuarios.data)
                    : action.usuarios.data,
                total: action.usuarios.meta.total,
                paginas: action.usuarios.meta.last_page
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.usuario);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(usuario => usuario.id !== action.usuario.id);
            return {...state, selecionados};
        }
        case Types.SELECIONAR_TODOS: {
            const selecionados = [...state.usuarios];
            return {...state, selecionados};
        }
        case Types.DESSELECIONAR_TODOS: {
            const selecionados = [];
            return {...state, selecionados};
        }
        case Types.REMOVER_REQUEST: {
            const usuarios = [...state.usuarios];
            usuarios.map((usuario, i) => {
                if (usuario.id === action.usuario.id) {
                    return usuarios[i].removendo = true;
                }
                return null;
            });
            return {...state, usuarios};
        }
        case Types.REMOVER_SUCESSO: {
            let usuarios = [...state.usuarios];
            let selecionados = [...state.selecionados];
            usuarios = usuarios.filter((usuario) => usuario.id !== action.usuario.id)
            selecionados = selecionados.filter((usuario) => usuario.id !== action.usuario.id)
            return {...state, usuarios, selecionados};
        }
        case Types.REMOVER_ERRO: {
            let usuarios = [...state.usuarios];
            usuarios.map((usuario, i) => {
                return usuarios[i].removendo = false;
            });
            return {...state, erro: action.erro, usuarios};
        }
        default: return state;
    }
}

export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
    return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarOrdem(ordem) {
    return { type: Types.ALTERAR_ORDEM, ordem }
}

export function alterarPagina(pagina) {
    return { type: Types.ALTERAR_PAGINA, pagina }
}

export function alterarTab(tab) {
    return { type: Types.ALTERAR_TAB, tab }
}

export function alterarItem(campo, valor) {
    return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function pesquisar(pesquisa, escolaridade = null, sexo = null) {
    return { type: Types.PESQUISAR_REQUEST, pesquisa, escolaridade, sexo }
}

export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25, ordem = null) {
    return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde, ordem }
}

export function adicionarSelecao(usuario) {
    return { type: Types.ADICIONAR_SELECAO, usuario }
}

export function removerSelecao(usuario) {
    return { type: Types.REMOVER_SELECAO, usuario }
}

export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(usuario) {
    return { type: Types.REMOVER_REQUEST, usuario }
}

export function definirNovoItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(usuario) {
    return { type: Types.SALVAR_REQUEST, usuario }
}

export function definirItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
}