import axios from 'axios';
class ServicoConfiguracaoSite {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/configuracoes-site` : 'https://api.pontuafidelidade.com.br/api/admin/configuracoes-site';

  constructor() {
    this.buscarToken();
    this.textos = (localStorage.getItem('configuracoes')) ? JSON.parse(localStorage.getItem('configuracoes')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarConfiguracaoPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarConfiguracoes() {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  atualizarConfiguracao(id, configuracao) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: configuracao,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoConfiguracaoSite;