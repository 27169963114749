import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    Button, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress,
    Menu, MenuItem, TableSortLabel
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    alterarOrdem,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/estabelecimentos';

class TabelaEstabelecimentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorMenu: null,
            estabelecimento: null
        };
    }
    alterarSelecao = (e, estabelecimento) => {
        return e.target.checked
            ? this.props.adicionarSelecao(estabelecimento)
            : this.props.removerSelecao(estabelecimento);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    iniciarRemover = (e) => {
        if (e) e.preventDefault();
        this.setState({anchorMenu: null}, () => {
            this.mostrarConfirmacaoRemover();
        });
    }
    mostrarConfirmacaoRemover = () => {
        this.refs.confirmacaoRemover.mostrar(this.state.estabelecimento);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (estabelecimento, e) => {
        if (e) e.preventDefault();
        this.props.definirItem(estabelecimento);
        this.props.irParaMostrar(estabelecimento.id);
    }
    editar = (e) => {
        if (e) e.preventDefault();
        this.props.iniciarEditar(this.state.estabelecimento);
        this.setState({anchorMenu: null});
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.estabelecimentos.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'razao_social' || this.props.ordem === 'razao_social-desc'}
                                        direction={this.props.ordem === 'razao_social' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'razao_social') ? 'razao_social-desc' : 'razao_social');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Razão Social
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>CPF/CNPJ</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'cidade' || this.props.ordem === 'cidade-desc'}
                                        direction={this.props.ordem === 'cidade' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'cidade') ? 'cidade-desc' : 'cidade');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Cidade/UF
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Usuários</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'pontos' || this.props.ordem === 'pontos-desc'}
                                        direction={this.props.ordem === 'pontos' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'pontos') ? 'pontos-desc' : 'pontos');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Pontos
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell padding="none" align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.estabelecimentos.map((estabelecimento, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === estabelecimento.id)}
                                                onChange={(e) => this.alterarSelecao(e, estabelecimento)}
                                                value={`${estabelecimento.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>
                                            <a className="andes-link" href="/" onClick={e => this.mostrar(estabelecimento, e)}>{estabelecimento.razao_social}</a>
                                        </TableCell>
                                        <TableCell>{(estabelecimento.tipo === 'pessoa_juridica') ? 'PJ' : 'PF'}</TableCell>
                                        <TableCell className="text-nowrap">{(estabelecimento.tipo === 'pessoa_juridica') ? estabelecimento.cnpj : estabelecimento.cpf}</TableCell>
                                        <TableCell>{estabelecimento.cidade}/{estabelecimento.estado}</TableCell>
                                        <TableCell>{(estabelecimento.categoria) ? estabelecimento.categoria.nome : '-'}</TableCell>
                                        <TableCell>{estabelecimento.status}</TableCell>
                                        <TableCell>{(estabelecimento.qtde_usuarios) ? estabelecimento.qtde_usuarios : '0'}</TableCell>
                                        <TableCell>{estabelecimento.pontos}</TableCell>
                                        <TableCell align="right" padding="none" className="text-nowrap">
                                            <Button
                                                className="mb-0"
                                                variant="contained"
                                                color="primary"
                                                disabled={estabelecimento.removendo}
                                                size="small" onClick={e => this.mostrar(estabelecimento)}>
                                                Detalhes
                                                {(estabelecimento.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '2em', top: '.2em'}} />
                                                    : ''}
                                            </Button>
                                            <IconButton
                                                size="small"
                                                color="default"
                                                onClick={e => this.setState({anchorMenu: e.currentTarget, estabelecimento})}>
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.estabelecimentos.length)
                                ? <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum estabelecimento cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={6} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                {/* MENU ITEM */}
                <Menu id="menu-estabelecimentos"
                    anchorEl={this.state.anchorMenu}
                    keepMounted
                    open={Boolean(this.state.anchorMenu)}
                    onClose={e => this.setState({anchorMenu: null})}>
                        <MenuItem onClick={(e) => this.editar(e)}>Editar</MenuItem>
                        <MenuItem onClick={(e) => this.iniciarRemover(e)}>Remover</MenuItem>
                </Menu>
                {/* MODAIS */}
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        estabelecimentos: state.estabelecimentos.estabelecimentos,
        selecionados: state.estabelecimentos.selecionados,
        paginas: state.estabelecimentos.paginas,
        pagina: state.estabelecimentos.pagina,
        qtde: state.estabelecimentos.qtde,
        ordem: state.estabelecimentos.ordem,
        carregando: state.estabelecimentos.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        alterarOrdem: (ordem) => dispatch(alterarOrdem(ordem)),
        adicionarSelecao: (estabelecimento) => dispatch(adicionarSelecao(estabelecimento)),
        removerSelecao: (estabelecimento) => dispatch(removerSelecao(estabelecimento)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (estabelecimento) => dispatch(remover(estabelecimento)),
        buscarTodos: (pagina, qtde, ordem) => dispatch(buscarTodos(pagina, qtde, ordem)),
        definirItem: (estabelecimento) => dispatch(definirItem(estabelecimento)),
        irParaMostrar: (id) => dispatch(push(`/estabelecimentos/ver/${id}`)),
        iniciarEditar: (estabelecimento) => dispatch(iniciarEditar(estabelecimento))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaEstabelecimentos);