import axios from 'axios';
class ServicoAdmin {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/admins` : 'https://api.pontuafidelidade.com.br/api/admin/admins';

  constructor() {
    this.buscarToken();
    this.admins = (localStorage.getItem('admins')) ? JSON.parse(localStorage.getItem('admins')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarAdminPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarAdmins(qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'GET',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  pesquisarAdmins(pesquisa, qtde, pagina) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?pesquisa=${pesquisa}&page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'GET',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  adicionarAdmin(novoAdmin) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        data: novoAdmin,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

  atualizarAdmin(id, admin) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: admin,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

  removerAdmin(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      })
    });
  }

}

export default ServicoAdmin;