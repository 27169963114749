import axios from 'axios';
class ServicoPromocao {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/promocoes` : 'https://api.pontuafidelidade.com.br/api/admin/promocoes';

  constructor() {
    this.buscarToken();
    this.promocoes = (localStorage.getItem('promocoes')) ? JSON.parse(localStorage.getItem('promocoes')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarPromocaoPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarPromocoes(qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  buscarPromocoesEstabelecimento(idEstabelecimento, qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}/estabelecimento=${idEstabelecimento}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  pesquisarPromocoes(pesquisa, qtde, pagina) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?pesquisa=${pesquisa}&page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  adicionarPromocao(novaPromocao) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        data: novaPromocao,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  atualizarPromocao(id, promocao) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: promocao,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  removerPromocao(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoPromocao;