import React from 'react';
import { Snackbar, IconButton, Icon, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  }
});

class ErroPagina extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mostrar: false, mensagem: props.mensagem || 'Erro.' }
  }

  componentWillReceiveProps(props) {
    this.setState({
      mostrar: props.mostrar,
      mensagem: props.mensagem
    })
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Snackbar
          open={this.state.mostrar}
          autoHideDuration={5000}
          onClose={e => this.props.aoFechar()}
          variant="error"
          message={<span>{this.state.mensagem}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={e => this.setState({ mostrar: false })}>
              <Icon>close</Icon>
            </IconButton>
          ]}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
      </MuiThemeProvider>
    );
  }
}

export default ErroPagina;