import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Paper, Table, TableHead, TableRow,
    TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon,
    CircularProgress, Typography, LinearProgress
} from '@material-ui/core';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/contatos';

class TabelaContatos extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, contato) => {
        return e.target.checked
            ? this.props.adicionarSelecao(contato)
            : this.props.removerSelecao(contato);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (contato) => {
        this.refs.confirmacaoRemover.mostrar(contato);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Typography>Lido:</Typography>
                        <Icon color="primary">mail_outline</Icon>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Typography>Não lido:</Typography>
                        <Icon color="primary">email</Icon>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.contatos.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Lido</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>E=mail</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.contatos.map((contato, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === contato.id)}
                                                onChange={(e) => this.alterarSelecao(e, contato)}
                                                value={`${contato.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>
                                            {(contato.lido) ? <Icon color="primary">mail_outline</Icon> : <Icon color="primary">email</Icon>}
                                        </TableCell>
                                        <TableCell>{contato.nome}</TableCell>
                                        <TableCell>{contato.email}</TableCell>
                                        <TableCell>{moment(contato.created_at).format('LLL')}</TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                            <IconButton size="small" onClick={e => this.props.iniciarEditar(contato)} color="default" aria-label="Editar">
                                                <Icon>search</Icon>
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={contato.removendo}
                                                onClick={e => this.mostrarConfirmacaoRemover(contato)}
                                                color="secondary"
                                                aria-label="Remover"
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                {(contato.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '.2em', top: '.2em'}} />
                                                    : ''}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.contatos.length)
                                ? <TableRow>
                                    <TableCell colSpan={6}>
                                        <Typography>Nenhum contato recebido.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={4} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        contatos: state.contatos.itens,
        selecionados: state.contatos.selecionados,
        carregando: state.contatos.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        adicionarSelecao: (contato) => dispatch(adicionarSelecao(contato)),
        removerSelecao: (contato) => dispatch(removerSelecao(contato)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (contato) => dispatch(remover(contato)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (contato) => dispatch(definirItem(contato)),
        iniciarEditar: (contato) => dispatch(iniciarEditar(contato))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaContatos);