import axios from 'axios';
class ServicoEstabelecimento {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/estabelecimentos` : 'https://api.pontuafidelidade.com.br/api/admin/estabelecimentos';

  constructor() {
    this.buscarToken();
    this.estabelecimentos = (localStorage.getItem('estabelecimentos')) ? JSON.parse(localStorage.getItem('estabelecimentos')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarEstabelecimentoPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarNomesEstabelecimentos(qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?campos=id,razao_social,nome_fantasia&page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  buscarEstabelecimentos(qtde = null, pagina = 1, ordem = null) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      if (ordem) url = `${url}&ordem=${ordem}`;
      console.log(url);
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  pesquisarEstabelecimentos(pesquisa, qtde, pagina) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?pesquisa=${pesquisa}&page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  adicionarEstabelecimento(novoEstabelecimento) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        data: novoEstabelecimento,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  atualizarEstabelecimento(id, estabelecimento) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: estabelecimento,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  removerEstabelecimento(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoEstabelecimento;