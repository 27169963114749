import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoVoucher';
import { Types as VouchersTypes } from '../ducks/vouchers';
import { Types as PesquisaTypes } from '../ducks/pesquisa';
const servico = new Servico();

function* salvar (action) {
    if (action.voucher.id) {
        try {
            const voucher = yield call([servico, servico.atualizarVoucher], action.voucher.id, action.voucher);
            yield put({type: VouchersTypes.SALVAR_SUCESSO, voucher: voucher.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: VouchersTypes.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const voucher = yield call([servico, servico.adicionarVoucher], action.voucher);
            yield put({type: VouchersTypes.SALVAR_SUCESSO, voucher});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: VouchersTypes.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerVoucher], action.voucher.id);
        yield put({type: VouchersTypes.REMOVER_SUCESSO, voucher: action.voucher});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: VouchersTypes.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const voucher = yield call([servico, servico.buscarVoucherPorId], action.id);
        yield put({type: VouchersTypes.BUSCAR_ITEM_SUCESSO, voucher});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: VouchersTypes.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const vouchers = yield call([servico, servico.buscarVouchers], action.qtde, action.pagina);
        yield put({type: VouchersTypes.BUSCAR_TODOS_SUCESSO, vouchers});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: VouchersTypes.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* pesquisar (action) {
    try {
        const vouchers = yield call([servico, servico.pesquisarVouchers], action.pesquisa, action.qtde, action.pagina);
        yield put({type: VouchersTypes.PESQUISAR_SUCESSO, vouchers});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: VouchersTypes.PESQUISAR_ERRO, erro: e.message});
        yield put({type: PesquisaTypes.REMOVER_LOADER});
     }
}

function* saga () {
    yield takeLatest(VouchersTypes.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(VouchersTypes.SALVAR_REQUEST, salvar);
    yield takeLatest(VouchersTypes.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(VouchersTypes.PESQUISAR_REQUEST, pesquisar);
    yield takeEvery(VouchersTypes.REMOVER_REQUEST, remover);
}

export default saga;