import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoPlano';
import { Types as PlanosTypes } from '../ducks/planos';
const servico = new Servico();

function* salvar (action) {
    if (action.plano.id) {
        console.log(action.plano);
        try {
            const plano = yield call([servico, servico.atualizarPlano], action.plano.id, action.plano);
            yield put({type: PlanosTypes.SALVAR_SUCESSO, plano: plano.data, editar: true});
        } catch (e) {
            console.log(e);
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: PlanosTypes.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const plano = yield call([servico, servico.adicionarPlano], action.plano);
            yield put({type: PlanosTypes.SALVAR_SUCESSO, plano});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: PlanosTypes.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerPlano], action.plano.id);
        yield put({type: PlanosTypes.REMOVER_SUCESSO, plano: action.plano});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PlanosTypes.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const plano = yield call([servico, servico.buscarPlanoPorId], action.id);
        yield put({type: PlanosTypes.BUSCAR_ITEM_SUCESSO, plano});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PlanosTypes.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const planos = yield call([servico, servico.buscarPlanos], action.qtde, action.pagina);
        yield put({type: PlanosTypes.BUSCAR_TODOS_SUCESSO, planos});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: PlanosTypes.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(PlanosTypes.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(PlanosTypes.SALVAR_REQUEST, salvar);
    yield takeLatest(PlanosTypes.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeEvery(PlanosTypes.REMOVER_REQUEST, remover);
}

export default saga;