import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import ErroForm from '../componentes/ErroForm';
import DrawerDepoimentos from '../componentes/depoimentosSite/DrawerAdicionar';
import TabelaDepoimentos from '../componentes/depoimentosSite/TabelaDepoimentos';

// REDUX
import {
  alterarLayout,
  buscarTodos,
  mostrarForm
} from '../store/ducks/depoimentosSite';

class PaginaDepoimentosSite extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              {/* ADICIONAR */}
              <Button color="primary" variant="contained" onClick={this.props.mostrarForm}>Adicionar</Button>
            </Grid>
          </Grid>
          <DrawerDepoimentos />
          <TabelaDepoimentos />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.depoimentosSite.carregando,
    erro: state.depoimentosSite.erro,
    layout: state.depoimentosSite.layout,
    selecionados: state.depoimentosSite.selecionados
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    buscarTodos: () => dispatch(buscarTodos()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaDepoimentosSite));