import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoPermissao';
import { Types } from '../ducks/permissoes';
const servico = new Servico();

function* buscarTodos (action) {
    try {
        const permissoes = yield call([servico, servico.buscarPermissoes]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, permissoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
}

export default saga;