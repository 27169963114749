import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import admins from './admins';
import adminsEstabelecimentos from './adminsEstabelecimentos';
import categoriasEstabelecimentos from './categoriasEstabelecimentos';
import cep from './cep';
import configuracoesSite from './configuracoesSite';
import contatos from './contatos';
import depoimentosSite from './depoimentosSite';
import estatisticasEstabelecimentos from './estatisticasEstabelecimentos';
import estatisticasGerais from './estatisticasGerais';
import estatisticasProdutos from './estatisticasProdutos';
import estatisticasVouchers from './estatisticasVouchers';
import funcoesEstabelecimentos from './funcoesEstabelecimentos';
import estabelecimentos from './estabelecimentos';
import funcoes from './funcoes';
import header from './header';
import inscricoes from './inscricoes';
import paginas from './paginas';
import pesquisa from './pesquisa';
import perguntasFrequentesSite from './perguntasFrequentesSite';
import permissoes from './permissoes';
import planos from './planos';
import produtos from './produtos';
import promocoes from './promocoes';
import slidesSite from './slidesSite';
import textosSite from './textosSite';
import usuarios from './usuarios';
import vantagensSite from './vantagensSite';
import vouchers from './vouchers';

const reducers = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    admins,
    adminsEstabelecimentos,
    categoriasEstabelecimentos,
    cep,
    configuracoesSite,
    contatos,
    depoimentosSite,
    estatisticasEstabelecimentos,
    estatisticasGerais,
    estatisticasProdutos,
    estatisticasVouchers,
    estabelecimentos,
    funcoesEstabelecimentos,
    funcoes,
    header,
    inscricoes,
    paginas,
    pesquisa,
    perguntasFrequentesSite,
    planos,
    produtos,
    promocoes,
    permissoes,
    slidesSite,
    textosSite,
    usuarios,
    vantagensSite,
    vouchers
});

export default reducers;