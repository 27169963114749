import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';

// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormSelect from '../../geral/CampoFormSelect';
import CampoFormSwitch from '../../geral/CampoFormSwitch';
import CampoFormTextarea from '../../geral/CampoFormTextarea';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography,
  CircularProgress, Button
} from '@material-ui/core';

import { IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/produtos';

const styles = {
  overFlowVisible: {
    overflow: 'visible !important'
  },
  drawerNormal: {
    width: '500px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  drawerExtendido: {
    width: '768px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  btnFloatHover: {
    backgroundColor: 'white'
  },
  btnFloatNormal: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  },
  btnFloatExtendido: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%) rotate(180deg)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  }
};

class DrawerAdicionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendido: false
    }
  }
  componentDidMount() {
    this.props.alterarItem('estabelecimentos_id', this.props.match.params.id);
  }
  extenderMenu = (e) => {
    e.preventDefault();
    this.setState({extendido: !this.state.extendido});
  }
  salvar = (e) => {
    e.preventDefault();
    const produto = { ...this.props.item };
    if (this.props.estabelecimento.id) {
      produto['estabelecimentos_id'] = this.props.estabelecimento.id;
    }
    this.props.salvar(produto);
  }
  render() {
    return (
      <div role="presentation">
        <Drawer
          classes={{
            paper: (this.state.extendido) ? this.props.classes.drawerExtendido : this.props.classes.drawerNormal
          }}
          anchor="right"
          open={this.props.mostrar}
          onClose={e => this.props.esconderForm()}>
          <IconButton
            classes={{
              root: (this.state.extendido) ? this.props.classes.btnFloatExtendido : this.props.classes.btnFloatNormal,
              hover: this.props.classes.btnFloatHover}}
            size="small"
            onClick={this.extenderMenu}
            color="primary"
            aria-label="Extender">
              <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1.5em', overflow: 'auto'}}>
            {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
            <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar prêmio</Typography>
            {/* NOME */}
            <CampoFormTexto
              required
              label="Nome"
              value={this.props.item.nome}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
              onChange={e => this.props.alterarItem('nome', e.target.value)} />
            {/* DESCRICAO */}
            <CampoFormTextarea
              label="Descrição"
              placeholder="Informe uma descrição para o prêmio"
              value={this.props.item.descricao}
              onChange={e => this.props.alterarItem('descricao', e)} />
            {/* TIPO PONTUACAO */}
            <CampoFormSelect
              label="Tipo da pontuação"
              value={this.props.item.tipo_pontuacao}
              onChange={e => this.props.alterarItem('tipo_pontuacao', e.target.value)}
              opcoes={[
                {value: 'quantidade', label: 'Quantidade'},
                {value: 'pontos', label: 'Pontos'}
              ]}/>
            {/* QUANTIDADE */}
            {(this.props.item.tipo_pontuacao === 'quantidade')
              ? <CampoFormTexto
                  label="Quantidade"
                  type="number"
                  value={this.props.item.quantidade}
                  erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.quantidade) ? this.props.erro.errors.quantidade[0] : ''}
                  onChange={e => this.props.alterarItem('quantidade', e.target.value)} />
                  : ''}
            {/* PONTOS */}
            {(this.props.item.tipo_pontuacao === 'pontos')
              ? <CampoFormTexto
                  label="Pontos"
                  type="number"
                  value={this.props.item.pontos}
                  erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.pontos) ? this.props.erro.errors.pontos[0] : ''}
                  onChange={e => this.props.alterarItem('pontos', e.target.value)} />
                  : ''}
            {/* ATIVO */}
            <CampoFormSwitch
              label="Prêmio ativo"
              checked={this.props.item.ativo}
              onChange={e => this.props.alterarItem('ativo', !this.props.item.ativo)} />
            {/* REGRAS */}
            <CampoFormTextarea
              label="Regras do prêmio"
              placeholder="Regras de pontuação e resgate do prêmio"
              value={this.props.item.regras}
              onChange={e => this.props.alterarItem('regras', e)} />
            {(this.props.erro && this.props.erro.message)
              ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
              : ''}
            <div className="flex-end">
              <Button
                disabled={this.props.carregando}
                type="submit"
                style={{marginTop: '1em', position: 'relative'}}
                variant="contained"
                color="primary">
                Salvar
                {(this.props.carregando)
                  ? <CircularProgress
                    size={24}
                    color="secondary"
                    style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                  : ''}
              </Button>
            </div>
          </form>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.produtos.carregando,
    estabelecimento: state.estabelecimentos.item,
    item: state.produtos.item,
    mostrar: state.produtos.mostrarForm,
    erro: state.produtos.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (produto) => dispatch(salvar(produto)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerAdicionar)));