import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chip, Icon, CircularProgress } from '@material-ui/core';

import '../../css/InputPesquisa.css';

import {
  alterarPesquisa,
  removerPesquisa,
  pesquisar
} from '../../store/ducks/pesquisa';

class InputPesquisa extends Component {
  constructor(props) {
    super(props);
    this.refBtnPesquisa = React.createRef();
  }
  pesquisar = (e) => {
    e.preventDefault();
    this.props.pesquisar(this.props.pesquisa);
    this.refBtnPesquisa.blur();
    if (typeof this.props.aoPesquisar === 'function') this.props.aoPesquisar();
  }
  removerPesquisa = (e) => {
    this.props.removerPesquisa();
    if (typeof this.props.aoRemoverPesquisa === 'function') this.props.aoRemoverPesquisa();
  }
  render() {
    return (
      <div className="wrapper-pesquisa">
        {(this.props.pesquisado)
          ? <Chip
            className="label-pesquisado"
            onDelete={this.removerPesquisa}
            icon={<Icon>search</Icon>}
            label={this.props.pesquisado} />
          : ''}
        {/* FORM PESQUISA */}
        <form className="form-pesquisa" onSubmit={this.pesquisar}>
          <Icon className="icone-pesquisa">search</Icon>
          <input
            ref={e => this.refBtnPesquisa = e}
            autoComplete="off"
            className="campo-pesquisa"
            value={this.props.pesquisa}
            onChange={e => this.props.alterarPesquisa(e.target.value)}
            placeholder="Pesquisar..." />
          {(this.props.pesquisando) ? <CircularProgress className="progress-pesquisa" color="secondary" size={24} /> : ''}
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    pesquisa: state.pesquisa.pesquisa,
    pesquisado: state.pesquisa.pesquisado,
    pesquisando: state.pesquisa.pesquisando
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarPesquisa: (pesquisa) => dispatch(alterarPesquisa(pesquisa)),
    removerPesquisa: () => dispatch(removerPesquisa()),
    pesquisar: (pesquisa) => dispatch(pesquisar(pesquisa))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputPesquisa);
