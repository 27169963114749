import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Paper, Table, TableHead, TableRow,
    TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon,
    CircularProgress, Typography, LinearProgress
} from '@material-ui/core';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar,
    reordenarItem
} from '../../../store/ducks/vantagensSite';

class TabelaVantagens extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, vantagem) => {
        return e.target.checked
            ? this.props.adicionarSelecao(vantagem)
            : this.props.removerSelecao(vantagem);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (vantagem) => {
        this.refs.confirmacaoRemover.mostrar(vantagem);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    onDragEnd = result => {
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId &&
                destination.index === source.index) {
                    return;
                }
        this.props.reordenarItem(result.source.index, result.destination.index, draggableId);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Icon>swap_vert</Icon>
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={(this.props.selecionados.length === this.props.vantagens.length)}
                                            onChange={this.alterarSelecaoTodos}
                                            color="primary"/>
                                    </TableCell>
                                    <TableCell padding="none">#</TableCell>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Contaúdo</TableCell>
                                    <TableCell padding="none">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <Droppable droppableId="vantagens">
                                {provided => (
                                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                        {(this.props.vantagens.map((vantagem, i) => {
                                            return (
                                                <Draggable key={vantagem.id} draggableId={`${vantagem.id}`} index={i}>
                                                    {(provided) => (
                                                        <TableRow ref={provided.innerRef} {...provided.draggableProps}>
                                                            <TableCell padding="checkbox">
                                                                <Icon {...provided.dragHandleProps}>reorder</Icon>
                                                            </TableCell>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={this.props.selecionados.some(e => e.id === vantagem.id)}
                                                                    onChange={(e) => this.alterarSelecao(e, vantagem)}
                                                                    value={`${vantagem.id}`} />
                                                            </TableCell>
                                                            <TableCell padding="none">{i+1}</TableCell>
                                                            <TableCell>{vantagem.titulo}</TableCell>
                                                            <TableCell>{vantagem.conteudo}</TableCell>
                                                            <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                                                <IconButton size="small" onClick={e => this.props.iniciarEditar(vantagem)} color="default" aria-label="Editar">
                                                                    <Icon>edit</Icon>
                                                                </IconButton>
                                                                <IconButton
                                                                    size="small"
                                                                    disabled={vantagem.removendo}
                                                                    onClick={e => this.mostrarConfirmacaoRemover(vantagem)}
                                                                    color="secondary"
                                                                    aria-label="Remover"
                                                                    style={{position: 'relative'}}>
                                                                    <Icon>delete</Icon>
                                                                    {(vantagem.removendo)
                                                                        ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '.2em', top: '.2em'}} />
                                                                        : ''}
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        }))}
                                        {(!this.props.vantagens.length)
                                            ? <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography>Nenhuma vantagem cadastrada.</Typography>
                                                </TableCell>
                                            </TableRow>
                                            : ''}
                                    </TableBody>
                                )}
                            </Droppable>
                            {(this.props.selecionados.length > 1)
                                ? <TableFooter>
                                    <TableRow>
                                        <TableCell padding="none"></TableCell>
                                        <TableCell padding="none"></TableCell>
                                        <TableCell colSpan={3} style={{display: 'flex', alignItems: 'center'}}>
                                            <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                            <IconButton
                                                color="secondary"
                                                aria-label="Remover selecionados"
                                                onClick={this.mostrarConfirmacaoSelecionados}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                                : ''}
                        </Table>
                    </DragDropContext>
                </Paper>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vantagens: state.vantagensSite.itens,
        selecionados: state.vantagensSite.selecionados,
        carregando: state.vantagensSite.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        adicionarSelecao: (vantagem) => dispatch(adicionarSelecao(vantagem)),
        removerSelecao: (vantagem) => dispatch(removerSelecao(vantagem)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (vantagem) => dispatch(remover(vantagem)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (vantagem) => dispatch(definirItem(vantagem)),
        iniciarEditar: (vantagem) => dispatch(iniciarEditar(vantagem)),
        reordenarItem: (origem, destino, id) => dispatch(reordenarItem(origem, destino, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaVantagens);