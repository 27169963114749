import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    Chip, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/vouchers';

class TabelaVouchers extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, voucher) => {
        return e.target.checked
            ? this.props.adicionarSelecao(voucher)
            : this.props.removerSelecao(voucher);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (voucher) => {
        this.refs.confirmacaoRemover.mostrar(voucher);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (voucher, e = null) => {
        if (e) e.preventDefault();
        this.props.definirItem(voucher);
        this.props.irParaMostrar(voucher.id);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.vouchers.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Título</TableCell>
                                <TableCell>Função</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Percent/Valor</TableCell>
                                <TableCell>Quantidade</TableCell>
                                <TableCell>Estab.</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.vouchers.map((voucher, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === voucher.id)}
                                                onChange={(e) => this.alterarSelecao(e, voucher)}
                                                value={`${voucher.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>
                                            <Link onClick={e => this.mostrar(voucher, e)} className="andes-link" to={`/vouchers/ver/${voucher.id}`}>{voucher.titulo}</Link>
                                        </TableCell>
                                        <TableCell>
                                            {voucher.nomeFuncao}                                            
                                        </TableCell>
                                        <TableCell>{(voucher.tipo === 'percentual') ? 'Percentual' : 'Valor'}</TableCell>
                                        <TableCell>{(voucher.tipo === 'percentual') ? `${voucher.percentual}%` : `R$${voucher.valor}`}</TableCell>
                                        <TableCell>{(voucher.sem_limite_quantidade ? <Chip label="Ilimitado" color="primary" /> : <Typography>{voucher.quantidade}</Typography>)}</TableCell>
                                        <TableCell style={{textAlign: 'center'}}>
                                            {(voucher.parceiro)
                                                ? <>
                                                    <Link className="andes-link" to={`/estabelecimentos/ver/${voucher.estabelecimento.id}?tab=vouchers`}>{voucher.estabelecimento.razao_social}</Link>
                                                    {(voucher.funcao === 'parceria')
                                                        ? <> 
                                                            <br />
                                                            <Icon>compare_arrows</Icon><br /><Link className="andes-link" to={`/estabelecimentos/ver/${voucher.parceiro.id}`}>{voucher.parceiro.razao_social}</Link>
                                                        </>
                                                        : ''}
                                                </>
                                                : '-'}
                                        </TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                            <IconButton size="small" onClick={e => this.mostrar(voucher)} color="default" aria-label="Detalhes">
                                                <Icon>search</Icon>
                                            </IconButton>
                                            <IconButton size="small" onClick={e => this.props.iniciarEditar(voucher)} color="default" aria-label="Editar">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={voucher.removendo}
                                                onClick={e => this.mostrarConfirmacaoRemover(voucher)}
                                                color="secondary"
                                                aria-label="Remover"
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                {(voucher.removendo)
                                                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '.5em', top: '.5em'}} />
                                                    : ''}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.vouchers.length)
                                ? <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum cupom de desconto cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={6} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vouchers: state.vouchers.itens,
        selecionados: state.vouchers.selecionados,
        paginas: state.vouchers.paginas,
        pagina: state.vouchers.pagina,
        qtde: state.vouchers.qtde,
        carregando: state.vouchers.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (voucher) => dispatch(adicionarSelecao(voucher)),
        removerSelecao: (voucher) => dispatch(removerSelecao(voucher)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (voucher) => dispatch(remover(voucher)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (voucher) => dispatch(definirItem(voucher)),
        irParaMostrar: (id) => dispatch(push(`/vouchers/ver/${id}`)),
        iniciarEditar: (voucher) => dispatch(iniciarEditar(voucher))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaVouchers);