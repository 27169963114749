import axios from 'axios';
class ServicoFuncao {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/permissoes` : 'https://api.pontuafidelidade.com.br/api/admin/permissoes';

  constructor() {
    this.buscarToken();
    this.funcoes = (localStorage.getItem('permissoes')) ? JSON.parse(localStorage.getItem('permissoes')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarPermissoes() {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

}

export default ServicoFuncao;