import { all } from 'redux-saga/effects';
import auth from './auth';
import admins from './admins';
import adminsEstabelecimentos from './adminsEstabelecimentos';
import categoriasEstabelecimentos from './categoriasEstabelecimentos';
import cep from './cep';
import configuracoesSite from './configuracoesSite';
import contatos from './contatos';
import depoimentosSite from './depoimentosSite';
import estabelecimentos from './estabelecimentos';
import estatisticasEstabelecimentos from './estatisticasEstabelecimentos';
import estatisticasGerais from './estatisticasGerais';
import estatisticasProdutos from './estatisticasProdutos';
import estatisticasVouchers from './estatisticasVouchers';
import funcoes from './funcoes';
import funcoesEstabelecimentos from './funcoesEstabelecimentos';
import inscricoes from './inscricoes';
import perguntasFrequentesSite from './perguntasFrequentesSite';
import permissoes from './permissoes';
import planos from './planos';
import produtos from './produtos';
import promocoes from './promocoes';
import slidesSite from './slidesSite';
import textosSite from './textosSite';
import usuarios from './usuarios';
import vantagensSite from './vantagensSite';
import vouchers from './vouchers';

export default function* rootSaga() {
  yield all([
    auth(),
    admins(),
    adminsEstabelecimentos(),
    categoriasEstabelecimentos(),
    cep(),
    configuracoesSite(),
    contatos(),
    depoimentosSite(),
    estabelecimentos(),
    estatisticasEstabelecimentos(),
    estatisticasGerais(),
    estatisticasProdutos(),
    estatisticasVouchers(),
    funcoes(),
    funcoesEstabelecimentos(),
    inscricoes(),
    perguntasFrequentesSite(),
    permissoes(),
    planos(),
    produtos(),
    promocoes(),
    slidesSite(),
    textosSite(),
    usuarios(),
    vantagensSite(),
    vouchers()
  ]);
}