import axios from 'axios';
class ServicoProduto {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/produtos` : 'https://api.pontuafidelidade.com.br/api/admin/produtos';
  apiUrlEstatisticas = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/estatisticas-produtos` : 'https://api.pontuafidelidade.com.br/api/admin/estatisticas-produtos';

  constructor() {
    this.buscarToken();
    this.produtos = (localStorage.getItem('produtos')) ? JSON.parse(localStorage.getItem('produtos')) : [];
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  buscarEstatisticas(idProduto) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrlEstatisticas}/${idProduto}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  buscarProdutoPorId(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err);
      })
    });
  }

  buscarProdutos(qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  buscarProdutosEstabelecimento(idEstabelecimento, qtde = null, pagina = 1) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}/estabelecimento=${idEstabelecimento}?page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  pesquisarProdutos(pesquisa, qtde, pagina) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      let url = `${this.apiUrl}?pesquisa=${pesquisa}&page=${pagina}`;
      if (qtde) url = `${url}&qtde=${qtde}`;
      axios({
        url,
        method: 'get',
        headers: {'Authorization': `Bearer ${this.token}`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  adicionarProduto(novoProduto) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}`,
        data: novoProduto,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  atualizarProduto(id, produto) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: produto,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      });
    });
  }

  removerProduto(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoProduto;