import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoCep';
import { Types } from '../ducks/cep';
import { Types as TypesEstab } from '../ducks/estabelecimentos';
const servico = new Servico();

function* buscarDados (action) {
    try {
        const dados = yield call([servico, servico.buscarDados], action.cep);
        yield put({type: TypesEstab.ALTERAR_ITEM, campo: 'bairro', valor: dados.bairro});
        yield put({type: TypesEstab.ALTERAR_ITEM, campo: 'cidade', valor: dados.localidade});
        yield put({type: TypesEstab.ALTERAR_ITEM, campo: 'estado', valor: dados.uf});
        yield put({type: TypesEstab.ALTERAR_ITEM, campo: 'logradouro', valor: dados.logradouro});
        yield put({type: Types.BUSCAR_SUCESSO, dados});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(Types.BUSCAR_REQUEST, buscarDados);
}

export default saga;