import React from 'react';
import { connect } from 'react-redux';
import {
  Paper, Grid, Typography, CircularProgress,
  Table, TableHead, TableBody, TableRow, TableCell,
  Divider
} from '@material-ui/core';
import {
  ResponsiveContainer, XAxis, YAxis, Tooltip, PieChart, Pie, Cell, Legend,
  CartesianGrid, BarChart, Bar, LineChart, Line
} from 'recharts';
import ServicoEstatistica from '../../../servicos/ServicoEstatistica';
import CardEstatistica from '../../../componentes/geral/CardEstatistica';
import TabelaTopUsuarios from '../../../componentes/usuarios/TabelaTopUsuarios';

import {
  buscarItem as buscarEstatisticas
} from '../../../store/ducks/estatisticasEstabelecimentos';

const cores = ['#58359d', '#ff3f6e', '#ff6033', '#FFBB28', '#FF8042'];
class TabDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estabelecimento: props.estabelecimento || {},
      estatisticas: null,
      buscando: false
    }
    this.servicoEstatistica = new ServicoEstatistica();
    this.data = [
      {name: '31/05', estabelecimentos: 0, pontos: 0, usuarios: 0 },
      {name: '01/06', estabelecimentos: 1, pontos: 2, usuarios: 3 },
      {name: '02/06', estabelecimentos: 1, pontos: 2, usuarios: 3 },
      {name: '03/06', estabelecimentos: 2, pontos: 2, usuarios: 4 },
      {name: '04/06', estabelecimentos: 2, pontos: 3, usuarios: 4 },
      {name: '05/06', estabelecimentos: 4, pontos: 6, usuarios: 5 }
    ];
  }

  componentDidMount() {
    if (this.props.estabelecimento && this.props.estabelecimento.id) {
      this.props.buscarEstatisticas(this.props.estabelecimento.id);
    }
  }

  renderCarregando = () => {
    if (this.props.carregando) {
      return (
        <div style={{marginTop: '1em', display: 'flex', alignItems: 'center'}}>
          <CircularProgress color="secondary" size={24} />
          <Typography style={{marginLeft: '1em'}}>Buscando...</Typography>
        </div>
      );
    }
  }

  renderGrafico = () => {
    return (
      <>
        <Typography>Pontos convertidos</Typography>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={this.props.estatisticas.historicoMensal}>
            <Line
              dot={{ stroke: '#FF3F6E', strokeWidth: 1, fill: '#FF3F6E' }}
              stroke="#FF3F6E"
              strokeWidth={2}
              type="natural"
              dataKey="pontos"
              name="Pontos convertidos" />
            <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
        <Typography>Usuários pontuando no estabelecimento</Typography>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={this.props.estatisticas.historicoMensal}>
            <Line
              dot={{ stroke: '#FF6033', strokeWidth: 1, fill: '#FF6033' }}
              stroke="#FF6033"
              strokeWidth={2}
              type="natural"
              dataKey="usuarios"
              name="Usuários" />
            <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  };

  renderGraficoUsuariosPorSexo = () => {
    const itensGrafico = [];
    if(this.props.estatisticas && this.props.estatisticas.usuarios && this.props.estatisticas.usuarios.por_sexo) {
      console.log(this.props.estatisticas.usuarios);
      const { por_sexo } = this.props.estatisticas.usuarios;
      let total = 0;
      por_sexo.map(sexo => {
        total += parseInt(sexo.quantidade);
        return itensGrafico.push({name: (sexo.nome_sexo) ? sexo.nome_sexo : 'Não informado', value: parseInt(sexo.quantidade)})
      });
      return (
        <Paper elevation={3} style={{width: '100%', height: '300px', padding: '1em'}}>
          <Typography variant="h6">Por sexo</Typography>
          <ResponsiveContainer height={250}>
            <PieChart>
              <Pie isAnimationActive={false} dataKey="value" data={itensGrafico} fill="#222222"
              cx="50%" cy="50%" outerRadius={80}
              label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                const RADIAN = Math.PI / 180;
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                return (
                  <text
                    x={x} y={y} fill="#222222"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central">
                    {itensGrafico[index].name} ({Math.ceil((100*value)/total)}%)
                  </text>
                );
                }}>
                  {
                    itensGrafico.map((entry, index) => <Cell key={index} fill={cores[index % cores.length]}/>)
                  }
                </Pie>
                <Legend wrapperStyle={{bottom: '10px'}} />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      );
    }
  }

  renderGraficoUsuariosPorFaixaEtaria = () => {
    const itensGrafico = [];
    if(this.props.estatisticas && this.props.estatisticas.usuarios && this.props.estatisticas.usuarios.por_faixa_etaria) {
      console.log(this.props.estatisticas.usuarios);
      const { por_faixa_etaria } = this.props.estatisticas.usuarios;
      let total = 0;
      por_faixa_etaria.map(faixa => {
        total += parseInt(faixa.quantidade);
        return itensGrafico.push({name: faixa.faixa, value: parseInt(faixa.quantidade)})
      });
      return (
        <Paper elevation={3} style={{width: '100%', height: '300px', padding: '1em'}}>
          <Typography variant="h6">Por faixa etária</Typography>
          <ResponsiveContainer height={250}>
            <BarChart data={itensGrafico}>
              <Bar dataKey="value" name="Pontos" fill="#FF6033">
                {
                  itensGrafico.map((entry, index) => <Cell key={index} fill={cores[index % cores.length]}/>)
                }
              </Bar>
              <CartesianGrid stroke="rgba(60, 60, 60, .3)" strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      );
    }
  }

  renderGraficosUsuarios = () => {
    return (
      <div>
        <Divider style={{marginTop: '1.5em'}} />
        <Paper style={{padding: '.5em 1em'}} elevation={0}>
          {/* TITULO */}
          <Grid container>
            <Grid item md={6} sm={6} xs={6}>
              <Typography variant="h4" style={{paddingTop: '.5em', marginBottom: '.5em'}}>Usuários</Typography>
            </Grid>
            <Grid item container md={6} sm={6} xs={6} justify="flex-end" alignItems="center">
              <Typography color="primary" className="text-uppercase" style={{display: 'inline'}}>Total de usuários: <strong>{(this.props.estatisticas) ? this.props.estatisticas.usuarios.total : 0}</strong></Typography>
              <Typography color="secondary" style={{display: 'inline', marginLeft: '.5em'}}>{(this.props.estatisticas.usuarios && this.props.estatisticas.usuarios.hoje > 0) ? `+${this.props.estatisticas.usuarios.hoje} hoje` : ''}</Typography>
            </Grid>
          </Grid>
          {/* PIZZA USUARIOS */}
          <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                {this.renderGraficoUsuariosPorSexo()}
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                {this.renderGraficoUsuariosPorFaixaEtaria()}
              </Grid>
          </Grid>
          <Grid container spacing={2}>
              {/* TOP USUARIOS */}
            <Grid item md={4} sm={12} xs={12}>
              <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Maiores pontuadores</Typography>
                <TabelaTopUsuarios usuarios={this.props.estatisticas.usuarios.top} />
              </Paper>
            </Grid>
            {/* MAIS VEZES */}
            <Grid item md={4} sm={12} xs={12}>
              <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Pontuaram mais vezes</Typography>
                <TabelaTopUsuarios colunaDados="Vezes" usuarios={this.props.estatisticas.usuarios.mais_vezes} />
              </Paper>
            </Grid>
            {/* MENOS VEZES */}
            <Grid item md={4} sm={12} xs={12}>
              <Paper style={{padding: '.5em 1em', marginBottom: '1em', marginTop: '1em'}} elevation={0}>
                <Typography variant="h6" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Pontuaram menos vezes</Typography>
                <TabelaTopUsuarios colunaDados="Vezes" usuarios={this.props.estatisticas.usuarios.menos_vezes} />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }

  render () {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        {this.renderCarregando()}
        <Typography variant="h4" style={{paddingTop: '.5em'}}>Geral</Typography>
        <Grid container spacing={2} style={{marginBottom: '.5em', marginTop: '.5em'}}>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="primary"
                titulo={(this.props.estatisticas.pontuacoes) ? this.props.estatisticas.pontuacoes.total : '-'}
                adicional={
                  (this.props.estatisticas.pontuacoes && this.props.estatisticas.pontuacoes.hoje > 0) ? `+${this.props.estatisticas.pontuacoes.hoje} hoje` : ''
                }
                texto={'Pontuações'}
                icone="done" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="primary"
                titulo={(this.props.estatisticas.pontos) ? this.props.estatisticas.pontos.total : '-'}
                adicional={
                  (this.props.estatisticas.pontos && this.props.estatisticas.pontos.hoje > 0) ? `+${this.props.estatisticas.pontos.hoje} hoje` : ''
                }
                texto={'Pontos'}
                icone="done_all" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="secondary"
                titulo={(this.props.estatisticas.resgates_produtos) ? this.props.estatisticas.resgates_produtos.total : '0'}
                adicional={
                  (this.props.estatisticas.resgates_produtos && this.props.estatisticas.resgates_produtos.hoje > 0) ? `+${this.props.estatisticas.resgates_produtos.hoje} hoje` : ''
                }
                texto={'Resgates de prêmios'}
                icone="emoji_events" />
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <CardEstatistica
                color="tertiary"
                titulo={(this.props.estatisticas.resgates_vouchers) ? this.props.estatisticas.resgates_vouchers.total : '0'}
                adicional={
                  (this.props.estatisticas.resgates_vouchers && this.props.estatisticas.resgates_vouchers.hoje > 0) ? `+${this.props.estatisticas.resgates_vouchers.hoje} hoje` : ''
                }
                texto={'Resgates de cupons'}
                icone="insert_link" />
            </Grid>
        </Grid>
        {(!this.props.carregando)
          ? <div>
            {this.renderGraficosUsuarios()}
            <Divider style={{marginTop: '1em'}} />
            <Paper style={{padding: '.5em 1em', marginBottom: '1em'}} elevation={0}>
              <Typography variant="h4" style={{paddingBottom: '1em', paddingTop: '.5em'}}>Histórico</Typography>
              {this.renderGrafico()}
            </Paper>
          </div>
          : ''}
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    estabelecimento: state.estabelecimentos.item,
    carregando: state.estatisticasEstabelecimentos.carregando,
    erro: state.estatisticasEstabelecimentos.erro,
    estatisticas: state.estatisticasEstabelecimentos.item
  }
}

const mapDispatchToProps = dispatch => {
    return {
      buscarEstatisticas: (id) => dispatch(buscarEstatisticas(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabDashboard);