import React, { Component } from 'react';
import {
  Card, CardActionArea, CardMedia,
  CardContent, Typography, CardActions, Button,
  LinearProgress
} from '@material-ui/core';

class CardImagem extends Component {
  render() {
    return (
      <div style={{position: 'relative'}}>
        {(this.props.carregando) ? <LinearProgress color="secondary" style={{zIndex: '1', position: 'absolute', top: '0', width: '100%'}} /> : ''}
        <Card style={{marginBottom: '1em'}}>
          <CardActionArea>
            <CardMedia
              style={{height: 250}} 
              image={this.props.src}
              title={this.props.alt} />
          </CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">{this.props.titulo}</Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.props.descricao}
            </Typography>
          </CardContent>
          <CardActions>
            {!this.props.esconderBtnCapa
              ? <Button
                onClick={e => this.props.aoDefinirCapa()}
                size="small"
                color="primary">Capa</Button>
              : ''}
            {!this.props.esconderBtnPerfil
              ? <Button
                onClick={e => this.props.aoDefinirPerfil()}
                size="small"
                color="primary">Perfil</Button>
              : ''}
            <Button
              onClick={e => this.props.aoRemover()}
              size="small"
              color="secondary">Remover</Button>
          </CardActions>
        </Card>
      </div>
    )
  }
}

export default CardImagem;