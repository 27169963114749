import axios from 'axios';
class ServicoImagemPromocao {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin/imagens-promocoes` : 'https://api.pontuafidelidade.com.br/api/admin/imagens-promocoes';

  constructor() {
    this.buscarToken();
  }

  buscarToken() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
  }

  enviarImagem(idPromocao, data) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${idPromocao}`,
        data,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

  removerCapa(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: {capa: false},
        json: true,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      })
    });
  }

  definirComoCapa(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: {capa: true},
        json: true,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

  removerPerfil(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: {perfil: false},
        json: true,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.response);
      })
    });
  }

  definirComoPerfil(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        data: {perfil: true},
        json: true,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        console.log(err.response);
        reject(err.response);
      })
    });
  }

  removerImagem(id) {
    this.buscarToken();
    return new Promise((resolve, reject) => {
      axios({
        url: `${this.apiUrl}/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}` }
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err.response);
      });
    });
  }

}

export default ServicoImagemPromocao;