import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Grid, Icon, Typography } from '@material-ui/core';

class CardEstatistica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.titulo || '',
            texto: props.texto || '',
            adicional: props.adicional || '',
            link: props.link || '',
            icone: props.icone || 'place'
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            titulo: props.titulo || this.state.titulo,
            texto: props.texto || this.state.texto,
            adicional: props.adicional || this.state.adicional,
            link: props.link || this.state.link,
            icone: props.icone || this.state.icone
        });
    }
    renderLink = () => {
        if (this.state.link) {
            return (
                <Button component={Link} to={this.state.link} size="small">Detalhes</Button>
            );
        }
    }
    render() {
        return (
            <Card className="card-estatistica" elevation={3} style={{width: '100%', position: 'relative'}}>
                <div className={`div-topo ${this.props.color}`}></div>
                <CardContent style={{position: 'relative', marginBottom: '0', paddingBottom: '0'}}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <div style={{padding: '0 .5em'}}>
                                <Typography className="text-nowrap" variant="h4">{(this.state.titulo) ? this.state.titulo : '-'}</Typography>
                                <Typography className="text-nowrap">{(this.state.texto) ? this.state.texto : '-'}</Typography>
                                {(this.state.adicional) ? <Typography color="secondary">{this.state.adicional}</Typography> : '-'}
                            </div>
                        </Grid>
                        <Grid item style={{display: 'flex', justifyContent: 'center', alignItems: 'right'}}>
                            <div style={{padding: '.5em'}}>
                                <Icon className={`icone ${this.props.color}`}>{this.props.icone}</Icon>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <div style={{padding: '0px .5em'}}>
                        {this.renderLink()}
                    </div>
                </CardActions>
            </Card>
        );
    }
}

export default CardEstatistica;