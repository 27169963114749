import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoContato from '../../servicos/ServicoContato';
import { Types } from '../ducks/contatos';
const servico = new ServicoContato();

function* salvar (action) {
    try {
        const contato = yield call([servico, servico.atualizarContato], action.contato.id, action.contato);
        yield put({type: Types.SALVAR_SUCESSO, contato: contato.data, editar: true});
    } catch (e) {
        if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.SALVAR_ERRO, erro: e.data});
    }
}

function* buscarItem (action) {
    try {
        const contato = yield call([servico, servico.buscarContatoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, contato});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const contatos = yield call([servico, servico.buscarContatos], action.qtde, action.pagina);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, contatos});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerContato], action.contato.id);
        yield put({type: Types.REMOVER_SUCESSO, contato: action.contato});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaContatos () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
}

export default sagaContatos;