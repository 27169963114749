import React, { Component } from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

class CampoFormAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        <Autocomplete
          debug
          loading={this.props.carregando}
          loadingText="Carregando"
          value={this.props.value}
          options={this.props.options}
          getOptionLabel={this.props.getOptionLabel}
          onChange={this.props.onChange}
          noOptionsText="Nenhuma opção disponível"
          onInputChange={this.props.onInputChange || null}
          renderInput={params => <TextField {...params} label={this.props.label} fullWidth />}
        />
          {(this.props.erro || this.props.ajuda) ? 
            <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
            : ''}
      </FormControl>
    )
  }
}

export default CampoFormAutocomplete;