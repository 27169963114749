import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import Servico from '../../servicos/ServicoAdminEstabelecimento';
import { Types as AdminTypes } from '../ducks/adminsEstabelecimentos';
const servico = new Servico();

function* salvar (action) {
    if (action.admin.id) {
        try {
            const admin = yield call([servico, servico.atualizarAdmin], action.admin.id, action.admin);
            yield put({type: AdminTypes.SALVAR_SUCESSO, admin: admin.data, editar: true});
        } catch (e) {
            yield put({type: AdminTypes.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const admin = yield call([servico, servico.adicionarAdmin], action.admin);
            yield put({type: AdminTypes.SALVAR_SUCESSO, admin});
        } catch (e) {
            yield put({type: AdminTypes.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerAdmin], action.admin.id);
        yield put({type: AdminTypes.REMOVER_SUCESSO, admin: action.admin});
    } catch (e) {
        yield put({type: AdminTypes.REMOVER_ERRO, erro: e.message});
    }
}

function* saga () {
    yield takeLatest(AdminTypes.SALVAR_REQUEST, salvar);
    yield takeEvery(AdminTypes.REMOVER_REQUEST, remover);
}

export default saga;