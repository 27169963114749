import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoVantagemSite from '../../servicos/ServicoVantagemSite';
import { Types } from '../ducks/vantagensSite';
const servico = new ServicoVantagemSite();

function* salvar (action) {
    if (action.vantagem.id) {
        try {
            const vantagem = yield call([servico, servico.atualizarVantagem], action.vantagem.id, action.vantagem);
            yield put({type: Types.SALVAR_SUCESSO, vantagem: vantagem.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const vantagem = yield call([servico, servico.adicionarVantagem], action.vantagem);
            yield put({type: Types.SALVAR_SUCESSO, vantagem});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* reordenar (action) {
    try {
        yield call([servico, servico.reordenarVantagens], action.id, action.destino);
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REORDENAR_ERRO, erro: e.message});
     }
}

function* buscarItem (action) {
    try {
        const vantagem = yield call([servico, servico.buscarVantagemPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, vantagem});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const vantagens = yield call([servico, servico.buscarVantagens]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, vantagens});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerVantagem], action.vantagem.id);
        yield put({type: Types.REMOVER_SUCESSO, vantagem: action.vantagem});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaVantagens () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
    yield takeEvery(Types.REORDENAR_REQUEST, reordenar);
}

export default sagaVantagens;