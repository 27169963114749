import axios from 'axios';
class ServicoLogin {
  apiUrl = (process.env.REACT_APP_APIURL) ? `${process.env.REACT_APP_APIURL}/admin` : 'https://api.pontuafidelidade.com.br/api/admin';
  usuario = null;
  token = null;

  constructor() {
    this.token = (localStorage.getItem('token')) ? localStorage.getItem('token') : null;
    this.usuario = (localStorage.getItem('usuario')) ? JSON.parse(localStorage.getItem('usuario')) : null;
  }

  estaLogado() {
    return (this.token && this.usuario);
  }

  buscarToken() {
    return this.token;
  }

  buscarUsuario() {
    return this.usuario;
  }

  salvarToken(token) {
    localStorage.setItem('token', token);
    this.token = token;
  }

  salvarUsuario(usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
    this.usuario = usuario;
  }

  efetuarLogin(email, senha) {
    return new Promise((resolve, reject) => {
      axios.post(`${this.apiUrl}/auth/login`, {email, password: senha})
        .then(res => {
          this.salvarToken(res.data.access_token);
          resolve(this.token);
        }).catch(err => {
          if (!err.response) return reject({status: 'erro', message: 'Serviço indisponível'});
          return reject(err.response);
        });
    });
  }

  efetuarLogout() {
    localStorage.clear();
  }

  buscarUsuarioApi() {
    return new Promise((resolve, reject) => {
      if (!this.token) return reject(new Error('Usuário deve se autenticar primeiro.'));
      axios({
        url: `${this.apiUrl}/auth/me`,
        headers: { 'Authorization': `Bearer ${this.token}` }
      })
        .then(res => {
          this.salvarUsuario(res.data);
          return resolve(res.data);
        }).catch(err => {
          if (!err.response) return reject({status: 'erro', message: 'Serviço indisponível'});
          return reject(err.response);
        });
    });
  }

}

export default ServicoLogin;