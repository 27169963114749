import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';

// FORM
import CampoFormTexto from '../../geral/CampoFormTexto';
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, CircularProgress, Button,
  Card, CardActionArea, CardMedia
} from '@material-ui/core';

import { IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/depoimentosSite';

const styles = {
  overFlowVisible: {
    overflow: 'visible !important'
  },
  drawerNormal: {
    width: '500px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  drawerExtendido: {
    width: '768px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  btnFloatHover: {
    backgroundColor: 'white'
  },
  btnFloatNormal: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  },
  btnFloatExtendido: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%) rotate(180deg)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  }
};

class DrawerAdicionar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendido: false,
      imagem: ''
    }
  }
  extenderMenu = (e) => {
    e.preventDefault();
    this.setState({extendido: !this.state.extendido});
  }
  abrirDialogFoto = (e) => {
    this.refInputFoto.click();
  }
  uploadFoto = (e) => {
    this.setState({imagem: this.refInputFoto.files[0]}, () => {
      if (FileReader) {
        var fr = new FileReader();
        fr.onload = () => {
          this.props.alterarItem('imagem', fr.result);
        }
        fr.readAsDataURL(this.state.imagem);
      }
    });
  }
  salvar = (e) => {
    e.preventDefault();
    const depoimento = { ...this.props.item };
    if (depoimento.id) {
      if (depoimento.imagem) delete depoimento.imagem;
      this.props.salvar(depoimento);
    } else {
      const data = new FormData();
      if (this.state.imagem) data.append('imagem', this.state.imagem, this.state.imagem.name);
      data.append('nome', depoimento.nome);
      data.append('observacao', depoimento.observacao);
      data.append('texto', depoimento.texto);
      this.props.salvar(data);
    }
  }
  render() {
    return (
      <div role="presentation">
        <Drawer
          classes={{
            paper: (this.state.extendido) ? this.props.classes.drawerExtendido : this.props.classes.drawerNormal
          }}
          anchor="right"
          open={this.props.mostrar}
          onClose={e => this.props.esconderForm()}>
          <IconButton
            classes={{
              root: (this.state.extendido) ? this.props.classes.btnFloatExtendido : this.props.classes.btnFloatNormal
            }}
            size="small"
            onClick={this.extenderMenu}
            color="primary"
            aria-label="Extender">
              <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <form noValidate autoComplete="off" onSubmit={this.salvar} style={{padding: '1.5em', overflow: 'auto'}}>
            {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
            <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Adicionar depoimento</Typography>
            {/* NOME */}
            <CampoFormTexto
              required
              label="Nome (autor do depoimento)"
              value={this.props.item.nome}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.nome) ? this.props.erro.errors.nome[0] : ''}
              onChange={e => this.props.alterarItem('nome', e.target.value)} />
            {/* OBSERVACAO */}
            <CampoFormTexto
              label="Observação"
              value={this.props.item.observacao}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.observacao) ? this.props.erro.errors.observacao[0] : ''}
              onChange={e => this.props.alterarItem('observacao', e.target.value)} />
            {/* TEXTO */}
            <CampoFormTexto
              required
              multiline
              label="Texto (depoimento)"
              value={this.props.item.texto}
              erro={(this.props.erro && this.props.erro.errors && this.props.erro.errors.texto) ? this.props.erro.errors.texto[0] : ''}
              onChange={e => this.props.alterarItem('texto', e.target.value)} />
            {/* IMAGEM */}
            {(!this.props.item.id)
              ? <Button disabled={this.props.carregando} style={{marginTop: '1em', position: 'relative'}} onClick={this.abrirDialogFoto} variant="contained" color="primary">
                  Selecionar imagem
                  {(this.props.carregando) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary"/> : ''}
                </Button>
              : ''}
            <input onChange={this.uploadFoto} type="file" ref={e => this.refInputFoto = e} style={{display: 'none'}} />
            {(this.props.item.url || this.props.item.imagem)
              ? <Card>
                  <CardActionArea>
                    <CardMedia component="img" image={(this.props.item.url) ? this.props.item.url : this.props.item.imagem} title="Imagem" /> 
                  </CardActionArea>
                </Card>
              : ''}
            {(this.props.erro && this.props.erro.errors && this.props.erro.errors.imagem)
              ? <CampoFormErro titulo="Imagem" mensagem={this.props.erro.errors.imagem[0]} />
              : ''}
            {/* ERRO */}
            {(this.props.erro && this.props.erro.message)
              ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
              : ''}
            <div className="flex-end">
              <Button
                disabled={this.props.carregando}
                type="submit"
                style={{marginTop: '1em', position: 'relative'}}
                variant="contained"
                color="primary">
                Salvar
                {(this.props.carregando)
                  ? <CircularProgress
                    size={24}
                    color="secondary"
                    style={{position: 'absolute', top: '.5em', left: '2.2em'}} />
                  : ''}
              </Button>
            </div>
          </form>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.depoimentosSite.carregando,
    item: state.depoimentosSite.item,
    mostrar: state.depoimentosSite.mostrarForm,
    erro: state.depoimentosSite.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (depoimento) => dispatch(salvar(depoimento)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerAdicionar)));