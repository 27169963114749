import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ErroForm from '../../ErroForm';
import moment from 'moment';

// FORM
import CampoFormErro from '../../geral/CampoFormErro';

import {
  Drawer, Typography, CircularProgress, Button,
  Table, TableBody, TableRow, TableCell
} from '@material-ui/core';

import { IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  alterarItem,
  esconderForm,
  mostrarForm,
  salvar
} from '../../../store/ducks/contatos';

const styles = {
  overFlowVisible: {
    overflow: 'visible !important'
  },
  drawerNormal: {
    width: '500px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  drawerExtendido: {
    width: '768px',
    maxWidth: '90%',
    transition: 'all 150ms',
    overflow: 'visible'
  },
  btnFloatHover: {
    backgroundColor: 'white'
  },
  btnFloatNormal: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  },
  btnFloatExtendido: {
    position: 'absolute',
    top: '64px',
    left: '0',
    transform: 'translate(-50%) rotate(180deg)',
    backgroundColor: 'white',
    zIndex: '2',
    border: '1px solid rgba(20, 20, 20, .16)'
  }
};

class DrawerLer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendido: false
    }
  }
  extenderMenu = (e) => {
    e.preventDefault();
    this.setState({extendido: !this.state.extendido});
  }
  salvar = (e) => {
    e.preventDefault();
    const contato = { ...this.props.item };
    contato['lido'] = !this.props.item.lido;
    this.props.salvar(contato);
  }
  render() {
    return (
      <div role="presentation">
        <Drawer
          classes={{
            paper: (this.state.extendido) ? this.props.classes.drawerExtendido : this.props.classes.drawerNormal
          }}
          anchor="right"
          open={this.props.mostrar}
          onClose={e => this.props.esconderForm()}>
          <IconButton
            classes={{
              root: (this.state.extendido) ? this.props.classes.btnFloatExtendido : this.props.classes.btnFloatNormal
            }}
            size="small"
            onClick={this.extenderMenu}
            color="primary"
            aria-label="Extender">
              <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <div style={{padding: '1.5em', overflow: 'auto'}}>
            {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
            <Typography style={{marginTop: '.5em', marginBottom: '.5em'}} variant="h5">Sobre o contato</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Data/hora</strong>
                  </TableCell>
                  <TableCell>{moment(this.props.item.created_at).format('LLL')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Nome</strong>
                  </TableCell>
                  <TableCell>{this.props.item.nome}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>E-mail</strong>
                  </TableCell>
                  <TableCell>{this.props.item.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Telefone</strong>
                  </TableCell>
                  <TableCell>{this.props.item.telefone || '-'}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell>
                  <strong>Mensagem</strong>
                </TableCell>
                <TableCell>{this.props.item.mensagem || '-'}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
            {/* ERRO */}
            {(this.props.erro && this.props.erro.message)
              ? <CampoFormErro titulo="Erro" mensagem="Os dados fornecidos são inválidos. Verifique os campos do formulário e tente novamente." />
              : ''}
            <div className="flex-end">
              <Button
                onClick={this.salvar}
                disabled={this.props.carregando}
                style={{marginTop: '1em', position: 'relative'}}
                variant="contained"
                color="primary">
                {(this.props.item.lido) ? 'Marcar como não lido' : 'Marcar como lido'}
                {(this.props.carregando)
                  ? <CircularProgress
                    size={24}
                    color="secondary"
                    style={{position: 'absolute', top: '.5em', left: 'calc(50% - 1em)'}} />
                  : ''}
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.contatos.carregando,
    item: state.contatos.item,
    mostrar: state.contatos.mostrarForm,
    erro: state.contatos.erro
  }
}
const mapDispatchToProps = dispatch => {
  return {
    alterarItem: (campo, valor) => dispatch(alterarItem(campo, valor)),
    salvar: (contato) => dispatch(salvar(contato)),
    esconderForm: () => dispatch(esconderForm()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerLer)));