import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import HeaderForm from '../../HeaderForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import ErroForm from '../../ErroForm';

import { Paper, Button, Table,
  TableHead, TableRow, TableCell,
  TableBody, IconButton, Icon,
  CircularProgress, Menu, MenuItem,
  Typography
} from '@material-ui/core';

import {
  iniciarEditar,
  mostrarForm,
  remover
} from '../../../store/ducks/produtos';

class TabProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null,
      produto: null
    }
  }

  mostrar = (produto, e) => {
    if (e) e.preventDefault();
    this.props.irParaDetalhes(produto.id);
  }

  editar = (e) => {
    if (e) e.preventDefault();
    this.props.iniciarEditar(this.state.produto);
    this.setState({anchorMenu: null});
  }

  iniciarRemover = (e) => {
    if (e) e.preventDefault();
    this.setState({anchorMenu: null}, () => {
      this.refs.confirmacaoRemover.mostrar(this.state.produto);
    });
  }

  renderProdutos = () => {
    const itens = this.props.itens.sort((a, b) => {
      return (a.pontos < b.pontos) ? 1 : -1;
    });
    return itens.map((produto, index) => {
      return (
        <TableRow key={produto.id}>
          <TableCell padding="none">{index + 1}</TableCell>
          <TableCell>{produto.nome}</TableCell>
          <TableCell>{(produto.tipo_pontuacao === 'pontos') ? 'Pontuação' : 'Quantidade'}</TableCell>
          <TableCell>{(produto.tipo_pontuacao === 'pontos') ? produto.pontos : produto.quantidade}</TableCell>
          <TableCell>{(produto.ativo) ? 'Ativo' : '-'}</TableCell>
          <TableCell padding="none" align="right">
            <Button
                className="mb-0"
                variant="contained"
                color="primary"
                disabled={produto.carregando}
                size="small" onClick={e => this.mostrar(produto)}>
                Detalhes
                {(produto.carregando)
                    ? <CircularProgress color="primary" size={24} style={{position: 'absolute', right: '2em', top: '.2em'}} />
                    : ''}
            </Button>
            <IconButton
                size="small"
                color="default"
                onClick={e => this.setState({anchorMenu: e.currentTarget, produto})}>
                <Icon>more_vert</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Prêmios do estabelecimento"/>
        {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
        <Button onClick={this.props.mostrarForm} style={{marginTop: '1em'}} variant="contained" color="primary">Adicionar</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Qtde/Pontos</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell padding="none" align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderProdutos()}
            {(!this.props.itens || !this.props.itens.length)
            ? <TableRow>
                <TableCell padding="none"></TableCell>
                <TableCell colSpan={5}>
                    <Typography>Nenhum prêmio cadastrado.</Typography>
                </TableCell>
            </TableRow>
            : ''}
          </TableBody>
        </Table>
        {/* MENU ITEM */}
        <Menu id="menu-produtos"
            anchorEl={this.state.anchorMenu}
            keepMounted
            open={Boolean(this.state.anchorMenu)}
            onClose={e => this.setState({anchorMenu: null})}>
              <MenuItem onClick={(e) => this.editar(e)}>Editar</MenuItem>
              <MenuItem onClick={(e) => this.iniciarRemover(e)}>Remover</MenuItem>
        </Menu>
        {/* MODAIS */}
        <ModalConfirmacao
          titulo="Remover o prêmio?"
          ref="confirmacaoRemover"
          aoConfirmar={produto => this.props.remover(produto)}>
          Você tem certeza que deseja remover o prêmio?
        </ModalConfirmacao>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    itens: state.produtos.itens,
    item: state.produtos.item,
    erro: state.produtos.erro
  }
}

const mapDispatchToProps = dispatch => {
  return {
    iniciarEditar: (item) => dispatch(iniciarEditar(item)),
    remover: (produto) => dispatch(remover(produto)),
    mostrarForm: () => dispatch(mostrarForm()),
    irParaDetalhes: (id) => dispatch(push(`/produtos/ver/${id}`))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabProdutos);