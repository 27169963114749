import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import CampoFormErro from '../componentes/geral/CampoFormErro';
import DrawerPerguntas from '../componentes/perguntasFrequentesSite/DrawerAdicionar';
import TabelaPerguntas from '../componentes/perguntasFrequentesSite/TabelaPerguntas';

// REDUX
import {
  alterarLayout,
  buscarTodos,
  mostrarForm
} from '../store/ducks/perguntasFrequentesSite';

class PaginaPerguntasFrequentesSite extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
  }

  render () {
    return (
        <div>
          {/* ERRO */}
          {(this.props.erro) ? <CampoFormErro titulo="Erro" mensagem={this.props.erro}></CampoFormErro> : ''}
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              {/* ADICIONAR */}
              <Button color="primary" variant="contained" onClick={this.props.mostrarForm}>Adicionar</Button>
            </Grid>
          </Grid>
          <DrawerPerguntas />
          <TabelaPerguntas />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.perguntasFrequentesSite.carregando,
    erro: state.perguntasFrequentesSite.erro,
    layout: state.perguntasFrequentesSite.layout,
    selecionados: state.perguntasFrequentesSite.selecionados
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    buscarTodos: () => dispatch(buscarTodos()),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaPerguntasFrequentesSite));