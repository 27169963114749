import React, { Component } from 'react';
import {
  Card, CardActionArea, CardMedia,
  CardContent, Typography, CardActions, Button,
  LinearProgress
} from '@material-ui/core';

class CardPreviewEstabelecimento extends Component {
  render() {
    return (
      <div style={{position: 'relative'}}>
        {(this.props.carregando) ? <LinearProgress color="secondary" style={{zIndex: '1', position: 'absolute', top: '0', width: '100%'}} /> : ''}
        <Card elevation={4} style={{marginBottom: '1em', width: '100%'}}>
            <CardMedia style={{paddingBottom: '1em'}}>
                <div className="preview-estabelecimento">
                    {(this.props.estabelecimento.capa)
                        ? <img className="preview-capa-estabelecimento" src={this.props.estabelecimento.capa.url} />
                        : <img className="preview-capa-estabelecimento" src="/assets/imagens/placeholder.png" />}
                    <div className="preview-perfil-estabelecimento">
                    {(this.props.estabelecimento.perfil)
                        ? <img src={this.props.estabelecimento.perfil.url} />
                        : <img src="/assets/imagens/placeholder.png" />}
                    </div>
                </div>
            </CardMedia>
            <CardContent>
                <Typography
                    style={{textAlign: 'center', marginTop: '1em'}}
                    variant="h5"
                    component="h3">
                        {(this.props.estabelecimento.nome_fantasia)
                            ? this.props.estabelecimento.nome_fantasia
                            : this.props.estabelecimento.razao_social}
                </Typography>
                <Typography
                    style={{textAlign: 'center', marginTop: '1em'}}
                    variant="body2"
                    color="textSecondary"
                    component="p">Esta é uma pré visualização do estabelecimento. Ele será exibido desta forma no app do cliente.</Typography>
            </CardContent>
            <CardActions>
                {(this.props.estabelecimento && this.props.estabelecimento.capa)
                    ? <Button
                        style={{width: '50%'}}
                        onClick={e => this.props.aoRemoverCapa()}
                        size="small"
                        color="secondary">Remover capa</Button>
                    : ''}
                {(this.props.estabelecimento && this.props.estabelecimento.perfil)
                    ? <Button
                        style={{width: '50%'}}
                        onClick={e => this.props.aoRemoverPerfil()}
                        size="small"
                        color="secondary">Remover perfil</Button>
                    : ''}
            </CardActions>
        </Card>
      </div>
    )
  }
}

export default CardPreviewEstabelecimento;