import React from 'react';
import Button from '@material-ui/core/Button';

import CampoFormTexto from '../componentes/geral/CampoFormTexto'

class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      senha: '',
      desabilitado: false,
      erroEmail: false,
      erroSenha: false
    };
  }

  componentWillReceiveProps(props) {
    if (typeof props.desabilitado !== 'undefined') this.setState({ desabilitado: props.desabilitado })
  }

  efetuarLogin = (e) => {
    e.preventDefault();
    this.setState({ erroEmail: this.state.email.length === 0, erroSenha: this.state.senha.length === 0 }, () => {
      if (!this.state.erroEmail && !this.state.erroSenha) {
        this.props.aoLogin(this.state.email, this.state.senha);
      }
    });
  }

  render() {
    return (
      <form noValidate autoComplete="off" onSubmit={e => this.efetuarLogin(e)}>
        <CampoFormTexto
          required
          label="E-mail"
          value={this.state.email}
          onChange={e => this.setState({ email: e.target.value })}
          erro={(this.state.erroEmail) ? 'Por favor informe um e-mail válido' : ''} />
        <CampoFormTexto
          required
          type="password"
          label="Senha"
          value={this.state.senha}
          onChange={e => this.setState({ senha: e.target.value })}
          erro={(this.state.erroSenha) ? 'Por favor informe a senha' : ''} />
        <Button
          disabled={this.state.desabilitado}
          type="submit"
          style={{width: '100%', marginTop: '1em'}}
          variant="contained"
          color="primary">
            Login
          </Button>
      </form>
    )
  }
}

export default FormLogin;