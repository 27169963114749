const stateInicial = {
    carregando: false,
    erro: '',
    layout: 'tabela',
    pagina: 1,
    paginas: 1,
    qtde: 25,
    itens: [],
    item: {},
    selecionados: [],
    linkExportacao: ''
}

export const Types = {
    // ALTERACOES
    ALTERAR_PAGINA: 'inscricoes/ALTERAR_PAGINA',
    ALTERAR_LAYOUT: 'inscricoes/ALTERAR_LAYOUT',
    ADICIONAR_SELECAO: 'inscricoes/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'inscricoes/REMOVER_SELECAO',
    SELECIONAR_TODOS: 'inscricoes/SELECIONAR_TODOS',
    DESSELECIONAR_TODOS: 'inscricoes/DESSELECIONAR_TODOS',
    // EXPORTAR
    EXPORTAR_REQUEST: 'inscricoes/EXPORTAR_REQUEST',
    EXPORTAR_SUCESSO: 'inscricoes/EXPORTAR_SUCESSO',
    EXPORTAR_ERRO: 'inscricoes/EXPORTAR_ERRO',
    // PESQUISAR
    PESQUISAR_REQUEST: 'inscricoes/PESQUISAR_REQUEST',
    PESQUISAR_SUCESSO: 'inscricoes/PESQUISAR_SUCESSO',
    PESQUISAR_ERRO: 'inscricoes/PESQUISAR_ERRO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'inscricoes/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'inscricoes/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'inscricoes/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'inscricoes/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'inscricoes/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'inscricoes/BUSCAR_ITEM_ERRO',
    // REMOVER
    REMOVER_REQUEST: 'inscricoes/REMOVER_REQUEST',
    REMOVER_SUCESSO: 'inscricoes/REMOVER_SUCESSO',
    REMOVER_ERRO: 'inscricoes/REMOVER_ERRO'
}

export default function reducer(state = stateInicial, action) {
    switch(action.type) {
        case Types.ALTERAR_PAGINA: {
            if (!isNaN(action.pagina)) {
                return {...state, pagina: action.pagina}
            }
            return {...state};
        }
        case Types.ALTERAR_LAYOUT: {
            if (['tabela', 'lista'].indexOf(action.layout) > -1) {
                return {...state, layout: action.layout};
            }
            return {...state};
        }
        case Types.PESQUISAR_REQUEST: {
            return {
                ...state,
                pagina: 1,
                qtde: 25,
                erro: ''
            }
        }
        case Types.PESQUISAR_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                itens: action.inscricoes.data,
                paginas: action.inscricoes.meta.last_page
            }
            return novoState;
        }
        case Types.PESQUISAR_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.inscricao
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.EXPORTAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: '',
                linkExportacao: ''
            }
        }
        case Types.EXPORTAR_SUCESSO: {
            console.log(action);
            const novoState = {
                ...state,
                carregando: false,
                linkExportacao: action.link
            }
            return novoState;
        }
        case Types.EXPORTAR_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                itens: action.inscricoes.data,
                paginas: action.inscricoes.meta.last_page
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.inscricao);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(inscricao => inscricao.id !== action.inscricao.id);
            return {...state, selecionados};
        }
        case Types.SELECIONAR_TODOS: {
            const selecionados = [...state.itens];
            return {...state, selecionados};
        }
        case Types.DESSELECIONAR_TODOS: {
            const selecionados = [];
            return {...state, selecionados};
        }
        case Types.REMOVER_REQUEST: {
            const itens = [...state.itens];
            itens.map((inscricao, i) => {
                if (inscricao.id === action.inscricao.id) {
                    return itens[i].removendo = true;
                }
                return null;
            });
            return {...state, itens};
        }
        case Types.REMOVER_SUCESSO: {
            let itens = [...state.itens];
            let selecionados = [...state.selecionados];
            itens = itens.filter((inscricao) => inscricao.id !== action.inscricao.id)
            selecionados = selecionados.filter((inscricao) => inscricao.id !== action.inscricao.id)
            return {...state, itens, selecionados};
        }
        case Types.REMOVER_ERRO: {
            let itens = [...state.itens];
            itens.map((inscricao, i) => {
                return itens[i].removendo = false;
            });
            return {...state, erro: action.erro, itens};
        }
        default: return state;
    }
}

export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
}

export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25) {
    return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function adicionarSelecao(inscricao) {
    return { type: Types.ADICIONAR_SELECAO, inscricao }
}

export function removerSelecao(inscricao) {
    return { type: Types.REMOVER_SELECAO, inscricao }
}

export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(inscricao) {
    return { type: Types.REMOVER_REQUEST, inscricao }
}

export function pesquisar(pesquisa, pagina = 1) {
    return { type: Types.PESQUISAR_REQUEST, pesquisa, pagina }
}

export function alterarPagina(pagina) {
    return { type: Types.ALTERAR_PAGINA, pagina }
}

export function exportar() {
    return { type: Types.EXPORTAR_REQUEST }
}