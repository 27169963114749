import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderForm from '../../HeaderForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import ErroForm from '../../ErroForm';

import { Paper, Button, Table,
  TableHead, TableRow, TableCell,
  TableBody, IconButton, Icon,
  CircularProgress
} from '@material-ui/core';

import {
  mostrarForm
} from '../../../store/ducks/adminsEstabelecimentos';

import {
  iniciarEditar,
  remover
} from '../../../store/ducks/adminsEstabelecimentos';

class TabAdmins extends Component {
  renderAdmins = () => {
    return this.props.itens.map((admin, index) => {
      return (
        <TableRow key={admin.id}>
          <TableCell padding="none">{index + 1}</TableCell>
          <TableCell>{admin.nome}</TableCell>
          <TableCell>{admin.email}</TableCell>
          <TableCell>{(admin.funcao) ? admin.funcao.nome : ''}</TableCell>
          <TableCell padding="none">
            <IconButton onClick={e => this.props.iniciarEditar(admin)} color="default" aria-label="Editar">
              <Icon>edit</Icon>
            </IconButton>
            <IconButton disabled={(admin.carregando) ? true : false} style={{position: 'relative'}} onClick={() => this.refs.confirmacaoRemover.mostrar(admin)} color="secondary" aria-label="Remover">
              <Icon>delete</Icon>
              {(admin.carregando) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary" /> : ''}
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Admins do estabelecimento"/>
        {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
        <Button onClick={this.props.mostrarForm} style={{marginTop: '1em'}} variant="contained" color="primary">Adicionar</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Função</TableCell>
              <TableCell padding="none">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderAdmins()}
          </TableBody>
        </Table>
        <ModalConfirmacao
          titulo="Remover o admin?"
          ref="confirmacaoRemover"
          aoConfirmar={admin => this.props.remover(admin)}>
          Você tem certeza que deseja remover o admin?
        </ModalConfirmacao>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    itens: state.adminsEstabelecimentos.itens,
    item: state.estabelecimentos.item,
    erro: state.estabelecimentos.erro
  }
}

const mapDispatchToProps = dispatch => {
  return {
    iniciarEditar: (item) => dispatch(iniciarEditar(item)),
    remover: (admin) => dispatch(remover(admin)),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabAdmins);