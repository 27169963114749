import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoLogin from '../../servicos/ServicoLogin';
import { Types as AuthTypes } from '../ducks/auth';
const servicoLogin = new ServicoLogin();


function* efetuarLogin(action) {
    try {
        const usuario = yield call([servicoLogin, servicoLogin.efetuarLogin], action.email, action.senha);
        yield put({type: AuthTypes.LOGIN_SUCESSO, usuario});
        yield put(push('/'));
     } catch (e) {
        yield put({type: AuthTypes.LOGIN_ERRO, erro: e.mensagem});
     }
}

function* sagaAuth() {
    yield takeLatest(AuthTypes.LOGIN_REQUEST, efetuarLogin);
}

export default sagaAuth;