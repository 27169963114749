import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoResgate';
import { Types } from '../ducks/estatisticasVouchers';
const servico = new Servico();

function* buscarItem (action) {
    try {
        const estatisticas = yield call([servico, servico.buscarResgate], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, estatisticas});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
}

export default saga;