const novoItem = {
  id: '',
  nome: '',
  email: '',
  senha: '',
  funcoes_id: '',
  funcoes: []
}

const stateInicial = {
  carregando: false,
  desabilitado: false,
  erro: '',
  layout: 'tabela',
  pagina: 1,
  paginas: 1,
  qtde: 25,
  admins: [],
  selecionados: [],
  mostrarForm: false,
  deveAlterarSenha: false,
  item: {...novoItem},
}

export const Types = {
  // ALTERACOES
  ALTERAR_LAYOUT: 'admins/ALTERAR_LAYOUT',
  ALTERAR_PAGINA: 'admins/ALTERAR_PAGINA',
  ALTERAR_QTDE: 'admins/ALTERAR_QTDE',
  ALTERAR_ALTERAR_SENHA: 'admins/ALTERAR_ALTERAR_SENHA',
  ALTERAR_ITEM: 'admins/ALTERAR_ITEM',
  MOSTRAR_FORM: 'admins/MOSTRAR_FORM',
  ESCONDER_FORM: 'admins/ESCONDER_FORM',
  ADICIONAR_SELECAO: 'admins/ADICIONAR_SELECAO',
  REMOVER_SELECAO: 'admins/REMOVER_SELECAO',
  SELECIONAR_TODOS: 'admins/SELECIONAR_TODOS',
  DESSELECIONAR_TODOS: 'admins/DESSELECIONAR_TODOS',
  DEFINIR_ITEM: 'admins/DEFINIR_ITEM',
  INICIAR_EDITAR: 'admins/INICIAR_EDITAR',
  // SALVAR
  SALVAR_REQUEST: 'admins/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'admins/SALVAR_SUCESSO',
  SALVAR_ERRO: 'admins/SALVAR_ERRO',
  // PESQUISAR
  PESQUISAR_REQUEST: 'admins/PESQUISAR_REQUEST',
  PESQUISAR_SUCESSO: 'admins/PESQUISAR_SUCESSO',
  PESQUISAR_ERRO: 'admins/PESQUISAR_ERRO',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'admins/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'admins/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'admins/BUSCAR_TODOS_ERRO',
  // ITEM
  BUSCAR_ITEM_REQUEST: 'admins/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'admins/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'admins/BUSCAR_ITEM_ERRO',
  // REMOVER
  REMOVER_REQUEST: 'admins/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'admins/REMOVER_SUCESSO',
  REMOVER_ERRO: 'admins/REMOVER_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
      case Types.ALTERAR_PAGINA: {
          if (!isNaN(action.pagina)) {
              return {...state, pagina: action.pagina}
          }
          return {...state};
      }
      case Types.ALTERAR_QTDE: {
          if (!isNaN(action.qtde)) {
              return {...state, qtde: action.qtde, pagina: 1}
          }
          return {...state};
      }
      case Types.ALTERAR_LAYOUT: {
          if (['tabela', 'lista'].indexOf(action.layout) > -1) {
              return {...state, layout: action.layout};
          }
          return {...state};
      }
      case Types.ALTERAR_ALTERAR_SENHA: {
        return {...state, deveAlterarSenha: action.valor}
      }
      case Types.ESCONDER_FORM: {
        const novoState = {...state, mostrarForm: false}
        return novoState;
      }
      case Types.MOSTRAR_FORM: {
        return {...state, mostrarForm: true, item: {...novoItem}, erro: ''}
      }
      case Types.ALTERAR_ITEM: {
          const item = {...state.item}
          item[action.campo] = action.valor;
          return {...state, item, erro: ''}
      }
      case Types.DEFINIR_ITEM: {
          return {
              ...state,
              item: action.item
          }
      }
      case Types.INICIAR_EDITAR: {
        const item = {...action.admin};
        item.funcoes_id = item.funcao.id
        return {
          ...state,
          erro: '',
          item,
          mostrarForm: true
        }
      }
      case Types.SALVAR_REQUEST: {
          return {
              ...state,
              carregando: true,
              desabilitado: true,
              erro: ''
          }
      }
      case Types.SALVAR_SUCESSO: {
        let novoState = {
          ...state,
          carregando: false,
          mostrarForm: false
        }
        if (action.editar) {
          const admins = [...state.admins];
          admins.map((item, i) => {
            if (item.id === action.admin.id) return admins[i] = action.admin;
            return null;
          });
          novoState = {...novoState, admins };
        } else {
          novoState = {...novoState, admins: [...state.admins, action.admin]};
        }
        return novoState;
      }
      case Types.SALVAR_ERRO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              erro: action.erro
          }
      }
      case Types.PESQUISAR_REQUEST: {
          return {
              ...state,
              pagina: 1,
              qtde: 25,
              erro: ''
          }
      }
      case Types.PESQUISAR_SUCESSO: {
          const novoState = {
              ...state,
              carregando: false,
              admins: action.admins.data,
              paginas: action.admins.meta.last_page
          }
          return novoState;
      }
      case Types.PESQUISAR_ERRO: {
          const novoState = {
              ...state,
              carregando: false,
              erro: action.erro
          }
          return novoState;
      }
      case Types.BUSCAR_ITEM_REQUEST: {
          return {
              ...state,
              carregando: true,
              desabilitado: true,
              erro: ''
          }
      }
      case Types.BUSCAR_ITEM_SUCESSO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              item: action.admin
          }
      }
      case Types.BUSCAR_ITEM_ERRO: {
          return {
              ...state,
              carregando: false,
              desabilitado: false,
              erro: action.erro
          }
      }
      case Types.BUSCAR_TODOS_REQUEST: {
          return {
              ...state,
              carregando: true,
              erro: ''
          }
      }
      case Types.BUSCAR_TODOS_SUCESSO: {
          const novoState = {
              ...state,
              carregando: false,
              admins: (state.layout === 'lista')
                  ? [...state.admins].concat(action.admins.data)
                  : action.admins.data,
              paginas: action.admins.meta.last_page
          }
          return novoState;
      }
      case Types.BUSCAR_TODOS_ERRO: {
          const novoState = {
              ...state,
              carregando: false,
              erro: action.erro
          }
          return novoState;
      }
      case Types.ADICIONAR_SELECAO: {
          const selecionados = [...state.selecionados];
          selecionados.push(action.admin);
          return {...state, selecionados};
      }
      case Types.REMOVER_SELECAO: {
          let selecionados = [...state.selecionados];
          selecionados = selecionados.filter(admin => admin.id !== action.admin.id);
          return {...state, selecionados};
      }
      case Types.SELECIONAR_TODOS: {
          const selecionados = [...state.admins];
          return {...state, selecionados};
      }
      case Types.DESSELECIONAR_TODOS: {
          const selecionados = [];
          return {...state, selecionados};
      }
      case Types.REMOVER_REQUEST: {
          const admins = [...state.admins];
          admins.map((admin, i) => {
              if (admin.id === action.admin.id) {
                  return admins[i].removendo = true;
              }
              return null;
          });
          return {...state, admins};
      }
      case Types.REMOVER_SUCESSO: {
          let admins = [...state.admins];
          let selecionados = [...state.selecionados];
          admins = admins.filter((admin) => admin.id !== action.admin.id)
          selecionados = selecionados.filter((admin) => admin.id !== action.admin.id)
          return {...state, admins, selecionados};
      }
      case Types.REMOVER_ERRO: {
          let admins = [...state.admins];
          admins.map((admin, i) => {
              return admins[i].removendo = false;
          });
          return {...state, erro: action.erro, admins};
      }
      default: return state;
  }
}

export function alterarLayout(layout) {
  return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
  return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
  return { type: Types.ALTERAR_PAGINA, pagina }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor }
}

export function alterarSenha(valor) {
  return { type: Types.ALTERAR_ALTERAR_SENHA, valor }
}

export function pesquisar(pesquisa) {
  return { type: Types.PESQUISAR_REQUEST, pesquisa }
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 50) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function adicionarSelecao(admin) {
  return { type: Types.ADICIONAR_SELECAO, admin }
}

export function removerSelecao(admin) {
  return { type: Types.REMOVER_SELECAO, admin }
}

export function selecionarTodos() {
  return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
  return { type: Types.DESSELECIONAR_TODOS }
}

export function remover(admin) {
  return { type: Types.REMOVER_REQUEST, admin }
}

export function definirNovoItem(item) {
  return { type: Types.DEFINIR_ITEM, item }
}

export function salvar(admin) {
  return { type: Types.SALVAR_REQUEST, admin }
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item }
}

export function mostrarForm() {
    return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
    return { type: Types.ESCONDER_FORM };
}

export function iniciarEditar(admin) {
    return { type: Types.INICIAR_EDITAR, admin }
}