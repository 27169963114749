import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ServicoSlideSite from '../../servicos/ServicoSlideSite';
import { Types } from '../ducks/slidesSite';
const servico = new ServicoSlideSite();

function* salvar (action) {
    if (action.slide.id) {
        try {
            const slide = yield call([servico, servico.atualizarSlide], action.slide.id, action.slide);
            yield put({type: Types.SALVAR_SUCESSO, slide: slide.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const slide = yield call([servico, servico.adicionarSlide], action.slide);
            yield put({type: Types.SALVAR_SUCESSO, slide});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* reordenar (action) {
    try {
        yield call([servico, servico.reordenarSlides], action.id, action.destino);
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REORDENAR_ERRO, erro: e.message});
     }
}

function* buscarItem (action) {
    try {
        const slide = yield call([servico, servico.buscarSlidePorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, slide});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const slides = yield call([servico, servico.buscarSlides]);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, slides});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerSlide], action.slide.id);
        yield put({type: Types.REMOVER_SUCESSO, slide: action.slide});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
     }
}

function* sagaSlides () {
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
    yield takeEvery(Types.REORDENAR_REQUEST, reordenar);
}

export default sagaSlides;