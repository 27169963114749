import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export default class ModalConfirmacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrar: false,
      dados: null
    }
  }
  esconder = () => {
    this.setState({mostrar: false});
  }
  mostrar = (dados) => {
    this.setState({mostrar: true, dados});
  }
  confirmar = () => {
    this.props.aoConfirmar(this.state.dados);
    this.esconder();
  }
  render () {
    return (
      <Dialog open={this.state.mostrar}>
        <DialogTitle>{this.props.titulo || 'Você tem certeza?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.esconder} color="primary">{this.props.textoCancelar || 'Cancelar'}</Button>
          <Button onClick={this.confirmar} color="primary" variant="contained">{this.props.textoConfirmar || 'Confirmar'}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}