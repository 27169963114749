const novoItem = {
  id: '',
  nome: '',
  periodo: 'MONTHLY',
  valor: 1.00,
  taxa_inscricao: 0.00,
  dias_teste: 0,
  descricao: '',
  id_remoto: null
}

const stateInicial = {
  item: {...novoItem},
  layout: 'tabela',
  pagina: 1,
  paginas: 1,
  qtde: 25,
  itens: [],
  selecionados: [],
  mostrarForm: false,
  carregando: false,
  erro: '',
  desabilitado: false
}

export const Types = {
  // ALTERACOES
  ALTERAR_LAYOUT: 'planos/ALTERAR_LAYOUT',
  ALTERAR_PAGINA: 'planos/ALTERAR_PAGINA',
  ALTERAR_QTDE: 'planos/ALTERAR_QTDE',
  ADICIONAR_SELECAO: 'planos/ADICIONAR_SELECAO',
  REMOVER_SELECAO: 'planos/REMOVER_SELECAO',
  SELECIONAR_TODOS: 'planos/SELECIONAR_TODOS',
  DESSELECIONAR_TODOS: 'planos/DESSELECIONAR_TODOS',
  ALTERAR_ITEM: 'planos/ALTERAR_ITEM',
  DEFINIR_ITEM: 'planos/DEFINIR_ITEM',
  MOSTRAR_FORM: 'planos/MOSTRAR_FORM',
  ESCONDER_FORM: 'planos/ESCONDER_FORM',
  INICIAR_EDITAR: 'planos/INICIAR_EDITAR',
  DEFINIR_ITENS: 'planos/DEFINIR_ITENS',
  SALVAR_REQUEST: 'planos/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'planos/SALVAR_SUCESSO',
  SALVAR_ERRO: 'planos/SALVAR_ERRO',
  REMOVER_REQUEST: 'planos/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'planos/REMOVER_SUCESSO',
  REMOVER_ERRO: 'planos/REMOVER_ERRO',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'planos/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'planos/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'planos/BUSCAR_TODOS_ERRO',
  // ITEM
  BUSCAR_ITEM_REQUEST: 'planos/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'planos/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'planos/BUSCAR_ITEM_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_PAGINA: {
        if (!isNaN(action.pagina)) {
            return {...state, pagina: action.pagina}
        }
        return {...state};
    }
    case Types.ALTERAR_QTDE: {
        if (!isNaN(action.qtde)) {
            return {...state, qtde: action.qtde, pagina: 1}
        }
        return {...state};
    }
    case Types.ALTERAR_LAYOUT: {
        if (['tabela', 'lista'].indexOf(action.layout) > -1) {
            return {...state, layout: action.layout};
        }
        return {...state};
    }
    case Types.ESCONDER_FORM: {
      return {...state, mostrarForm: false}
    }
    case Types.MOSTRAR_FORM: {
      return {...state, mostrarForm: true, erro: '', item: {...novoItem}}
    }
    case Types.ALTERAR_ITEM: {
      const item = {...state.item}
      item[action.campo] = action.valor;
      return {...state, item, erro: ''};
    }
    case Types.ADICIONAR_SELECAO: {
        const selecionados = [...state.selecionados];
        selecionados.push(action.plano);
        return {...state, selecionados};
    }
    case Types.REMOVER_SELECAO: {
        let selecionados = [...state.selecionados];
        selecionados = selecionados.filter(plano => plano.id !== action.plano.id);
        return {...state, selecionados};
    }
    case Types.SELECIONAR_TODOS: {
        const selecionados = [...state.itens];
        return {...state, selecionados};
    }
    case Types.DESSELECIONAR_TODOS: {
        const selecionados = [];
        return {...state, selecionados};
    }
    case Types.BUSCAR_TODOS_REQUEST: {
        return {
            ...state,
            carregando: true,
            erro: ''
        }
    }
    case Types.BUSCAR_TODOS_SUCESSO: {
        const novoState = {
            ...state,
            carregando: false,
            itens: (state.layout === 'lista')
                ? [...state.itens].concat(action.planos.data)
                : action.planos.data,
            paginas: action.planos.meta.last_page
        }
        return novoState;
    }
    case Types.BUSCAR_TODOS_ERRO: {
        const novoState = {
            ...state,
            carregando: false,
            erro: action.erro
        }
        return novoState;
    }
    case Types.BUSCAR_ITEM_REQUEST: {
      return {
        ...state,
        carregando: true,
        desabilitado: true,
        erro: ''
      }
    }
    case Types.BUSCAR_ITEM_SUCESSO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        item: action.plano
      }
    }
    case Types.BUSCAR_ITEM_ERRO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        erro: action.erro
      }
    }
    case Types.DEFINIR_ITEM: {
      return {...state, item: action.item}
    }
    case Types.DEFINIR_ITENS: {
      return {...state, itens: action.itens}
    }
    case Types.SALVAR_REQUEST: {
      return {
        ...state,
        carregando: true,
        erro: ''
      }
    }
    case Types.SALVAR_SUCESSO: {
      let novoState = {
        ...state,
        carregando: false,
        mostrarForm: false,
        item: action.plano
      }
      if (action.editar) {
        const itens = [...state.itens];
        itens.map((item, i) => {
          if (item.id === action.plano.id) return itens[i] = action.plano;
          return null;
        });
        novoState = {...novoState, itens };
      } else {
        novoState = {...novoState, itens: [...state.itens, action.plano]};
      }
      return novoState;
    }
    case Types.SALVAR_ERRO: {
      return {
        ...state, carregando: false, erro: action.erro
      }
    }
    case Types.INICIAR_EDITAR: {
      const item = {...action.plano};
      return {
        ...state,
        item,
        erro: '',
        mostrarForm: true
      }
    }
    case Types.REMOVER_REQUEST: {
      let itens = [...state.itens];
      itens.map((plano, i) => {
          if (plano.id === action.plano.id) return itens[i].carregando = true;
          return null;
      });
      return { ...state, erro: '', itens }
    }
    case Types.REMOVER_SUCESSO: {
      let itens = [...state.itens];
      itens = itens.filter(plano => plano.id !== action.plano.id);
      return { ...state, itens }
    }
    case Types.REMOVER_ERRO: {
      return { ...state, erro: action.erro }
    }
    default: return {...state};
  }
}

export function alterarLayout(layout) {
  return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
  return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
  return { type: Types.ALTERAR_PAGINA, pagina }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor };
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item };
}

export function definirItens(itens) {
  return { type: Types.DEFINIR_ITENS, itens }
}

export function mostrarForm() {
  return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
  return { type: Types.ESCONDER_FORM };
}

export function salvar(plano) {
  return { type: Types.SALVAR_REQUEST, plano }
}

export function iniciarEditar(plano) {
  return { type: Types.INICIAR_EDITAR, plano }
}

export function remover(plano) {
  return { type: Types.REMOVER_REQUEST, plano }
}

export function adicionarSelecao(plano) {
  return { type: Types.ADICIONAR_SELECAO, plano }
}

export function removerSelecao(plano) {
  return { type: Types.REMOVER_SELECAO, plano }
}

export function selecionarTodos() {
  return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
  return { type: Types.DESSELECIONAR_TODOS }
}