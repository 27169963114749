const stateInicial = {
    carregando: false,
    erro: '',
    permissoes: [],
    selecionados: []
}
  
export const Types = {
    // ALTERACOES
    ADICIONAR_SELECAO: 'permissoes/ADICIONAR_SELECAO',
    REMOVER_SELECAO: 'permissoes/REMOVER_SELECAO',
    // TODOS
    BUSCAR_TODOS_REQUEST: 'permissoes/BUSCAR_TODOS_REQUEST',
    BUSCAR_TODOS_SUCESSO: 'permissoes/BUSCAR_TODOS_SUCESSO',
    BUSCAR_TODOS_ERRO: 'permissoes/BUSCAR_TODOS_ERRO',
    // ITEM
    BUSCAR_ITEM_REQUEST: 'permissoes/BUSCAR_ITEM_REQUEST',
    BUSCAR_ITEM_SUCESSO: 'permissoes/BUSCAR_ITEM_SUCESSO',
    BUSCAR_ITEM_ERRO: 'permissoes/BUSCAR_ITEM_ERRO'
}
  
  export default function reducer (state = stateInicial, action) {
    switch(action.type) {
        case Types.BUSCAR_ITEM_REQUEST: {
            return {
                ...state,
                carregando: true,
                desabilitado: true,
                erro: ''
            }
        }
        case Types.BUSCAR_ITEM_SUCESSO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                item: action.permissao
            }
        }
        case Types.BUSCAR_ITEM_ERRO: {
            return {
                ...state,
                carregando: false,
                desabilitado: false,
                erro: action.erro
            }
        }
        case Types.BUSCAR_TODOS_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_TODOS_SUCESSO: {
            const novoState = {
                ...state,
                carregando: false,
                permissoes: [...action.permissoes]
            }
            return novoState;
        }
        case Types.BUSCAR_TODOS_ERRO: {
            const novoState = {
                ...state,
                carregando: false,
                erro: action.erro
            }
            return novoState;
        }
        case Types.ADICIONAR_SELECAO: {
            const selecionados = [...state.selecionados];
            selecionados.push(action.permissao);
            return {...state, selecionados};
        }
        case Types.REMOVER_SELECAO: {
            let selecionados = [...state.selecionados];
            selecionados = selecionados.filter(permissao => permissao.id !== action.permissao.id);
            return {...state, selecionados};
        }
        default: return state;
    }
  }
  
  export function alterarLayout(layout) {
    return { type: Types.ALTERAR_LAYOUT, layout }
  }
  
  export function alterarQtde(qtde) {
    return { type: Types.ALTERAR_QTDE, qtde }
  }
  
  export function alterarPagina(pagina) {
    return { type: Types.ALTERAR_PAGINA, pagina }
  }
  
  
  export function alterarItem(campo, valor) {
    return { type: Types.ALTERAR_ITEM, campo, valor }
  }
  
  export function pesquisar(pesquisa) {
    return { type: Types.PESQUISAR_REQUEST, pesquisa }
  }
  
  export function buscarItem(id) {
    return { type: Types.BUSCAR_ITEM_REQUEST, id }
  }
  
  export function buscarTodos(pagina = 1, qtde = 25) {
    return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
  }
  
  export function adicionarSelecao(permissao) {
    return { type: Types.ADICIONAR_SELECAO, permissao }
  }
  
  export function removerSelecao(permissao) {
    return { type: Types.REMOVER_SELECAO, permissao }
  }
  
  export function selecionarTodos() {
    return { type: Types.SELECIONAR_TODOS }
  }
  
  export function desselecionarTodos() {
    return { type: Types.DESSELECIONAR_TODOS }
  }
  
  export function remover(permissao) {
    return { type: Types.REMOVER_REQUEST, permissao }
  }
  
  export function definirNovoItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
  }
  
  export function salvar(permissao) {
    return { type: Types.SALVAR_REQUEST, permissao }
  }
  
  export function definirItem(item) {
    return { type: Types.DEFINIR_ITEM, item }
  }
  
  export function mostrarForm() {
      return { type: Types.MOSTRAR_FORM };
  }
  
  export function esconderForm() {
      return { type: Types.ESCONDER_FORM };
  }