import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import HeaderForm from '../../HeaderForm';

import {
  Paper, Grid, Typography, Table,
  TableBody, TableRow, TableCell, Chip
} from "@material-ui/core";

class TabDados extends Component {
  componentDidMount() {
    console.log(this.props.item);
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Dados do prêmio"/>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
          {/* GERAL */}
            <Typography style={{marginTop: '1em'}} variant="h6">Geral</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Estabelecimento</TableCell>
                  <TableCell>
                  {(this.props.item.estabelecimento)
                    ? <Link className="andes-link" to={`/estabelecimentos/ver/${this.props.item.estabelecimento.id}`}>{this.props.item.estabelecimento.razao_social}</Link>
                    : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>{this.props.item.nome}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo conversão</TableCell>
                  <TableCell>{(this.props.item.tipo_pontuacao === 'pontos') ? 'Pontuação' : 'Quantidade'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pontos</TableCell>
                  <TableCell>{(this.props.item.tipo_pontuacao === 'pontos') ? this.props.item.pontos : this.props.item.quantidade}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Quantidade</TableCell>
                  <TableCell>{this.props.item.quantidade}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ativo</TableCell>
                  <TableCell>{(this.props.item.ativo ? <Chip label="Ativa" color="primary" /> : '-')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Descrição</TableCell>
                  <TableCell>
                    {(this.props.item.descricao)
                      ? <div>
                        {renderHTML(this.props.item.descricao)}
                      </div>
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Regras</TableCell>
                  <TableCell>
                    {(this.props.item.regras)
                      ? <div>
                      {renderHTML(this.props.item.regras)}
                    </div>
                      : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    item: state.produtos.item
  }
}

export default connect(mapStateToProps)(TabDados);