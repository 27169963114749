import React, { Component } from 'react';
import { InputLabel, Select, FormControl, FormHelperText, MenuItem, Input, Chip } from '@material-ui/core';

class CampoFormSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        <InputLabel error={(this.props.erro) ? true : false}>{this.props.label}</InputLabel>
        <Select
          fullWidth
          multiple={this.props.multiple}
          value={this.props.value}
          onChange={this.props.onChange}
          error={(this.props.erro) ? true : false}
          renderValue={selected => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {(typeof selected === 'object' ) ? selected.map(value => {
                  let label = value;
                  this.props.opcoes.map((opcao) => {
                    if (opcao.value === value) return label = opcao.label;
                    return null;
                  })
                  return (<Chip key={value} label={label} />)
                }) : this.props.opcoes.map((opcao) => {
                  if (opcao.value === selected) return <span>{opcao.label}</span>;
                  return '';
                })
              }
            </div>
          )}
          input={ <Input
              error={(this.props.erro) ? true : false}
              required={this.props.required}
              fullWidth /> }>
          {this.props.opcoes.map((opcao, i) => {
            return <MenuItem key={i} value={opcao.value}>{opcao.label}</MenuItem>
          })}
        </Select>
        {(this.props.erro || this.props.ajuda) ? 
            <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
            : ''}
      </FormControl>
    )
  }
}

export default CampoFormSelect;