import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

class TabelaTopUsuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: props.usuarios || []
        }
    }

    componentWillReceiveProps(props) {
        if (props.usuarios) {
            this.setState({ usuarios: props.usuarios });
        }
    }

    renderUsuarios = () => {
        return this.state.usuarios.map((usuario, i) => {
            return (
                <TableRow key={i}>
                    <TableCell padding="none">{i+1}</TableCell>
                    <TableCell>{usuario.nome}</TableCell>
                    <TableCell align="right">
                            <strong>{(usuario.pontos) ? usuario.pontos : (usuario.vezes) ? usuario.vezes : '-'}</strong>
                    </TableCell>
                </TableRow>
            );
        });
    }

    render() {
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell component="th" padding="none">#</TableCell>
                        <TableCell component="th">Nome</TableCell>
                        <TableCell component="th" align="right">{(this.props.colunaDados) ? this.props.colunaDados : 'Pontos'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderUsuarios()}
                </TableBody>
            </Table>
        );
    }
}

export default TabelaTopUsuarios;