import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Servico from '../../servicos/ServicoFuncao';
import { Types } from '../ducks/funcoes';
const servico = new Servico();

function* salvar (action) {
    if (action.funcao.id) {
        try {
            const funcao = yield call([servico, servico.atualizarFuncao], action.funcao.id, action.funcao);
            yield put({type: Types.SALVAR_SUCESSO, funcao: funcao.data, editar: true});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    } else {
        try {
            const funcao = yield call([servico, servico.adicionarFuncao], action.funcao);
            yield put({type: Types.SALVAR_SUCESSO, funcao});
        } catch (e) {
            if (e.message && e.message.indexOf('code 401') > -1) yield put(push('/login'));
            yield put({type: Types.SALVAR_ERRO, erro: e.data});
        }
    }
}

function* remover (action) {
    try {
        yield call([servico, servico.removerFuncao], action.funcao.id);
        yield put({type: Types.REMOVER_SUCESSO, funcao: action.funcao});
    } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.REMOVER_ERRO, erro: e.message});
    }
}

function* buscarItem (action) {
    try {
        const funcao = yield call([servico, servico.buscarFuncaoPorId], action.id);
        yield put({type: Types.BUSCAR_ITEM_SUCESSO, funcao});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_ITEM_ERRO, erro: e.message});
     }
}

function* buscarTodos (action) {
    try {
        const funcoes = yield call([servico, servico.buscarFuncoes], action.qtde, action.pagina);
        yield put({type: Types.BUSCAR_TODOS_SUCESSO, funcoes});
     } catch (e) {
        if (e.message.indexOf('code 401') > -1) yield put(push('/login'));
        yield put({type: Types.BUSCAR_TODOS_ERRO, erro: e.message});
     }
}

function* saga () {
    yield takeLatest(Types.BUSCAR_TODOS_REQUEST, buscarTodos);
    yield takeLatest(Types.BUSCAR_ITEM_REQUEST, buscarItem);
    yield takeLatest(Types.SALVAR_REQUEST, salvar);
    yield takeEvery(Types.REMOVER_REQUEST, remover);
}

export default saga;