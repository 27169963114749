
const novoState = {
    carregando: false,
    erro: '',
    dados: {}
}

export const Types = {
    BUSCAR_REQUEST: 'cep/BUSCAR_REQUEST',
    BUSCAR_SUCESSO: 'cep/BUSCAR_SUCESSO',
    BUSCAR_ERRO: 'cep/BUSCAR_ERRO'
}

export default function reducer(state = novoState, action) {
    switch(action.type) {
        case Types.BUSCAR_REQUEST: {
            return {
                ...state,
                carregando: true,
                erro: ''
            }
        }
        case Types.BUSCAR_SUCESSO: {
            console.log(action.dados);
            return {
                ...state,
                carregando: false,
                dados: action.dados
            }
        }
        case Types.BUSCAR_ERRO: {
            console.log(action.erro);
            return {
                ...state,
                carregando: false,
                erro: action.erro
            }
        }
        default: return {...state};
    }
}

export function buscarDadosCep(cep) {
    return { type: Types.BUSCAR_REQUEST, cep }
}