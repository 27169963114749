import React, { Component } from 'react';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from "quill-emoji";
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import './index.css';

Quill.register(
  {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true,
);

const modules = {
  toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ["emoji"],
      ["clean"],
  ],

  "emoji-toolbar": true,
  "emoji-textarea": false,
  "emoji-shortname": true,
}


const formats = ["header", "bold", "italic", "underline", "strike", "list", "indent", "align", "clean", "emoji"];

class CampoFormTextarea extends Component {
  constructor(props) {
    super(props);
    this.state = { erro: props.erro || '' }
  }
  componentWillReceiveProps(props) {
    this.setState({erro: props.erro});
  }
  render() {
    return (
      <FormControl margin="normal" fullWidth style={{position: 'relative'}}>
        <Typography variant="subtitle1">{this.props.label}</Typography>
        <ReactQuill modules={modules} formats={formats} rows={10}
          placeholder={(this.props.placeholder) ? this.props.placeholder : ''}
          value={this.props.value}
          onChange={this.props.onChange} />
          {(this.props.erro || this.props.ajuda) ? 
            <FormHelperText error={(this.props.erro) ? true : false}>{(this.props.erro) ? this.props.erro : this.props.ajuda}</FormHelperText>
            : ''}
      </FormControl>
    )
  }
}

export default CampoFormTextarea;