import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  Chip, Card, CardContent, Grid, Typography, Divider,
  Table, TableBody, TableRow, TableCell, Button, CircularProgress
} from '@material-ui/core';

import {
  alterarPagina,
  adicionarSelecao,
  buscarTodos,
  removerSelecao,
  selecionarTodos,
  desselecionarTodos,
  definirItem,
  remover,
  iniciarEditar
} from '../../../store/ducks/produtos';

class ListaProdutos extends Component {
  componentDidMount() {
    document.addEventListener('scroll', this.handleScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }
  // INFINITE SCROLL PARA A VIEW EM LISTA
  handleScrolling = () => {
    const wrappedElement = document.getElementById('listaDados');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      if (this.props.paginas > this.props.pagina && !this.props.carregando) {
        this.props.alterarPagina(this.props.pagina + 1);
        this.props.buscarTodos(this.props.pagina, this.props.qtde);
      }
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  mostrarConfirmacaoRemover = (produto) => {
    this.refs.confirmacaoRemover.mostrar(produto);
  }
  editar = (produto) => {
    this.props.definirItem(produto);
    this.props.irParaEditar(produto.id);
  }
  render() {
    return (
      <div id="listaDados">
        {(this.props.carregando && this.props.pagina === 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {this.props.produtos.map((produto, i) => {
          return (
            <Card key={i} raised style={{marginBottom: '.5em', padding: '.5em'}}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5" style={{textTransform: 'uppercase'}}>{produto.nome}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {(produto.ativo ? <Chip label="Ativa" color="primary" /> : '-')}
                  </Grid>
                </Grid>
                <Divider style={{margin: '1em 0'}} />
                <Grid container>
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell><strong>Tipo da pontuação</strong></TableCell>
                          <TableCell>{(produto.tipo_pontuacao === 'pontos') ? 'Pontuação' : 'Quantidade'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Pontos/Qtde.</strong></TableCell>
                          <TableCell>{(produto.tipo_pontuacao === 'pontos') ? produto.pontos : produto.quantidade}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>Estab.</strong></TableCell>
                            <TableCell>
                              {(produto.estabelecimento)
                                ? <Link className="andes-link" to={`/estabelecimentos/ver/${produto.estabelecimento.id}?tab=produtos`}>{produto.estabelecimento.razao_social}</Link>
                                : '-'}
                            </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button 
                      onClick={e => this.props.iniciarEditar(produto)}
                      variant="outlined"
                      size="small">Editar</Button>
                    <Button 
                      onClick={e => this.mostrarConfirmacaoRemover(produto)}
                      variant="outlined"
                      size="small"
                      color="secondary">Remover</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {/* LOADER MAIS */}
        {(this.props.carregando && this.props.pagina > 1)
          ? <div className="flex-center">
            <CircularProgress />
          </div> : ''}
        {/* CONFIRMAÇÃO */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.remover(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      produtos: state.produtos.itens,
      selecionados: state.produtos.selecionados,
      paginas: state.produtos.paginas,
      pagina: state.produtos.pagina,
      qtde: state.produtos.qtde,
      carregando: state.produtos.carregando
  }
}

const mapDispatchToProps = dispatch => {
  return {
      alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
      adicionarSelecao: (produto) => dispatch(adicionarSelecao(produto)),
      removerSelecao: (produto) => dispatch(removerSelecao(produto)),
      selecionarTodos: () => dispatch(selecionarTodos()),
      desselecionarTodos: () => dispatch(desselecionarTodos()),
      remover: (produto) => dispatch(remover(produto)),
      buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
      definirItem: (produto) => dispatch(definirItem(produto)),
      iniciarEditar: (produto) => dispatch(iniciarEditar(produto))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaProdutos);