import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import HeaderForm from '../../HeaderForm';
import { Button, CircularProgress, Paper, Grid, } from '@material-ui/core';
import CardImagem from '../../geral/CardImagem';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import {
  definirCapa,
  definirPerfil,
  enviarFoto,
  removerFoto,
  removerCapa,
  removerPerfil
} from '../../../store/ducks/promocoes';

moment.locale('pt-br');

class TabImagens extends React.Component {
  constructor(props) {
    super(props);
    this.refInputFoto = React.createRef();
  }

  abrirDialogFoto = (e) => {
    this.refInputFoto.click();
  }

  uploadFoto = (e) => {
    const arquivo = this.refInputFoto.files[0];
    const data = new FormData();
    data.append('foto', arquivo, arquivo.name)
    this.props.enviarFoto(this.props.item.id, data);
  }

  render () {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Fotos da promoção"/>
        {/* GRID NOVO */}
        <Grid container spacing={2}>
          <Grid container spacing={2} item md={9} xs={12}>
            {/* ENVIAR */}
            <Grid item md={12}>
              <Button disabled={this.props.carregando} style={{marginTop: '1em', position: 'relative'}} onClick={this.abrirDialogFoto} variant="contained" color="primary">
                Enviar foto
                {(this.props.carregando) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary"/> : ''}
              </Button>
              <input onChange={this.uploadFoto} type="file" ref={e => this.refInputFoto = e} style={{display: 'none'}} />
            </Grid>
            {/* IMAGENS */}
            {this.props.item.imagens.map((imagem, i) => {
              return (
                <Grid key={i} item md={4} xs={12}>
                  <CardImagem
                    carregando={imagem.carregando}
                    src={imagem.url}
                    alt={imagem.titulo}
                    titulo=""
                    descricao={moment(imagem.created_at).format('L')}
                    aoDefinirCapa={e => this.refs.confirmacaoDefinirCapa.mostrar(imagem)}
                    aoDefinirPerfil={e => this.refs.confirmacaoDefinirPerfil.mostrar(imagem)}
                    aoRemover={e => this.refs.confirmacaoRemover.mostrar(imagem)}/>
                </Grid>
              )
            })}
          </Grid>
          <Grid item md={3} xs={12}>
            {/* PERFIL */}
            {(this.props.item.perfil)
              ? <CardImagem
                esconderBtnPerfil
                src={this.props.item.perfil.url}
                alt={this.props.item.nome}
                titulo='Perfil'
                descricao="Essa imagem aparecerá como foto principal da promoção."
                aoRemover={e => this.refs.confirmacaoRemoverPerfil.mostrar(this.props.item.perfil)} />
              : ''}
            {/* CAPA */}
            {(this.props.item.capa)
              ? <CardImagem
                  esconderBtnCapa
                  carregando={this.props.item.capa.carregando}
                  src={this.props.item.capa.url}
                  alt={this.props.item.nome}
                  titulo='Capa'
                  descricao="Essa imagem aparecerá no fundo da tela de perfil da promoção no aplicativo."
                  aoRemover={e => this.refs.confirmacaoRemoverCapa.mostrar(this.props.item.capa)} />
              : ''}
          </Grid>
        </Grid>
        {/* CONFIRMACAO REMOVER */}
        <ModalConfirmacao
            titulo="Remover o item?"
            ref="confirmacaoRemover"
            aoConfirmar={e => this.props.removerFoto(e)}>
            Você realmente deseja remover esse item?
        </ModalConfirmacao>
        {/* DEFINIR COMO CAPA */}
        <ModalConfirmacao
            titulo="Definir a imagem como capa?"
            ref="confirmacaoDefinirCapa"
            aoConfirmar={e => this.props.definirCapa(e)}>
            Você realmente deseja definir esta imagem como capa?
        </ModalConfirmacao>
        {/* DEFINIR COMO PERFIL */}
        <ModalConfirmacao
            titulo="Definir a imagem como perfil?"
            ref="confirmacaoDefinirPerfil"
            aoConfirmar={e => this.props.definirPerfil(e)}>
            Você realmente deseja definir esta imagem como perfil?
        </ModalConfirmacao>
        {/* CONFIRMACAO REMOVER PERFIL */}
        <ModalConfirmacao
            titulo="Remover imagem de perfil?"
            ref="confirmacaoRemoverPerfil"
            aoConfirmar={e => this.props.removerPerfil(e)}>
            Você realmente deseja remover esta imagem de perfil?
        </ModalConfirmacao>
        {/* CONFIRMACAO REMOVER CAPA */}
        <ModalConfirmacao
            titulo="Remover imagem de capa?"
            ref="confirmacaoRemoverCapa"
            aoConfirmar={() => this.props.removerCapa(this.props.item.capa)}>
            Você realmente deseja remover esta imagem de capa?
        </ModalConfirmacao>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.promocoes.carregando,
    item: state.promocoes.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    definirCapa: (foto) => dispatch(definirCapa(foto)),
    definirPerfil: (foto) => dispatch(definirPerfil(foto)),
    enviarFoto: (id, foto) => dispatch(enviarFoto(id, foto)),
    removerFoto: (foto) => dispatch(removerFoto(foto)),
    removerCapa: (foto) => dispatch(removerCapa(foto)),
    removerPerfil: (foto) => dispatch(removerPerfil(foto))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabImagens);