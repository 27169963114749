import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import HeaderForm from '../../HeaderForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao';
import ErroForm from '../../ErroForm';
import moment from 'moment';

import { Paper, Button, Table,
  TableHead, TableRow, TableCell,
  TableBody, IconButton, Icon,
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  iniciarEditar,
  mostrarForm,
  remover
} from '../../../store/ducks/promocoes';

class TabPromocoes extends Component {
  renderPromocoes = () => {
    return this.props.itens.map((promocao, index) => {
      return (
        <TableRow key={promocao.id}>
          <TableCell padding="none">{index + 1}</TableCell>
          <TableCell>{promocao.nome}</TableCell>
          <TableCell>{(promocao.tipo_conversao === 'pontuacao') ? 'Pontuação' : 'Quantidade'}</TableCell>
          <TableCell>{(promocao.tipo_conversao === 'pontuacao') ? promocao.pontos : promocao.quantidade}</TableCell>
          <TableCell>de {moment(promocao.inicio_validade).format('DD/MM/YYYY')} até {moment(promocao.final_validade).format('DD/MM/YYYY')}</TableCell>
          <TableCell>{(promocao.ativo) ? 'Ativo' : '-'}</TableCell>
          <TableCell padding="none">
            <IconButton onClick={e => this.props.irParaDetalhes(promocao.id)} color="default" aria-label="Ver">
              <Icon>search</Icon>
            </IconButton>
            <IconButton onClick={e => this.props.iniciarEditar(promocao)} color="default" aria-label="Editar">
              <Icon>edit</Icon>
            </IconButton>
            <IconButton disabled={(promocao.carregando) ? true : false} style={{position: 'relative'}} onClick={() => this.refs.confirmacaoRemover.mostrar(promocao)} color="secondary" aria-label="Remover">
              <Icon>delete</Icon>
              {(promocao.carregando) ? <CircularProgress style={{position: 'absolute'}} size={24} color="secondary" /> : ''}
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }
  render() {
    return (
      <Paper elevation={6} style={{padding: '1.5em'}}>
        <HeaderForm titulo="Promoções do estabelecimento"/>
        {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
        <Button onClick={this.props.mostrarForm} style={{marginTop: '1em'}} variant="contained" color="primary">Adicionar</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">#</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Qtde/Pontos</TableCell>
              <TableCell>Validade</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell padding="none">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(this.props.itens && this.props.itens.length)
              ? this.renderPromocoes()
              : <TableRow>
                  <TableCell padding="none"></TableCell>
                  <TableCell colSpan="6">
                    <Typography>Nenhuma promoção encontrada para este estabelecimento.</Typography>
                  </TableCell>
                </TableRow>}
          </TableBody>
        </Table>
        <ModalConfirmacao
          titulo="Remover a promoção?"
          ref="confirmacaoRemover"
          aoConfirmar={promocao => this.props.remover(promocao)}>
          Você tem certeza que deseja remover a promoção?
        </ModalConfirmacao>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    itens: state.promocoes.itens,
    item: state.promocoes.item,
    erro: state.promocoes.erro
  }
}

const mapDispatchToProps = dispatch => {
  return {
    iniciarEditar: (item) => dispatch(iniciarEditar(item)),
    remover: (promocao) => dispatch(remover(promocao)),
    mostrarForm: () => dispatch(mostrarForm()),
    irParaDetalhes: (id) => dispatch(push(`/promocoes/ver/${id}`))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabPromocoes);