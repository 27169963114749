import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, 
    Checkbox, IconButton, Icon, Typography, LinearProgress
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    definirItem,
    remover,
    iniciarEditar
} from '../../../store/ducks/textosSite';

class TabelaTextos extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, produto) => {
        return e.target.checked
            ? this.props.adicionarSelecao(produto)
            : this.props.removerSelecao(produto);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (produto) => {
        this.refs.confirmacaoRemover.mostrar(produto);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (produto, e = null) => {
        if (e) e.preventDefault();
        this.props.definirItem(produto);
        this.props.irParaMostrar(produto.id);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.textos.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>Local</TableCell>
                                <TableCell>Principal</TableCell>
                                <TableCell>Principal inglês</TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.textos.map((texto, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === texto.id)}
                                                onChange={(e) => this.alterarSelecao(e, texto)}
                                                value={`${texto.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>{texto.nomeLocal}</TableCell>
                                        <TableCell>{texto.principal}</TableCell>
                                        <TableCell>{texto.principal_ingles}</TableCell>
                                        <TableCell padding="none" style={{whiteSpace: 'nowrap'}}>
                                            <IconButton size="small" onClick={e => this.props.iniciarEditar(texto)} color="default" aria-label="Editar">
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.textos.length)
                                ? <TableRow>
                                    <TableCell colSpan={6}>
                                        <Typography>Nenhum texto cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        textos: state.textosSite.itens,
        selecionados: state.textosSite.selecionados,
        paginas: state.textosSite.paginas,
        pagina: state.textosSite.pagina,
        qtde: state.textosSite.qtde,
        carregando: state.textosSite.carregando
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        adicionarSelecao: (texto) => dispatch(adicionarSelecao(texto)),
        removerSelecao: (texto) => dispatch(removerSelecao(texto)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (texto) => dispatch(remover(texto)),
        buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
        definirItem: (texto) => dispatch(definirItem(texto)),
        iniciarEditar: (texto) => dispatch(iniciarEditar(texto))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaTextos);