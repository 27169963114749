import axios from 'axios';

const urlCep = "https://viacep.com.br/ws";
const padraoRetorno = 'json';

class ServicoCep {
  buscarDados(cep) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${urlCep}/${cep}/${padraoRetorno}`,
        method: 'get',
        headers: {'Accept': `application/json`}
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    });
  }
}

export default ServicoCep;