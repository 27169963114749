import React from 'react';
import { connect } from 'react-redux';
import PaginaDashboard from './paginas/PaginaDashboard';
import PaginaLogin from './paginas/PaginaLogin';
import PaginaAdmins from './paginas/PaginaAdmins';
import PaginaConfiguracoes from './paginas/PaginaConfiguracoes';
import PaginaFuncoes from './paginas/PaginaFuncoes';
import PaginaPerfil from './paginas/PaginaPerfil';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Router, withRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/pt-br";
// ESTABELECIMENTOS
import PaginaEstabelecimentos from './paginas/PaginaEstabelecimentos';
import PaginaDetalhesEstabelecimento from './paginas/PaginaDetalhesEstabelecimento';
// USUARIOS
import PaginaUsuarios from './paginas/PaginaUsuarios';
// PROMOCAO
import PaginaPromocoes from './paginas/PaginaPromocoes';
import PaginaDetalhesPromocao from './paginas/PaginaDetalhesPromocao';
// VOUCHERS
import PaginaVouchers from './paginas/PaginaVouchers';
import PaginaDetalhesVoucher from './paginas/PaginaDetalhesVoucher';
// SIDEBAR
import Sidebar from './componentes/geral/Sidebar';
// HEADER
import HeaderPagina from './componentes/geral/HeaderPagina';
// CSS
import './css/App.css';
// REDUX
import {
  alterarTitulo
} from './store/ducks/header';
import PaginaPlanos from './paginas/PaginaPlanos';
import PaginaProdutos from './paginas/PaginaProdutos';
import PaginaDetalhesProduto from './paginas/PaginaDetalhesProduto';
import PaginaCategoriasEstabelecimentos from './paginas/PaginaCategoriasEstabelecimentos';
import PaginaPerguntasFrequentesSite from './paginas/PaginaPerguntasFrequentesSite';
import PaginaSlidesSite from './paginas/PaginaSlidesSite';
import PaginaTextosSite from './paginas/PaginaTextosSite';
import PaginaConfiguracoesSite from './paginas/PaginaConfiguracoesSite';
import PaginaContatos from './paginas/PaginaContatos';
import PaginaInscricoes from './paginas/PaginaInscricoes';
import PaginaVantagensSite from './paginas/PaginaVantagensSite';
import PaginaDepoimentosSite from './paginas/PaginaDepoimentosSite';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: [
      '-apple-system',
      'Lato',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiAvatar: {
      root: {
        boxShadow: '0px 0px 15px rgba(20, 20, 20, .16)'
      }
    },
    MuiButton: {
      root: {
        marginBottom: '1em'
      }
    }
  },
  palette: {
    primary: {
      main: '#58359d'
    },
    secondary: {
      main: '#ff3f6e'
    }
  },
});


class App extends React.Component {
  render () {
    return (
      <Router history={this.props.history}>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              {/* LOGIN OU SIDEBAR */}
              <Switch>
                <Route path="/login" component={PaginaLogin}></Route>
                <Route component={Sidebar}></Route>
              </Switch>
              <main className="app-wrapper">
                {/* HEADER */}
                <Switch>
                  <Route path="/login" component={null}></Route>
                  <Route component={HeaderPagina}></Route>
                </Switch>
                {/* ROTAS */}
                <Route path="/" exact component={PaginaDashboard} />
                <Route path="/me" component={PaginaPerfil}></Route>
                <Route path="/configuracoes" component={PaginaConfiguracoes} />
                {/* ADMINS */}
                <Route path="/admins" exact component={PaginaAdmins} ></Route>
                {/* CATEGORIAS DE ESTABELECIMENTOS */}
                <Route path="/categorias-estabelecimentos" exact component={PaginaCategoriasEstabelecimentos}></Route>
                {/* FUNCOES */}
                <Route path="/funcoes" exact component={PaginaFuncoes} ></Route>
                {/* ESTABELECIMENTOS */}
                <Route path="/estabelecimentos" exact component={PaginaEstabelecimentos}></Route>
                <Route path="/estabelecimentos/ver/:id" component={PaginaDetalhesEstabelecimento}></Route>
                {/* PRODUTOS */}
                <Route path="/produtos" exact component={PaginaProdutos}></Route>
                <Route path="/produtos/ver/:id" component={PaginaDetalhesProduto}></Route>
                {/* PROMOCOES */}
                <Route path="/promocoes" exact component={PaginaPromocoes}></Route>
                <Route path="/promocoes/ver/:id" component={PaginaDetalhesPromocao}></Route>
                {/* USUARIOS */}
                <Route path="/usuarios" exact component={PaginaUsuarios}></Route>
                {/* VOUCHERS */}
                <Route path="/vouchers" exact component={PaginaVouchers}></Route>
                <Route path="/vouchers/ver/:id" component={PaginaDetalhesVoucher}></Route>
                {/* SITE */}
                {/* CONTATOS */}
                <Route path="/contatos-site" exact component={PaginaContatos}></Route>
                {/* INSCRICOES */}
                <Route path="/inscricoes-site" exact component={PaginaInscricoes}></Route>
                {/* CONFIGURACOES DO SITE */}
                <Route path="/configuracoes-site" exact component={PaginaConfiguracoesSite}></Route>
                {/* DEPOIMENTOS DO SITE */}
                <Route path="/depoimentos-site" exact component={PaginaDepoimentosSite}></Route>
                {/* PERGUNTAS FREQUENTES DO SITE */}
                <Route path="/perguntas-frequentes-site" exact component={PaginaPerguntasFrequentesSite}></Route>
                {/* SLIDES DO SITE */}
                <Route path="/slides-site" exact component={PaginaSlidesSite}></Route>
                {/* TEXTOS DO SITE */}
                <Route path="/textos-site" exact component={PaginaTextosSite}></Route>
                {/* VANTAGENS NO SITE */}
                <Route path="/vantagens-site" exact component={PaginaVantagensSite}></Route>
                {/* ASSINATURAS */}
                {/* PLANOS */}
                <Route path="/planos" exact component={PaginaPlanos}></Route>
              </main>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarTitulo: titulo => dispatch(alterarTitulo(titulo))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));