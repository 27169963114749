import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

import DrawerPlanos from '../componentes/planos/DrawerAdicionar';
import TabelaPlanos from '../componentes/planos/TabelaPlanos';

// REDUX
import {
  alterarLayout,
  alterarQtde,
  buscarTodos,
  mostrarForm
} from '../store/ducks/planos';

class PaginaPlanos extends React.Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-restricted-globals 
    if (!localStorage.getItem('usuario')) { location.href = '/login'; }
  }

  ajustarTela = () => (window.innerWidth < 768)
    ? this.props.alterarLayout('tabela')
    : this.props.alterarLayout('tabela')

  componentDidMount() {
    this.props.buscarTodos(this.props.pagina, this.props.qtde);
    this.ajustarTela();
    window.addEventListener("resize", window.addEventListener("resize", this.ajustarTela.bind(this)));
   
  }

  render () {
    return (
        <div>
          {/* MENU */}
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Button
                onClick={e => this.props.mostrarForm()}
                variant="contained"
                color="primary">
                  Adicionar
              </Button>
            </Grid>
            <Grid item container md={6} sm={12} xs={12} justify="flex-end">
            </Grid>
          </Grid>
          <DrawerPlanos />
          {/* TABELA */}
          {(this.props.layout === 'tabela')
            ? <TabelaPlanos /> : ''}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    carregando: state.planos.carregando,
    erro: state.planos.erro,
    layout: state.planos.layout,
    planos: state.planos.planos,
    selecionados: state.planos.selecionados,
    pagina: state.planos.pagina,
    qtde: state.planos.qtde
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alterarLayout: (layout) => dispatch(alterarLayout(layout)),
    alterarQtde: (qtde) => dispatch(alterarQtde(qtde)),
    buscarTodos: (pagina, qtde) => dispatch(buscarTodos(pagina, qtde)),
    mostrarForm: () => dispatch(mostrarForm())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginaPlanos));