import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import '../../css/Breadcrumbs.css';

class Bradcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { home: props.home || true, itens: props.itens || [] }
  }

  componentWillReceiveProps (props) {
    this.setState({ home: props.home || true, itens: props.itens || [] });
  }

  renderHome = () => {
    if (this.state.home) {
      if (this.state.itens.length > 0) {
        return <Link className="breadcrumbs-item" to="/">Home</Link>
      } else {
        return <div className="breadcrumbs-item">Home</div>
      }
    }
  }

  renderItens = () => {
    return this.state.itens.map((item, i) => {
      if (i === this.state.itens.length - 1) {
        return (
          <div style={{width: '100%', display: 'flex', alignItems: 'center'}} key={i}>
            <div className="breadcrumbs-divider"><Icon>chevron_right</Icon></div>
            <div className="breadcrumbs-item active">{item.nome}</div>
          </div>
        );
      } else {
        return (
          <div style={{display: 'flex', alignItems: 'center'}} key={i}>
            <div className="breadcrumbs-divider"><Icon>chevron_right</Icon></div>
            <Link to={`${item.endereco}`} className="breadcrumbs-item">{item.nome}</Link>
          </div>
        );
      }
    })
  }

  render() {
    return (
      <div className="breadcrumbs-wrapper">
        {this.renderHome()}
        {this.renderItens()}
      </div>
    );
  }
}

export default Bradcrumbs;