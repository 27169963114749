import * as moment from 'moment';

const novoItem = {
  id: '',
  nome: '',
  descricao: '',
  valor: 0,
  inicio_validade: moment(),
  final_validade: moment().add('90', 'd'),
  ativo: false,
  estabelecimentos_id: '',
  imagens: []
}

const stateInicial = {
  item: {...novoItem},
  layout: 'tabela',
  pagina: 1,
  paginas: 1,
  qtde: 25,
  tab: 0,
  itens: [],
  selecionados: [],
  mostrarForm: false,
  carregando: false,
  erro: '',
  desabilitado: false
}

export const Types = {
  // ALTERACOES
  ALTERAR_LAYOUT: 'promocoes/ALTERAR_LAYOUT',
  ALTERAR_PAGINA: 'promocoes/ALTERAR_PAGINA',
  ALTERAR_QTDE: 'promocoes/ALTERAR_QTDE',
  ADICIONAR_SELECAO: 'promocoes/ADICIONAR_SELECAO',
  REMOVER_SELECAO: 'promocoes/REMOVER_SELECAO',
  SELECIONAR_TODOS: 'promocoes/SELECIONAR_TODOS',
  DESSELECIONAR_TODOS: 'promocoes/DESSELECIONAR_TODOS',
  ALTERAR_ITEM: 'promocoes/ALTERAR_ITEM',
  ALTERAR_TAB: 'promocoes/ALTERAR_TAB',
  DEFINIR_ITEM: 'promocoes/DEFINIR_ITEM',
  MOSTRAR_FORM: 'promocoes/MOSTRAR_FORM',
  ESCONDER_FORM: 'promocoes/ESCONDER_FORM',
  INICIAR_EDITAR: 'promocoes/INICIAR_EDITAR',
  DEFINIR_ITENS: 'promocoes/DEFINIR_ITENS',
  SALVAR_REQUEST: 'promocoes/SALVAR_REQUEST',
  SALVAR_SUCESSO: 'promocoes/SALVAR_SUCESSO',
  SALVAR_ERRO: 'promocoes/SALVAR_ERRO',
  REMOVER_REQUEST: 'promocoes/REMOVER_REQUEST',
  REMOVER_SUCESSO: 'promocoes/REMOVER_SUCESSO',
  REMOVER_ERRO: 'promocoes/REMOVER_ERRO',
  // TODOS
  BUSCAR_TODOS_REQUEST: 'promocoes/BUSCAR_TODOS_REQUEST',
  BUSCAR_TODOS_SUCESSO: 'promocoes/BUSCAR_TODOS_SUCESSO',
  BUSCAR_TODOS_ERRO: 'promocoes/BUSCAR_TODOS_ERRO',
  // ITEM
  BUSCAR_ITEM_REQUEST: 'promocoes/BUSCAR_ITEM_REQUEST',
  BUSCAR_ITEM_SUCESSO: 'promocoes/BUSCAR_ITEM_SUCESSO',
  BUSCAR_ITEM_ERRO: 'promocoes/BUSCAR_ITEM_ERRO',
  // PESQUISAR
  PESQUISAR_REQUEST: 'promocoes/PESQUISAR_REQUEST',
  PESQUISAR_SUCESSO: 'promocoes/PESQUISAR_SUCESSO',
  PESQUISAR_ERRO: 'promocoes/PESQUISAR_ERRO',
  // ENVIAR FOTO
  ENVIAR_FOTO_REQUEST: 'promocoes/ENVIAR_FOTO_REQUEST',
  ENVIAR_FOTO_SUCESSO: 'promocoes/ENVIAR_FOTO_SUCESSO',
  ENVIAR_FOTO_ERRO: 'promocoes/ENVIAR_FOTO_ERRO',
  // REMOVER FOTO
  REMOVER_FOTO_REQUEST: 'promocoes/REMOVER_FOTO_REQUEST',
  REMOVER_FOTO_SUCESSO: 'promocoes/REMOVER_FOTO_SUCESSO',
  REMOVER_FOTO_ERRO: 'promocoes/REMOVER_FOTO_ERRO',
  // DEFINIR CAPA
  DEFINIR_CAPA_REQUEST: 'promocoes/DEFINIR_CAPA_REQUEST',
  DEFINIR_CAPA_SUCESSO: 'promocoes/DEFINIR_CAPA_SUCESSO',
  DEFINIR_CAPA_ERRO: 'promocoes/DEFINIR_CAPA_ERRO',
  // DEFINIR PERFIL
  DEFINIR_PERFIL_REQUEST: 'promocoes/DEFINIR_PERFIL_REQUEST',
  DEFINIR_PERFIL_SUCESSO: 'promocoes/DEFINIR_PERFIL_SUCESSO',
  DEFINIR_PERFIL_ERRO: 'promocoes/DEFINIR_PERFIL_ERRO',
  // REMOVER CAPA
  REMOVER_CAPA_REQUEST: 'promocoes/REMOVER_CAPA_REQUEST',
  REMOVER_CAPA_SUCESSO: 'promocoes/REMOVER_CAPA_SUCESSO',
  REMOVER_CAPA_ERRO: 'promocoes/REMOVER_CAPA_ERRO',
  // REMOVER PERFIL
  REMOVER_PERFIL_REQUEST: 'promocoes/REMOVER_PERFIL_REQUEST',
  REMOVER_PERFIL_SUCESSO: 'promocoes/REMOVER_PERFIL_SUCESSO',
  REMOVER_PERFIL_ERRO: 'promocoes/REMOVER_PERFIL_ERRO'
}

export default function reducer (state = stateInicial, action) {
  switch(action.type) {
    case Types.ALTERAR_PAGINA: {
        if (!isNaN(action.pagina)) {
            return {...state, pagina: action.pagina}
        }
        return {...state};
    }
    case Types.ALTERAR_QTDE: {
        if (!isNaN(action.qtde)) {
            return {...state, qtde: action.qtde, pagina: 1}
        }
        return {...state};
    }
    case Types.ALTERAR_LAYOUT: {
        if (['tabela', 'lista'].indexOf(action.layout) > -1) {
            return {...state, layout: action.layout};
        }
        return {...state};
    }
    case Types.ESCONDER_FORM: {
      return {...state, mostrarForm: false}
    }
    case Types.MOSTRAR_FORM: {
      return {...state, mostrarForm: true, item: {...novoItem}, erro: ''}
    }
    case Types.ALTERAR_TAB: {
      if(!isNaN(action.tab)) return {...state, tab: action.tab};
      return {...state};
    }
    case Types.ALTERAR_ITEM: {
      const item = {...state.item}
      item[action.campo] = action.valor;
      return {...state, item, erro: ''};
    }
    case Types.ADICIONAR_SELECAO: {
        const selecionados = [...state.selecionados];
        selecionados.push(action.promocao);
        return {...state, selecionados};
    }
    case Types.REMOVER_SELECAO: {
        let selecionados = [...state.selecionados];
        selecionados = selecionados.filter(promocao => promocao.id !== action.promocao.id);
        return {...state, selecionados};
    }
    case Types.SELECIONAR_TODOS: {
        const selecionados = [...state.itens];
        return {...state, selecionados};
    }
    case Types.DESSELECIONAR_TODOS: {
        const selecionados = [];
        return {...state, selecionados};
    }
    case Types.BUSCAR_TODOS_REQUEST: {
        return {
            ...state,
            carregando: true,
            erro: ''
        }
    }
    case Types.BUSCAR_TODOS_SUCESSO: {
        const novoState = {
            ...state,
            carregando: false,
            itens: (state.layout === 'lista')
                ? [...state.itens].concat(action.promocoes.data)
                : action.promocoes.data,
            paginas: action.promocoes.meta.last_page
        }
        return novoState;
    }
    case Types.BUSCAR_TODOS_ERRO: {
        const novoState = {
            ...state,
            carregando: false,
            erro: action.erro
        }
        return novoState;
    }
    case Types.BUSCAR_ITEM_REQUEST: {
      return {
        ...state,
        carregando: true,
        desabilitado: true,
        erro: ''
      }
    }
    case Types.BUSCAR_ITEM_SUCESSO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        item: action.promocao
      }
    }
    case Types.BUSCAR_ITEM_ERRO: {
      return {
        ...state,
        carregando: false,
        desabilitado: false,
        erro: action.erro
      }
    }
    case Types.PESQUISAR_REQUEST: {
        return {
            ...state,
            pagina: 1,
            qtde: 25,
            erro: ''
        }
    }
    case Types.PESQUISAR_SUCESSO: {
        const novoState = {
            ...state,
            carregando: false,
            itens: action.promocoes.data,
            paginas: action.promocoes.meta.last_page
        }
        return novoState;
    }
    case Types.PESQUISAR_ERRO: {
        const novoState = {
            ...state,
            carregando: false,
            erro: action.erro
        }
        return novoState;
    }
    case Types.DEFINIR_ITEM: {
      return {...state, item: action.item}
    }
    case Types.DEFINIR_ITENS: {
      return {...state, itens: action.itens}
    }
    case Types.SALVAR_REQUEST: {
      return {
        ...state,
        carregando: true,
        erro: ''
      }
    }
    case Types.SALVAR_SUCESSO: {
      let novoState = {
        ...state,
        carregando: false,
        mostrarForm: false,
        item: action.promocao
      }
      if (action.editar) {
        const itens = [...state.itens];
        itens.map((item, i) => {
          if (item.id === action.promocao.id) return itens[i] = action.promocao;
          return null;
        });
        novoState = {...novoState, itens };
      } else {
        novoState = {...novoState, itens: [...state.itens, action.promocao]};
      }
      return novoState;
    }
    case Types.SALVAR_ERRO: {
      return {
        ...state, carregando: false, erro: action.erro
      }
    }
    case Types.INICIAR_EDITAR: {
      const item = {...action.promocao};
      if (typeof item.inicio_validade === 'string') item.inicio_validade = moment(item.inicio_validade)
      if (typeof item.final_validade === 'string') item.final_validade = moment(item.final_validade)
      return {
        ...state,
        item,
        erro: '',
        mostrarForm: true
      }
    }
    case Types.REMOVER_REQUEST: {
      let itens = [...state.itens];
      itens.map((promocao, i) => {
          if (promocao.id === action.promocao.id) return itens[i].carregando = true;
          return null;
      });
      return { ...state, erro: '', itens }
    }
    case Types.REMOVER_SUCESSO: {
      let itens = [...state.itens];
      itens = itens.filter(promocao => promocao.id !== action.promocao.id);
      return { ...state, itens }
    }
    case Types.REMOVER_ERRO: {
      return { ...state, erro: action.erro }
    }
    case Types.ENVIAR_FOTO_REQUEST: {
        return {
            ...state,
            carregando: true,
            erro: ''
        }
    }
    case Types.ENVIAR_FOTO_SUCESSO: {
        let imagens = [...state.item.imagens];
        imagens.splice(0, 0, action.foto);
        let item = {...state.item, imagens};
        return {
            ...state,
            item,
            carregando: false
        }
    }
    case Types.ENVIAR_FOTO_ERRO: {
        return {
            ...state,
            carregando: false,
            erro: action.erro
        }
    }
    case Types.DEFINIR_CAPA_REQUEST: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => {
            if (foto.id === action.foto.id) return imagens[i].carregando = true;
            return null;
        });
        let item = {...state.item, imagens}
        return { ...state, erro: '', item }
    }
    case Types.DEFINIR_CAPA_SUCESSO: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => imagens[i].carregando = false);
        let item = {...state.item, imagens, capa: action.foto}
        return { ...state, item, carregando: false }
    }
    case Types.DEFINIR_CAPA_ERRO: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => imagens[i].carregando = false);
        let item = {...state.item, imagens}
        return { ...state, erro: action.erro, item }
    }
    case Types.DEFINIR_PERFIL_REQUEST: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => {
            if (foto.id === action.foto.id) return imagens[i].carregando = true;
            return null;
        });
        let item = {...state.item, imagens}
        return { ...state, erro: '', item }
    }
    case Types.DEFINIR_PERFIL_SUCESSO: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => imagens[i].carregando = false);
        let item = {...state.item, imagens, perfil: action.foto}
        return { ...state, item, carregando: false }
    }
    case Types.DEFINIR_PERFIL_ERRO: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => imagens[i].carregando = false);
        let item = {...state.item, imagens}
        return { ...state, erro: action.erro, item }
    }
    case Types.REMOVER_FOTO_REQUEST: {
        let imagens = [...state.item.imagens];
        imagens.map((foto, i) => {
            if (foto.id === action.foto.id) return imagens[i].carregando = true;
            return null;
        });
        let item = {...state.item, imagens}
        return { ...state, erro: '', item }
    }
    case Types.REMOVER_FOTO_SUCESSO: {
        let imagens = [...state.item.imagens];
        imagens = imagens.filter(foto => foto.id !== action.foto.id);
        let item = {...state.item, imagens}
        return { ...state, item, carregando: false }
    }
    case Types.REMOVER_FOTO_ERRO: {
        return { ...state, carregando: false, erro: action.erro }
    }
    case Types.REMOVER_CAPA_REQUEST: {
        let capa = {...state.item.capa};
        capa.carregando = true;
        let item = {...state.item, capa}
        return { ...state, erro: '', item }
    }
    case Types.REMOVER_CAPA_SUCESSO: {
        let item = {...state.item, capa: null}
        return { ...state, item }
    }
    case Types.REMOVER_CAPA_ERRO: {
        let capa = {...state.item.capa};
        capa.carregando = false;
        let item = {...state.item, capa}
        return { ...state, erro: action.erro, item }
    }
    case Types.REMOVER_PERFIL_REQUEST: {
        let perfil = {...state.item.perfil};
        perfil.carregando = true;
        let item = {...state.item, perfil}
        return { ...state, erro: '', item }
    }
    case Types.REMOVER_PERFIL_SUCESSO: {
        let item = {...state.item, perfil: null}
        return { ...state, item }
    }
    case Types.REMOVER_PERFIL_ERRO: {
        let perfil = {...state.item.perfil};
        perfil.carregando = false;
        let item = {...state.item, perfil}
        return { ...state, erro: action.erro, item }
    }
    default: return {...state};
  }
}

export function alterarLayout(layout) {
  return { type: Types.ALTERAR_LAYOUT, layout }
}

export function alterarQtde(qtde) {
  return { type: Types.ALTERAR_QTDE, qtde }
}

export function alterarPagina(pagina) {
  return { type: Types.ALTERAR_PAGINA, pagina }
}

export function alterarItem(campo, valor) {
  return { type: Types.ALTERAR_ITEM, campo, valor };
}

export function alterarTab(tab) {
  return { type: Types.ALTERAR_TAB, tab }
}

export function buscarItem(id) {
  return { type: Types.BUSCAR_ITEM_REQUEST, id }
}

export function buscarTodos(pagina = 1, qtde = 25) {
  return { type: Types.BUSCAR_TODOS_REQUEST, pagina, qtde }
}

export function pesquisar(pesquisa) {
  return { type: Types.PESQUISAR_REQUEST, pesquisa }
}

export function definirItem(item) {
  return { type: Types.DEFINIR_ITEM, item };
}

export function definirItens(itens) {
  return { type: Types.DEFINIR_ITENS, itens }
}

export function mostrarForm() {
  return { type: Types.MOSTRAR_FORM };
}

export function esconderForm() {
  return { type: Types.ESCONDER_FORM };
}

export function salvar(promocao) {
  return { type: Types.SALVAR_REQUEST, promocao }
}

export function iniciarEditar(promocao) {
  return { type: Types.INICIAR_EDITAR, promocao }
}

export function remover(promocao) {
  return { type: Types.REMOVER_REQUEST, promocao }
}

export function enviarFoto(id, foto) {
  return { type: Types.ENVIAR_FOTO_REQUEST, id, foto }
}

export function removerFoto(foto) {
  return { type: Types.REMOVER_FOTO_REQUEST, foto }
}

export function removerCapa(foto) {
  return { type: Types.REMOVER_CAPA_REQUEST, foto }
}

export function removerPerfil(foto) {
  return { type: Types.REMOVER_PERFIL_REQUEST, foto }
}

export function definirCapa(foto) {
  return { type: Types.DEFINIR_CAPA_REQUEST, foto }
}

export function definirPerfil(foto) {
  return { type: Types.DEFINIR_PERFIL_REQUEST, foto }
}

export function adicionarSelecao(estabelecimento) {
  return { type: Types.ADICIONAR_SELECAO, estabelecimento }
}

export function removerSelecao(estabelecimento) {
  return { type: Types.REMOVER_SELECAO, estabelecimento }
}

export function selecionarTodos() {
  return { type: Types.SELECIONAR_TODOS }
}

export function desselecionarTodos() {
  return { type: Types.DESSELECIONAR_TODOS }
}