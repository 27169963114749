import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class CampoFormErro extends Component {
  render() {
    return (
        <div style={{backgroundColor: '#fce4ec', borderLeft: '5px solid #f44336', borderRadius: '5px', padding: '1em', marginTop: '1em', marginBottom: '1em'}}>
            <Typography variant="h6">{this.props.titulo}</Typography>
            <Typography variant="body2">{this.props.mensagem}</Typography>
        </div>
    )
  }
}

export default CampoFormErro;