import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, 
    Checkbox, IconButton, Icon, CircularProgress, Typography, LinearProgress,
    TableSortLabel
} from '@material-ui/core';
import MenuPaginacao from '../../MenuPaginacao';
import ErroForm from '../../ErroForm';
import ModalConfirmacao from '../../geral/ModalConfirmacao/index';
import moment from 'moment';

import {
    alterarPagina,
    adicionarSelecao,
    buscarTodos,
    removerSelecao,
    selecionarTodos,
    desselecionarTodos,
    alterarOrdem,
    definirItem,
    remover
} from '../../../store/ducks/usuarios';

class TabelaUsuarios extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    alterarSelecao = (e, usuario) => {
        return e.target.checked
            ? this.props.adicionarSelecao(usuario)
            : this.props.removerSelecao(usuario);
    }
    alterarSelecaoTodos = () => {
        return (this.props.selecionados.length)
            ? this.props.desselecionarTodos()
            : this.props.selecionarTodos()
    }
    mostrarConfirmacaoRemover = (usuario) => {
        this.refs.confirmacaoRemover.mostrar(usuario);
    }
    mostrarConfirmacaoSelecionados = () => {
        this.refs.confirmacaoSelecionados.mostrar();
    }
    removerSelecionados = () => {
        this.props.selecionados.map((item) => {
            return this.props.remover(item);
        })
    }
    mostrar = (usuario) => {
        this.props.definirItem(usuario);
        this.props.irParaMostrar(usuario.id);
    }
    editar = (usuario) => {
        this.props.definirItem(usuario);
        this.props.irParaEditar(usuario.id);
    }
    selecionarSexo = (sexo, e) => {
        if (e) e.preventDefault();
        this.props.aoFiltrarPorSexo(sexo);
    }
    selecionarEscolaridade = (escolaridade, e) => {
        if (e) e.preventDefault();
        this.props.aoFiltrarPorEscolaridade(escolaridade);
    }
    render() {
        return (
            <>
                {/* LOADER */}
                {(this.props.carregando) ? <LinearProgress color="secondary" /> : ''}
                {/* TABELA */}
                <Paper elevation={6} style={{padding: '1.5em'}}>
                    {(this.props.erro) ? <ErroForm>{this.props.erro}</ErroForm> : ''}
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={(this.props.selecionados.length === this.props.usuarios.length)}
                                        onChange={this.alterarSelecaoTodos}
                                        color="primary"/>
                                </TableCell>
                                <TableCell padding="none">#</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'alfabetica' || this.props.ordem === 'alfabetica-desc'}
                                        direction={this.props.ordem === 'alfabetica' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'alfabetica') ? 'alfabetica-desc' : 'alfabetica');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Nome
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'sexo' || this.props.ordem === 'sexo-desc'}
                                        direction={this.props.ordem === 'sexo' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'sexo') ? 'sexo-desc' : 'sexo');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Sexo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'escolaridade' || this.props.ordem === 'escolaridade-desc'}
                                        direction={this.props.ordem === 'escolaridade' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'escolaridade') ? 'escolaridade-desc' : 'escolaridade');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Escolaridade
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'data_nascimento' || this.props.ordem === 'data_nascimento-desc'}
                                        direction={this.props.ordem === 'data_nascimento' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'data_nascimento') ? 'data_nascimento-desc' : 'data_nascimento');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Dt. Nascimento
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={this.props.ordem === 'pontos' || this.props.ordem === 'pontos-desc'}
                                        direction={this.props.ordem === 'pontos' ? 'asc' : 'desc'}
                                        onClick={e => {
                                            this.props.alterarOrdem((this.props.ordem === 'pontos') ? 'pontos-desc' : 'pontos');
                                            setTimeout(() => {
                                                this.props.buscarTodos(this.props.pagina, this.props.qtde, this.props.ordem)
                                            });
                                        }}>
                                        Pontos
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell padding="none">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.usuarios.map((usuario, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={this.props.selecionados.some(e => e.id === usuario.id)}
                                                onChange={(e) => this.alterarSelecao(e, usuario)}
                                                value={`${usuario.id}`} />
                                        </TableCell>
                                        <TableCell padding="none">{i+1}</TableCell>
                                        <TableCell>{usuario.nome}</TableCell>
                                        <TableCell>{usuario.email}</TableCell>
                                        <TableCell>
                                            <a href="/" onClick={e => this.selecionarSexo({
                                                label: usuario.nome_sexo,
                                                slug: usuario.sexo
                                            }, e)} className="andes-link">{usuario.nome_sexo}</a>
                                        </TableCell>
                                        <TableCell>
                                            <a href="/" onClick={e => this.selecionarEscolaridade({
                                                label: usuario.nome_escolaridade,
                                                slug: usuario.escolaridade
                                            }, e)} className="andes-link">{usuario.nome_escolaridade}</a>
                                        </TableCell>
                                        <TableCell>{
                                            (usuario.data_nascimento) ? moment(usuario.data_nascimento).format('DD/MM/YYYY') : '-'
                                        }</TableCell>
                                        <TableCell>{usuario.pontos}</TableCell>
                                        <TableCell padding="none">
                                            <IconButton
                                                size="small"
                                                disabled={usuario.removendo}
                                                onClick={e => this.mostrarConfirmacaoRemover(usuario)}
                                                color="secondary"
                                                aria-label="Remover"
                                                style={{position: 'relative'}}>
                                                <Icon>delete</Icon>
                                                {(usuario.removendo)
                                                    ? <CircularProgress color="secondary" size={24} style={{position: 'absolute', right: '.2em', top: '.2em'}} />
                                                    : ''}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }))}
                            {(!this.props.usuarios.length)
                                ? <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography>Nenhum usuário cadastrado.</Typography>
                                    </TableCell>
                                </TableRow>
                                : ''}
                        </TableBody>
                        {(this.props.selecionados.length > 1)
                            ? <TableFooter>
                                <TableRow>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell padding="none"></TableCell>
                                    <TableCell colSpan={4} style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{whiteSpace: 'nowrap'}}>{this.props.selecionados.length} selecionados</span>
                                        <IconButton
                                            color="secondary"
                                            aria-label="Remover selecionados"
                                            onClick={this.mostrarConfirmacaoSelecionados}
                                            >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                            : ''}
                    </Table>
                </Paper>
                {/* PAGINACAO */}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <MenuPaginacao
                        aoIrPara={(pagina) => {
                            this.props.alterarPagina(pagina);
                            this.props.buscarTodos(pagina, this.props.qtde, this.props.ordem);
                        }}
                        atual={this.props.pagina}
                        paginas={this.props.paginas} />
                </div>
                <ModalConfirmacao
                    titulo="Remover o item?"
                    ref="confirmacaoRemover"
                    aoConfirmar={e => this.props.remover(e)}>
                    Você realmente deseja remover esse item?
                </ModalConfirmacao>
                <ModalConfirmacao
                    titulo="Remover itens selecionados?"
                    ref="confirmacaoSelecionados"
                    aoConfirmar={this.removerSelecionados}>
                    Você realmente deseja remover os itens selecionados?
                </ModalConfirmacao>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuarios: state.usuarios.usuarios,
        selecionados: state.usuarios.selecionados,
        paginas: state.usuarios.paginas,
        pagina: state.usuarios.pagina,
        qtde: state.usuarios.qtde,
        ordem: state.usuarios.ordem,
        carregando: state.usuarios.carregando,
        erro: state.usuarios.erro
    }
}

const mapDispatchToProps = dispatch => {
    return {
        alterarPagina: (pagina) => dispatch(alterarPagina(pagina)),
        alterarOrdem: (ordem) => dispatch(alterarOrdem(ordem)),
        adicionarSelecao: (usuario) => dispatch(adicionarSelecao(usuario)),
        removerSelecao: (usuario) => dispatch(removerSelecao(usuario)),
        selecionarTodos: () => dispatch(selecionarTodos()),
        desselecionarTodos: () => dispatch(desselecionarTodos()),
        remover: (usuario) => dispatch(remover(usuario)),
        buscarTodos: (pagina, qtde, ordem) => dispatch(buscarTodos(pagina, qtde, ordem)),
        definirItem: (usuario) => dispatch(definirItem(usuario))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaUsuarios);